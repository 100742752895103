import { ISummaryViewProps } from "./Summary.view";
import { Button, Datepicker } from "xa-inputs";
import { useTranslation } from "react-i18next";
import "./Less/SummaryForm.css";

export interface ISummaryFormViewProps extends ISummaryViewProps {}

const SummaryFormView: React.FC<ISummaryFormViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                props.loadCourierSummary(true);
            }}
            className="summary-form"
        >
            <Datepicker
                {...props.summaryForm.from}
                labelText={t("date_from")}
                onChange={props.onChangeSummary}
            />
            <Datepicker
                {...props.summaryForm.till}
                labelText={t("date_till")}
                onChange={props.onChangeSummary}
            />
            <div className="flex-div">
                <Button
                    className="button"
                    disabled={props.selectedCourier ? false : true}
                    onClick={props.exportSummary}
                >
                    {t("export")}
                </Button>
                <Button type="submit" disabled={props.selectedCourier ? false : true}>
                    {t("summary_get")}
                </Button>
            </div>
        </form>
    );
};

export default SummaryFormView;
