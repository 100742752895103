import { IDeliveryUnitViewProps } from "./DeliveryUnit.view";
import { useMountWithTriggers } from "xa-generics";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { DragOverCapture } from "../Util/DragScroll.util";
import { useTranslation } from "react-i18next";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { ModalContext } from "xa-modal";
import { useCouriers } from "../../../Contexts/Courier.context";
import { useContext } from "react";
import { OrderModel } from "../../../Models/Order.model";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { Statuses } from "../../../Static/Statuses.static";
import AssignCourierController from "../../AssignCourier/Controller/AssignCourier.controller";
import OrderEditorController from "../../OrderEditor/Controller/OrderEditor.controller";
import TimeSpan from "../../UI/TimeSpan/TimeSpan.view";
import DropMenu from "../../UI/DropMenu/DropMenu.view";
import "./Less/DeliveryUnitOrder.css";
import moment from "moment";
import { DATETIMEFORMAT } from "../../../Static/DateFormats.static";

export interface IDeliveryUnitOrderProps extends IDeliveryUnitViewProps {
    turn: CourierTurnModel;
    isDraggable: boolean;
    isDisabled: boolean;
    listLength: number;
    order: OrderModel;
    index: number;
}

const DeliveryUnitOrder: React.FC<IDeliveryUnitOrderProps> = ({ order, ...props }) => {
    const modal = useContext(ModalContext);
    const { restaurant } = useAccess();
    const { couriersById } = useCouriers();
    const dictionary = useDictionary();
    const { t } = useTranslation();

    const cleanupDragEvent = (): void => {
        if (props.lastMoved.current && props.lastMoved.current.elementID === order.id) {
            setTimeout(() => {
                props.lastMoved.current = null;
            }, 300);
        }
    };
    useMountWithTriggers(cleanupDragEvent, [props.turn.orders]);

    const id: string = `${props.turn.id}||${order.id}`;
    let className: string = "unit-order";
    let isCurrentTarget: boolean = false;
    let isTopVisible: boolean = false;
    let isBotVisible: boolean = false;

    if (order.status === Statuses.order_delivered) {
        className += " unit-order-delivered";
    }

    if (props.dropTarget && props.dragOrigin) {
        if (props.dropTarget.rawID === id) {
            isCurrentTarget = true;
            if (props.dragOrigin.index > props.index) {
                isTopVisible = true;
            } else {
                isBotVisible = true;
            }
        }
    } else {
        if (props.lastMoved.current && props.lastMoved.current.elementID === order.id) {
            className += " animate-order-row";
        }
    }

    if (!props.isDraggable) {
        className += ` not-draggable-order`;
    }

    if (props.loading) {
        className += " disabled-unit-order";
    }

    return (
        <>
            {isCurrentTarget && isTopVisible && <div className="drop-target-line" />}
            <div
                id={id}
                draggable={props.isDraggable}
                className={className}
                onDragStart={(e) => {
                    if (props.isDraggable) {
                        props.onDragStart(order, props.index);
                    } else e.preventDefault();
                }}
                onDragOverCapture={(e) => DragOverCapture(e)}
                onDragOver={props.onDragOver}
                onDragEnd={props.onDragEnd}
            >
                <div className="unit-order__status">
                    <div className="unit-order__status--counter">
                        <div className="unit-order__status--counter--number">{order.counter}</div>
                        <div
                            className={`circle unit-order__status--counter--status-dot ${order.status}`}
                        />
                    </div>
                    <div className="unit-order__status--arrow-container">
                        {props.index > 0 && (
                            <div
                                className="flaticon-down-arrow up-arrow"
                                onClick={() => {
                                    if (props.isDraggable) props.moveOrder(order, "UP");
                                }}
                            />
                        )}
                        {props.index < props.listLength - 1 && (
                            <div
                                className="flaticon-down-arrow down-arrow"
                                onClick={() => {
                                    if (props.isDraggable) props.moveOrder(order, "DOWN");
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="unit-order__data">
                    <div className="unit-order__data--address">{order.getFormattedAddress(t)}</div>
                    {order.change_status_at && (
                        <div className="delivery-unit__data--counters">
                            <div className="delivery-unit__data--counters--leftover">
                                {t("change_status_at")}
                            </div>
                            <div className="delivery-unit__data--counters--delivered">
                                : {moment(order.change_status_at).format(DATETIMEFORMAT)}
                            </div>
                        </div>
                    )}
                    <div className="unit-order__data--comment">
                        {order.name}: {order.comment || "-"}
                    </div>
                </div>
                <div className="unit-order__payment">
                    <div className="unit-order__payment--method">
                        {dictionary.parsePayment(order.payment_method)}
                    </div>
                    <TimeSpan
                        id={id}
                        maxDelay={restaurant.delay_time}
                        date={order.showAtAsDate}
                        elementIsLate={order.isLate}
                        className="unit-order__payment--time"
                        warningClassName={"unit-order__payment--time--warning"}
                        onChangeLate={() => props.setOrderToBeLate(order)}
                    />
                </div>
                <div className="unit-order__menu">
                    <DropMenu>
                        <div
                            className="menu-item"
                            onClick={() => {
                                modal.setContent(<OrderEditorController order={order} />);
                            }}
                        >
                            {t("order_editor_title")}
                        </div>
                        {props.turn.status !== Statuses.deliveryStatuses.go_back && (
                            <div className="menu-item" onClick={() => props.backToAddresses(order)}>
                                {t("unit_order_menu_back_to_addresses")}
                            </div>
                        )}

                        <div className="menu-item" onClick={() => props.printBigBlock(order.id)}>
                            {t("print_big_block")}
                        </div>
                        {props.turn.status === Statuses.deliveryStatuses.created && (
                            <div
                                className="menu-item"
                                onClick={() =>
                                    modal.setContent(
                                        <AssignCourierController
                                            order={order}
                                            courier={props.turn.getCourier(couriersById)}
                                        />
                                    )
                                }
                            >
                                {t("unit_order_menu_swap")}
                            </div>
                        )}
                        <div className="menu-item" onClick={() => props.cancelOrder(order)}>
                            {t("unit_order_menu_delete")}
                        </div>
                    </DropMenu>
                </div>
            </div>
            {isCurrentTarget && isBotVisible && <div className="drop-target-line" />}
        </>
    );
};

export default DeliveryUnitOrder;
