import { Button } from "xa-inputs";
import { OrderModel } from "../../../Models/Order.model";
import { useOrders } from "../../../Contexts/Orders.context";
import { ModalContext } from "xa-modal";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { useMountWithTriggers } from "xa-generics";
import AssignCourierController from "../../AssignCourier/Controller/AssignCourier.controller";
import "./Less/OverlayAssign.css";

export interface IMapOverlayAssignButtonProps {}

const MapOverlayAssignButton: React.FC<IMapOverlayAssignButtonProps> = (props) => {
    const { t } = useTranslation();
    const modal = useContext(ModalContext);
    const orderContext = useOrders();

    const [showAssign, setshowAssign] = useState<boolean>(false);

    const getAssignValue = (): void => {
        for (const order of orderContext.list) {
            if (order.isCheckedToAssign) {
                setshowAssign(true);
                return;
            }
        }
        setshowAssign(false);
    };

    useMountWithTriggers(getAssignValue, [orderContext.list]);

    if (!showAssign) return null;

    return (
        <div className="overlay-assign-container">
            <Button
                onClick={() => {
                    const list: OrderModel[] = [];
                    for (const order of orderContext.list) {
                        if (order.isCheckedToAssign) {
                            list.push(order);
                        }
                    }
                    modal.setContent(<AssignCourierController order={list} />);
                }}
            >
                {t("assign_order_to_courier")}
            </Button>
        </div>
    );
};

export default MapOverlayAssignButton;
