import { ISocketCourierCoordList } from "../../../Interfaces/ISocketTask.interface";
import { DeliveryMarkerView } from "./DeliveryMarker.util";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { IDynamicObject } from "xa-generics";
import { IMapFilterForm } from "../../../Interfaces/IMapFilterForm.interface";
import { CourierModel } from "../../../Models/Courier.model";
import { IMapDelivery } from "../Interface/IMapDelivery.interface";
import { OrderModel } from "../../../Models/Order.model";

export const MapDeliveryList = (
    list: CourierTurnModel[],
    form: IMapFilterForm,
    lastKnownCoords: ISocketCourierCoordList,
    couriersById: IDynamicObject<CourierModel>,
    onClick: (delivery: CourierTurnModel) => void,
    onOrderClick: (order: OrderModel, swappable: boolean, delivery?: CourierTurnModel) => void
): IMapDelivery[] => {
    const nodeArray: IMapDelivery[] = [];

    let showOrders: boolean = true;

    for (let delivery of list) {
        if (form.courierFilter.value instanceof Array && form.courierFilter.value.length > 0) {
            for (let courier of form.courierFilter.value) {
                if (courier.id === "UNASSIGNED") {
                    showOrders = false;
                }
                if (courier.id === delivery.courier_id) {
                    showOrders = true;
                    nodeArray.push(
                        DeliveryMarkerView(
                            showOrders,
                            delivery,
                            lastKnownCoords,
                            couriersById,
                            form,
                            onClick,
                            onOrderClick
                        )
                    );

                    break;
                }
            }
        } else {
            nodeArray.push(
                DeliveryMarkerView(
                    showOrders,
                    delivery,
                    lastKnownCoords,
                    couriersById,
                    form,
                    onClick,
                    onOrderClick
                )
            );
        }
    }

    return nodeArray;
};
