import { IDeliveryFilterForm } from "../Interfaces/IDeliveryFilterForm.interface";

export const DeliveryFilterForm: IDeliveryFilterForm = {
    filters: {
        id: "filters",
        value: "all",
        radioType: true,
        objRadioIDS: {
            all: "all",
            on_road: "on_road",
            go_back: "go_back",
            created: "created"
        }
    }
};
