import { useNow } from "../../../Contexts/Now.context";
import { Statuses } from "../../../Static/Statuses.static";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { getElapsedSeconds } from "../../../Utils/GetElapsedSeconds.util";
import { useMountWithTriggers } from "xa-generics";

export interface IDeliveryTimeProps {
    round: CourierTurnModel;
    text: string;
    labelClass?: string;
    valueClass?: string;
}

const DeliveryTime: React.FC<IDeliveryTimeProps> = (props) => {
    const [node, setNode] = useState<string | JSX.Element>("");
    const { t } = useTranslation();
    const now = useNow();

    const calculateTime = (): string | JSX.Element => {
        if (!props.round.started_at) return t("unknown");

        const elapsed: number = getElapsedSeconds(props.round.started_at_as_date, now);
        const elapsedSubEstimated: number = props.round.full_travel_time - elapsed;
        const mins: number = Math.round(elapsedSubEstimated / 60);

        return (
            <span className="minutes" title={t("delivery_time_explanation")}>
                {mins} {t("time_min")}
            </span>
        );
    };

    const init = (): (() => void) => {
        let tmout: NodeJS.Timeout | undefined;
        if (!node) {
            tmout = setTimeout(() => {
                setNode(t("calculating"));
            }, 500);
        } else {
            switch (props.round.status) {
                case Statuses.deliveryStatuses.created:
                    const element: JSX.Element = (
                        <span className="minutes">
                            {Math.round(props.round.full_travel_time / 60)} {t("time_min")}
                        </span>
                    );
                    setNode(element);
                    break;
                case Statuses.deliveryStatuses.on_road:
                case Statuses.deliveryStatuses.go_back:
                    setNode(calculateTime());
            }
        }

        return () => {
            if (tmout) clearTimeout(tmout);
        };
    };

    useMountWithTriggers(init, [now]);

    return (
        <>
            <div className={props.labelClass || "delivery-unit__delivery-time--text"}>
                {props.text}
            </div>
            <div className={props.valueClass || "delivery-unit__delivery-time--mins"}>{node}</div>
        </>
    );
};

export default DeliveryTime;
