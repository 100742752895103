import { ModelConstructor, IDynamicObject } from "xa-generics";
import { PrintOrderModel } from "../Components/Print/Model/PrintOrder.model";

export class DetailedCourierTurnModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as DetailedCourierTurnMData;
        ModelConstructor(data, this);

        if (data.orders instanceof Array) {
            this.orders = data.orders.map((order) => new PrintOrderModel(order));
        }
    }

    auto_fill: boolean = false;
    id: string = "";
    courier_id: string = "";
    restaurant_id: string = "";
    status: string = "";
    created_at: string = "";
    updated_at: string = "";
    finish_estimated_travel_time: number = 0;
    finish_distance: number = 0;
    all_order_qty: number = 0;
    delivered_order_qty: number = 0;
    failed_order_qty: number = 0;
    full_travel_time: number = 0;
    finish_real_travel_time: number = 0;
    started_at: string = "";
    finished_at: string = "";
    is_optimized: boolean = false;
    is_calculated: boolean = false;
    full_time: string = "";
    miss_time: string = "";
    success_rate: string = "";
    need_order_parsing: boolean = false;
    is_force_finished: string = "";
    delivery_at: string = "";
    has_balance: boolean = false;
    orders: PrintOrderModel[] = [];
}

//If you add public methods, you may want to replace the empty string with them!
type DetailedCourierTurnMData = Partial<InstanceType<typeof DetailedCourierTurnModel>>;
