import { IOrderFilterForm } from "../Interface/IOrderFilterForm.interface";

export const OrderFilterForm: IOrderFilterForm = {
    filters: {
        id: "filters",
        value: "all",
        radioType: true,
        objRadioIDS: {
            all: "all",
            done: "done",
            kitchen: "kitchen",
            late: "late"
        }
    }
};
