import { map, sortBy } from "lodash";
import { useCouriers } from "../../../Contexts/Courier.context";
import { CourierModel } from "../../../Models/Courier.model";
import { ErrorHandler } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { RestaurantModel } from "../../../Models/Restaurant.model";
import { IError, ILoading } from "xa-generics";
import CourierHeaderElementView from "./CourierHeader.element.view";
import CourierElementView from "./Courier.element.view";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/Couriers.css";

export interface ICourierElementFunctions {
    changeAvailability: (courierId: string, value: boolean) => void;
    loading: ILoading;
}

export interface ICouriersViewProps extends ICourierElementFunctions {
    restaurant: RestaurantModel;
    resetError: () => void;
    error: IError;
}

const CouriersView: React.FC<ICouriersViewProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    const { couriers, error } = useCouriers();

    return (
        <div className="couriers-view">
            <h2 className="couriers-view__title">{t("couriers_title")}</h2>

            <div className="couriers-view__pin">
                {t("couriers_pin")}: <b>{props.restaurant.pin}</b>
            </div>

            <div className="couriers-view__list-container">
                {couriers.length > 0 && <CourierHeaderElementView />}
                {couriers.length ? (
                    map(sortBy(couriers, "name"), (courier: CourierModel, index: number) => (
                        <CourierElementView
                            changeAvailability={props.changeAvailability}
                            key={`${index}-courier-element`}
                            loading={props.loading}
                            courier={courier}
                        />
                    ))
                ) : (
                    <div className="couriers-view__list-container--no-data no-data">
                        {t("couriers_no_data")}
                    </div>
                )}
            </div>
            {props.loading && (
                <Spinner isAbsolute>
                    <span>{t("save_changes")}</span>
                </Spinner>
            )}
            <ErrorHandler error={props.error || error} resetError={props.resetError} />
        </div>
    );
};

export default CouriersView;
