import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { DetailedCourierTurnModel } from "../../../Models/DetailedCourierTurn.model";
import { DetailedCourierModel } from "../../../Models/DetailedCourier.model";
import { FromatPhoneNumber } from "../../../Utils/FormatPhoneNumber.util";
import { useTranslation } from "react-i18next";
import { ProductModel } from "../Model/Product.model";
import { ILabelObject } from "../Interface/ILabelObject.interface";
import { ErrorHandler } from "xa-error-with-lang";
import { map } from "lodash";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/LabelPrinting.css";

export interface ILabelPrintingViewProps {
    turn: DetailedCourierTurnModel | null;
    courier: DetailedCourierModel | null;
    products: ProductModel[];
    loading: ILoading;
    error: IError;
}

const LabelPrintingView: React.FC<ILabelPrintingViewProps> = (props) => {
    const { t } = useTranslation();

    const print = (): void => {
        if (!props.loading && !props.error && props.turn && props.courier) {
            window.print();
        }
    };
    useMountWithTriggers(print, [props.loading, props.error, props.turn, props.courier]);

    if (props.loading || !props.turn) return <Spinner isLarge />;
    if (props.error) {
        return (
            <div className="print-menu">
                <ErrorHandler error={props.error} />
            </div>
        );
    }

    const orderItems: ILabelObject[] = [];

    for (let order of props.turn.orders) {
        for (let item of order.order_items) {
            let productName = "";
            if (item.subproduct_name) productName += `${item.subproduct_name} - `;
            if (item.product_name) productName += item.product_name;
            const obj: ILabelObject = {
                productName: productName,
                quantity: Number(item.quantity),
                address: order.getFormattedAddress(t),
                tel: FromatPhoneNumber(order.tel),
                createdAt: order.showAtAsString,
                name: order.name,
                counter: order.counter
            };
            const qty: number = obj.quantity;
            for (let i = 0; i < qty; i++) {
                orderItems.push(obj);
            }
        }
    }

    const printEllipsis = (value: string, maxCharCount: number = 65): string => {
        if (value.length < maxCharCount) return value;
        return `${value.substring(0, maxCharCount)}...`;
    };

    return (
        <div className="label-printing" id="printarea">
            {map(orderItems, (order, index) => (
                <div className="label-printing__item" key={`${index}-delivery-label-item`}>
                    <div className="label-printing__item--name">
                        {printEllipsis(`${order.counter} - ${order.name}`, 28)}
                    </div>
                    <div className="label-printing__item--row tel-date">
                        <span className="tel">{order.tel}</span>
                        <span className="date">{order.createdAt.substring(0, 10)}</span>
                    </div>
                    <div className="label-printing__item--row food">
                        {printEllipsis(`${order.productName}`, 34)}
                    </div>
                    <div className="label-printing__item--row address">
                        {printEllipsis(order.address)}
                    </div>
                    <div className="label-printing__item--row comment">
                        {t("product_label_text")}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LabelPrintingView;
