import { ModelConstructor, IDynamicObject } from "xa-generics";

export class ProductCountModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    product_id: string = "";
    restaurant_id: string = "";
    product_name: string = "";
    count: number = 0;
}
