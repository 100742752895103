import { IDragEventStart, IDragData } from "../Interfaces/IDragEvent.interface";
import { IDeliveryUnitProps } from "../Controller/DeliveryUnit.controller";
import { IRoundDaoMethods } from "../Interfaces/IRoundDaoMethods.type";
import { ILoading, IError } from "xa-generics";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { useTranslation } from "react-i18next";
import { FloatingError } from "xa-error-with-lang";
import { useCouriers } from "../../../Contexts/Courier.context";
import { OrderModel } from "../../../Models/Order.model";
import { textFilter } from "../../../Utils/TextFilter.util";
import { IMoveTypes } from "../Interfaces/IMoves.type";
import { useState } from "react";
import { Statuses } from "../../../Static/Statuses.static";
import { Button } from "xa-inputs";
import IDeliveryButtonConfig from "../Interfaces/IDeliveryButtonConfig.interface";
import DeliveryUnitOrder from "./DeliveryUnitOrder.view";
import DeliveryTime from "../../UI/TimeSpan/DeliveryTime.view";
import DropMenu from "../../UI/DropMenu/DropMenu.view";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/DeliveryUnit.css";
import moment from "moment";
import { DATETIMEFORMAT } from "../../../Static/DateFormats.static";
import { useAccess } from "../../../Modules/Access/Context/Access.context";

export interface IDeliveryUnitViewProps extends IDeliveryUnitProps {
    lastMoved: React.MutableRefObject<IDragEventStart<OrderModel> | null>;
    moveOrder: (order: OrderModel, direction: IMoveTypes) => void;
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
    deliveryDaoCalls: (daoMethod: IRoundDaoMethods) => void;
    onDragStart: (order: OrderModel, index: number) => void;
    dragOrigin: IDragEventStart<OrderModel> | null;
    setOrderToBeLate: (order: OrderModel) => void;
    backToAddresses: (order: OrderModel) => void;
    printBigBlock: (orderId: string) => void;
    cancelOrder: (order: OrderModel) => void;
    setError: (error: IError) => void;
    dropTarget: IDragData | null;
    printTurnOrders: () => void;
    printTurnTaxes: () => void;
    changeAutoFill: () => void;
    loading: ILoading;
    error: IError;
}

const DeliveryUnitView: React.FC<IDeliveryUnitViewProps> = (props) => {
    const { couriersById } = useCouriers();
    const { taxPrinter, host } = useAccess();
    const round: CourierTurnModel = props.turn;
    const orders: OrderModel[] = props.turn.orders;
    const courier = props.turn.getCourier(couriersById);

    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(props.counterFilter ? true : false);

    let classes: string = "delivery-unit";
    let areElementsDraggable: boolean = round.status === Statuses.deliveryStatuses.created;

    if (isOpened) classes += " delivery-unit-opened";
    if (props.loading) classes += " delivery-unit-disabled";

    const getButtonConfig = (): IDeliveryButtonConfig => {
        if (round.auto_fill) {
            return {
                estTimetext: t("delivery_round_time"),
                btnText: t("delivery_autofill_off"),
                btnFunction: () => props.changeAutoFill()
            };
        }
        switch (round.status) {
            case Statuses.deliveryStatuses.go_back:
                return {
                    estTimetext: t("delivery_return_time"),
                    btnText: t("delivery_returned"),
                    btnFunction: () => props.deliveryDaoCalls("finishRound")
                };
            case Statuses.deliveryStatuses.on_road:
                return {
                    estTimetext: t("delivery_remaining_time"),
                    btnText: "",
                    btnFunction: () => null
                };
            default:
                return {
                    estTimetext: t("delivery_round_time"),
                    btnText: t("delivery_start"),
                    btnFunction: () => props.deliveryDaoCalls("startRound")
                };
        }
    };

    const config: IDeliveryButtonConfig = getButtonConfig();

    return (
        <>
            <div className={classes}>
                <FloatingError error={props.error} resetError={() => props.setError(null)} />
                <div className="delivery-unit__opener">
                    <div
                        className="delivery-unit__opener--icon-container"
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        {props.loading && <Spinner isAbsolute />}
                        <span className="arrow icon"></span>
                    </div>
                </div>
                <div className="delivery-unit__data">
                    <div className="delivery-unit__data--name">{courier?.name || "-"}</div>
                    {round.started_at && (
                        <div className="delivery-unit__data--counters">
                            <div className="delivery-unit__data--counters--leftover">
                                {t("delivery_start")}
                            </div>
                            <div className="delivery-unit__data--counters--delivered">
                                : {moment(round.started_at).format(DATETIMEFORMAT)}
                            </div>
                        </div>
                    )}
                    <div className="delivery-unit__data--counters">
                        <div className="delivery-unit__data--counters--leftover">
                            {round.all_order_qty -
                                round.delivered_order_qty -
                                round.failed_order_qty}{" "}
                            {t("delivery_addresses_left")}{" "}
                        </div>
                        <div className="delivery-unit__data--counters--delivered">
                            | {round.delivered_order_qty} {t("delivery_delivered")}
                        </div>
                    </div>
                </div>
                <div className={"delivery-unit__status"}>
                    <div className={`delivery-unit__status--box ${round.status}`}>
                        {t(`delivery_status_${round.status}`)}
                    </div>
                </div>
                <div className="delivery-unit__delivery-time">
                    <DeliveryTime round={round} text={config.estTimetext} />
                </div>
                <div className="delivery-unit__button">
                    {round.status === Statuses.deliveryStatuses.on_road ||
                    round.need_order_parsing ? null : (
                        <Button onClick={config.btnFunction}>{config.btnText}</Button>
                    )}
                </div>
                <div className="delivery-unit__menu">
                    <DropMenu>
                        {!round.is_optimized &&
                        round.status === Statuses.deliveryStatuses.created ? (
                            <div
                                className="menu-item"
                                onClick={() => props.deliveryDaoCalls("optimizeSequence")}
                            >
                                {t("delivery_optimize_sequence")}
                            </div>
                        ) : null}
                        {!round.is_calculated ? (
                            <div
                                className="menu-item"
                                onClick={() => props.deliveryDaoCalls("calculateSequence")}
                            >
                                {t("delivery_calculate_sequence")}
                            </div>
                        ) : null}
                        {round.status !== Statuses.deliveryStatuses.created && (
                            <div
                                className="menu-item"
                                onClick={() => props.deliveryDaoCalls("closeRoundAsSuccessful")}
                            >
                                {t("delivery_close_sequence")}
                            </div>
                        )}

                        <div className="menu-item" onClick={props.printTurnOrders}>
                            {t("print_big_block")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                window.open(`/print/${round.id}`, "_blank");
                            }}
                        >
                            {t("delivery_print_sequence")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                window.open(`/print/menu/${round.id}`, "_blank");
                            }}
                        >
                            {t("delivery_print_menu")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                window.open(`/print/labels/${round.id}`, "_blank");
                            }}
                        >
                            {t("delivery_label_print")}
                        </div>
                        {host && !host.is_cloud && taxPrinter?.has_tax_printer && (
                            <div className="menu-item" onClick={props.printTurnTaxes}>
                                {t("tax_print")}
                            </div>
                        )}
                    </DropMenu>
                </div>
            </div>
            <div className={"unit-order-container"}>
                {isOpened &&
                    orders.map((order: OrderModel, index: number) => {
                        if (props.textFilter && !textFilter(props.textFilter, order)) return null;
                        if (props.counterFilter && props.counterFilter !== order.counter)
                            return null;

                        return (
                            <DeliveryUnitOrder
                                {...props}
                                key={`${index}-${round.id}-delivery-round-order`}
                                isDisabled={props.loading ? true : false}
                                isDraggable={areElementsDraggable}
                                lastMoved={props.lastMoved}
                                listLength={orders.length}
                                index={index}
                                order={order}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default DeliveryUnitView;
