import { ModelConstructor, IDynamicObject, setString } from "xa-generics";

export class ItemOptionModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as ItemOptionMData;
        ModelConstructor(data, this);

        if (data.option?.name) {
            this.option.name = setString(data.option.name);
        }
    }

    created_at: string = "";
    id: string = "";
    option: { name: string } = { name: "" };
    option_id: string = "";
    order_item_id: string = "";
    quantity: number = 0;
    total_price: number = 0;
    unit_price: number = 0;
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type ItemOptionMData = Partial<InstanceType<typeof ItemOptionModel>>;
