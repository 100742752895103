export const DragOverCapture = (e: React.DragEvent<HTMLDivElement>): void => {
    //Return if parentElement couldn't be retrieved
    if (!e.currentTarget.parentElement) return;
    const y_height: number = e.clientY || e.screenY || e.pageY;
    //If the dragged element y is lesser than the offset top (so it's close to the drawer top)
    if (y_height < 400) {
        const el = document.getElementById("scrollable-list-container");
        //Return if the dom element couldn't be found
        if (!el) return;
        //scroll up at lesat half of the screen (can't scroll past element scrollTop 0)
        el.scroll({
            top: el.scrollTop - 20
        });
    }
    //If the dragged element y is greater + (topline+header offset) greater than windown inner height
    if (y_height > window.innerHeight - 180) {
        const el = document.getElementById("scrollable-list-container");
        //Return if the dom element couldn't be found
        if (!el) return;
        //scroll down at least half the height of screen (can't got past element height)
        el.scroll({
            top: el.scrollTop + 20
        });
    }
};
