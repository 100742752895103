import { ModelConstructor, IDynamicObject, setString } from "xa-generics";
import { ItemOptionModel } from "./ItemOption.model";

export class OrderItemModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as OrderItemMData;
        ModelConstructor(data, this);

        if (data.item_options instanceof Array) {
            this.item_options = data.item_options.map((option) => new ItemOptionModel(option));
        }

        if (data.product?.name) {
            this.product.name = setString(data.product.name);
            this.product_name = this.product.name;
        }
        if (data.product_category?.name) {
            this.product_category.name = setString(data.product_category.name);
            this.category_name = this.product_category.name;
        }
        if (data.subproduct?.name) {
            this.subproduct.name = setString(data.subproduct.name);
            this.subproduct_name = this.subproduct.name;
        }
    }

    created_at: string = "";
    id: string = "";
    item_options: ItemOptionModel[] = [];
    order_id: string = "";
    product: { name: string } = { name: "" };
    product_category: { name: string } = { name: "" };
    quantity: number = 0;
    subproduct: { name: string } = { name: "" };
    subproduct_id: string = "";
    total_price: number = 0;
    unit_price: number = 0;
    updated_at: string = "";

    //Custom props
    product_name: string = "";
    category_name: string = "";
    subproduct_name: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type OrderItemMData = Partial<InstanceType<typeof OrderItemModel>>;
