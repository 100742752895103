import ReactDOM from "react-dom";
import { Router } from "./Components/Navigation/View/Router";
import { AXIOSNAMES } from "./Static/AxiosInstanceNames.static";
import { BrowserRouter } from "react-router-dom";
import { IDynamicObject } from "xa-generics";
import { EnvironmentContext } from "./Contexts/Environment.context";
import { axios, IAllowedLanguages } from "xa-generics";
import { AxiosResponse, AxiosError } from "axios";
import { AccessConsumer, AccessContextProvider } from "./Modules/Access/Context/Access.context";
import * as ServiceWorker from "./serviceWorker";
import DefaultAxios from "axios";
import Environment from "./Models/Environment.model";
import Cookies from "js-cookie";
import Config from "./Config/Packages.config";
import "./Assets/font/font/flaticon.css";
import "./Css/SharedGlobal.css";
import "./Css/Fonts.css";
import "./Css/Input.css";

const defaultLang = Cookies.get("lang")?.slice(0, 2).toLowerCase() || "hu";
Config(defaultLang);

DefaultAxios.get("/api/rest")
    .then((response: AxiosResponse<IDynamicObject>) => new Environment(response.data))
    .then((ENV_KEYS: Environment) => {
        axios.initAxios(
            ENV_KEYS.REST_API_URL,
            AXIOSNAMES.DEFAULT,
            defaultLang as IAllowedLanguages,
            { cookie_key: "token", header: "Authorization" }
        );
        axios.initAxios(ENV_KEYS.ONEMIN_BACKEND, AXIOSNAMES.ONEMIN, "hu", {
            cookie_key: "token",
            header: "Authorization"
        });
        axios.initAxios(ENV_KEYS.ONEMIN_BACKEND, AXIOSNAMES.PRINT, "hu", {
            cookie_key: "token",
            header: "Authorization"
        });

        const AppRoot = (
            <BrowserRouter>
                <EnvironmentContext.Provider value={ENV_KEYS}>
                    <AccessContextProvider>
                        <AccessConsumer>
                            {(ctxValue) => <Router key={`${ctxValue.accessModel?.id}-user-id`} />}
                        </AccessConsumer>
                    </AccessContextProvider>
                </EnvironmentContext.Provider>
            </BrowserRouter>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));
        ServiceWorker.unregister();
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Maybe copy the error message below to help us solve the issue:</p>
                <p>{error.message}</p>
                {console.error(error)}
            </div>,
            document.getElementById("root")
        );
    });
