import { useState } from "react";
import { ILoading } from "xa-generics";
import { LockerSVG } from "../../SVG/LockerSVG";
import { useTranslation } from "react-i18next";
import { Button, TextInput } from "xa-inputs";
import Spinner from "../Spinner/Spinner";
import "./Less/RequirePin.css";

export interface IRequirePinProps {
    isInvalid: boolean;
    validatePin: (value: string) => void;
    loading: ILoading;
    close: () => void;
}

const RequirePin: React.FC<IRequirePinProps> = (props) => {
    const { t } = useTranslation();

    const [pin, setPin] = useState<string>("");

    return (
        <div className="require-pin">
            <div className="require-pin__title">
                {t("require_pin")}
                <div className="close" onClick={() => props.close()}>
                    X
                </div>
            </div>
            <div className="require-pin__image">
                <LockerSVG className="locker-image" />
            </div>
            <div className="require-pin__form">
                <TextInput
                    isNumeric
                    value={pin}
                    id={"pin"}
                    placeholder={"PIN"}
                    className="require-pin__form--input"
                    onChange={(e) => setPin(e.value)}
                />
            </div>
            {props.isInvalid && <div className="require-pin__invalid">{t("PIN_INVALID")}</div>}
            <div className="require-pin__button">
                <Button
                    hasContainer
                    onClick={() => props.validatePin(pin)}
                    disabled={pin.length !== 4}
                >
                    {props.loading ? <Spinner isNegatedColor isTiny /> : t("enter")}
                </Button>
            </div>
        </div>
    );
};

export default RequirePin;
