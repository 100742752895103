import { IArchivedOrderElementFunctions } from "./ArchivedOrders.view";
import { useTranslation } from "react-i18next";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { DropMenuItem } from "../../UI/DropMenu/DropMenuItem.view";
import { OrderModel } from "../../../Models/Order.model";
import { StoreCheck } from "../../Orders/Util/OrderChecks.util";
import { Checkbox } from "xa-inputs";
import DropMenu from "../../UI/DropMenu/DropMenu.view";

export interface IArchivedOrderElementViewProps extends IArchivedOrderElementFunctions {
    archivedOrder: OrderModel;
}

const ArchivedOrderElementView: React.FC<IArchivedOrderElementViewProps> = (
    props
): React.ReactElement => {
    const order: OrderModel = props.archivedOrder;
    const dictionary = useDictionary();
    const { t } = useTranslation();

    const handleCheck = (): void => {
        StoreCheck(order.id, !order.isCheckedToAssign);
        props.markOrder(order, !order.isCheckedToAssign);
    };

    return (
        <div className="order archived-order" onClick={handleCheck}>
            <div className="order__checkbox">
                <Checkbox
                    id={`order-${order.id}`}
                    value={order.isCheckedToAssign}
                    onChange={() => {}}
                />
                <div className="order__checkbox--overlay">
                    {/* This div is to prevent the onChange from bubbling up to the 'handleCheck'
                        and do a double state refresh, ultimately leaving the state the same. */}
                </div>
            </div>
            <div className="order__status">
                <span className="order__status--counter">{order.counter}</span>
                <span className={`circle archived`} />
            </div>
            <div className="order__data">
                <span className="order__data--address">{order.getFormattedAddress(t)}</span>
                {order.address_notes && (
                    <span className="order__data--address-notes">
                        {`${t("address_notes")}: ${order.address_notes}`}
                    </span>
                )}
                <span className="order__data--name">
                    {order.first_name} {order.last_name}: {order.comment || "-"}
                </span>
            </div>
            <div className="order__payment">
                <span className="order__payment--method">
                    {dictionary.parsePayment(order.payment_method)}
                </span>
                <span className="order__payment--timer">{order.showAtAsString}</span>
            </div>
            <div className="order__dots">
                <DropMenu>
                    <DropMenuItem onClick={() => props.revokeOrder(order)}>
                        {t("unit_order_menu_back_to_addresses")}
                    </DropMenuItem>
                    <DropMenuItem
                        onClick={() => {
                            props.editOrder(order);
                        }}
                    >
                        {t("order_edit")}
                    </DropMenuItem>
                </DropMenu>
            </div>
        </div>
    );
};

export default ArchivedOrderElementView;
