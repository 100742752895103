import { IMapFilterForm } from "../Interfaces/IMapFilterForm.interface";

export const mapFilterForm: IMapFilterForm = {
    orderStatus: {
        id: "orderStatus",
        value: null,
        options: []
    },
    courierFilter: {
        id: "courierFilter",
        value: null,
        options: []
    }
};
