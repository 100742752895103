import { IError, ILoading, useDidMount } from "xa-generics";
import { DetailedCourierTurnModel } from "../../../Models/DetailedCourierTurn.model";
import { DetailedCourierModel } from "../../../Models/DetailedCourier.model";
import { ITurnResponse } from "../Interface/ITurnResponse.interface";
import { ProductModel } from "../Model/Product.model";
import { useState } from "react";
import { useMatch } from "react-router";
import { RoundDAO } from "../../../Api/DAO/CourierTurn.dao";
import PrintMenuView from "../View/PrintMenu.view";

export type IPrintMenuProps = {};

const PrintMenu: React.FC<IPrintMenuProps> = (props) => {
    const path = useMatch("/print/menu/:round_id");

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [turn, setTurn] = useState<DetailedCourierTurnModel | null>(null);
    const [courier, setCourier] = useState<DetailedCourierModel | null>(null);

    const loadRound = (): void => {
        setLoading(
            RoundDAO.loadTurn(path!.params.round_id!)
                .then((data: ITurnResponse) => {
                    setTurn(data.turn);
                    setCourier(data.courier);
                    setProducts(data.products);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useDidMount(loadRound);

    return (
        <PrintMenuView
            turn={turn}
            loading={loading}
            courier={courier}
            error={error}
            products={products}
        />
    );
};

export default PrintMenu;
