import { PinDAO } from "../../../Api/DAO/Pin.dao";
import { useState } from "react";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { cloneDeep } from "lodash";
import { SUMMARYFORM } from "../Static/Summary.form";
import { ISummaryForm } from "../Interfaces/ISummaryForm.interface";
import { IInputChange } from "xa-inputs";
import { CourierModel } from "../../../Models/Courier.model";
import { ICourierSummaries } from "../Interfaces/ICourierSummaries.type";
import { CourierSummaryModel } from "../Model/CourierSummary.model";
import { PaymentSummaryModel } from "../Model/PaymentSummary.model";
import { ILoading, IError, useMountWithTriggers, useDidMount } from "xa-generics";
import SummaryView from "../View/Summary.view";
import SummaryDAO from "../DAO/Summary.dao";
import moment from "moment";

export interface ISummaryControllerProps {
    closeSummary: () => void;
}

const SummaryController: React.FC<ISummaryControllerProps> = (props) => {
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);
    const [centerSummary, setCenterSummary] = useState<PaymentSummaryModel>(
        new PaymentSummaryModel()
    );
    const [courierSummaries, setCourierSummaries] = useState<ICourierSummaries>({});
    const [summaryForm, setsummaryForm] = useState<ISummaryForm>(cloneDeep(SUMMARYFORM));
    const [selectedCourier, setSelectedCourier] = useState<CourierModel | null>(null);
    const [pin, setPin] = useState<string>("");
    const [isValidPin, setIsValidPin] = useState<boolean>(true);

    const { accessModel } = useAccess();

    const validatePin = (data: string): void => {
        setLoading(
            PinDAO.postRestaurantPin({ pin: data })
                .then((model) => {
                    if (model.is_valid) setPin(data);
                    setIsValidPin(model.is_valid);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const loadCenterSummary = (): void => {
        if (accessModel.has_pin && !pin) return;

        setLoading(
            SummaryDAO.getRestSummary(
                moment(summaryForm.from.value as Date).toISOString(),
                moment(summaryForm.till.value as Date).toISOString(),
                pin
            )
                .then((data: PaymentSummaryModel) => setCenterSummary(data))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const loadCourierSummary = (forceReload: boolean = false): void => {
        if (!selectedCourier) return;
        if (!forceReload && courierSummaries[selectedCourier.id]) return;
        if (accessModel.has_pin && !pin) return;

        setLoading(
            SummaryDAO.getCourierSummary(
                selectedCourier.id,
                moment(summaryForm.from.value as Date).toISOString(),
                moment(summaryForm.till.value as Date).toISOString(),
                pin
            )
                .then((data: CourierSummaryModel) => {
                    let newlist = cloneDeep(courierSummaries);
                    newlist[selectedCourier.id] = data;
                    setCourierSummaries(newlist);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const onChangeSummary = (e: IInputChange): void => {
        let newForm = cloneDeep(summaryForm);
        newForm[e.fieldName as keyof ISummaryForm].value = e.value;
        let maxDate = new Date(newForm.till.value as Date);
        maxDate.setMinutes(maxDate.getMinutes() - 5);
        let minDate = new Date(newForm.from.value as Date);
        minDate.setMinutes(minDate.getMinutes() + 5);
        newForm.from.maxDate = maxDate;
        newForm.till.minDate = minDate;
        setsummaryForm(newForm);
    };

    const exportSummary = (): void => {
        if (!selectedCourier) return;
        if (accessModel.has_pin && !pin) return;

        setLoading(
            SummaryDAO.exportSummary(
                selectedCourier.id,
                moment(summaryForm.from.value as Date).toISOString(),
                moment(summaryForm.till.value as Date).toISOString(),
                pin
            )
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useMountWithTriggers(loadCenterSummary, [pin, summaryForm]);
    useMountWithTriggers(loadCourierSummary, [selectedCourier]);

    const onKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Escape") props.closeSummary();
    };

    const onEscKeyDown = (): (() => void) => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    };
    useDidMount(onEscKeyDown);

    return (
        <SummaryView
            pin={pin}
            error={error}
            loading={loading}
            setError={setError}
            isValidPin={isValidPin}
            validatePin={validatePin}
            summaryForm={summaryForm}
            centerSummary={centerSummary}
            exportSummary={exportSummary}
            selectedCourier={selectedCourier}
            onChangeSummary={onChangeSummary}
            closeSummary={props.closeSummary}
            courierSummaries={courierSummaries}
            loadCourierSummary={loadCourierSummary}
            setSelectedCourier={setSelectedCourier}
        />
    );
};

export default SummaryController;
