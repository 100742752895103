import { IDateFilterForm } from "../Interfaces/IDateFilterForm.interface";

export const DATEFILTERFORM: IDateFilterForm = {
    date: {
        id: "date",
        value: (function () {
            let date = new Date();
            if (date.getHours() <= 5) {
                date.setDate(date.getDate() - 1);
            }
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        })(),
        maxDate: (function () {
            let date = new Date();
            date.setDate(date.getDate() + 90);
            return date;
        })()
    }
};
