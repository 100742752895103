import { ToggleLayer, anchor } from "react-laag";
import "./Less/DropMenu.css";

export interface IDropMenuProps {}

const DropMenu: React.FC<IDropMenuProps> = (props) => {
    return (
        <ToggleLayer
            placement={{ anchor: anchor.LEFT_TOP }}
            closeOnOutsideClick={true}
            renderLayer={({ layerProps, isOpen, close }) =>
                isOpen && (
                    <div
                        className="context-menu-dropdown"
                        {...layerProps}
                        onClick={(e) => {
                            e.stopPropagation();
                            close();
                        }}
                    >
                        {props.children}
                    </div>
                )
            }
        >
            {({ toggle, triggerRef }) => (
                <div
                    className="cube-container"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggle();
                    }}
                    ref={triggerRef}
                >
                    <div className="cube"></div>
                    <div className="cube"></div>
                    <div className="cube"></div>
                </div>
            )}
        </ToggleLayer>
    );
};

export default DropMenu;
