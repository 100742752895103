import { axios, IDynamicObject } from "xa-generics";
import { AccessModel } from "../Models/Access.model";
import { AXIOSNAMES } from "../../../Static/AxiosInstanceNames.static";
import { HostModel } from "../Models/Host.model";

export abstract class AccessDAO {
    public static async loadServer(id: string): Promise<HostModel> {
        const request = await axios
            .getInstance(AXIOSNAMES.ONEMIN)
            .get<IDynamicObject>(`/api/v3/restaurants/${id}/server`);
        const model = new HostModel(request.data);
        axios.getInstance(AXIOSNAMES.PRINT).defaults.baseURL = model.host;
        return model;
    }

    public static async loadMe(token?: string): Promise<AccessModel> {
        const request = await axios.getInstance().get<IDynamicObject<any>>(
            `/api/admins/me`,
            token
                ? {
                      headers: {
                          authorization: token
                      }
                  }
                : undefined
        );
        return new AccessModel(request.data);
    }

    public static async login(
        data: IDynamicObject<any>
    ): Promise<{ model: AccessModel; token: string }> {
        const request = await axios
            .getInstance()
            .post<IDynamicObject<any>>(`/api/admins/login`, data);
        return { model: new AccessModel(request.data), token: request.headers.authorization };
    }

    public static async logout(): Promise<void> {
        return await axios.getInstance().delete<void, void>(`/api/admins/logout`);
    }
}
