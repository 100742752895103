import { ISummaryForm } from "../Interfaces/ISummaryForm.interface";

export const SUMMARYFORM: ISummaryForm = {
    from: {
        id: "from",
        value: (function () {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        })(),
        maxDate: (function () {
            let date = new Date();
            date.setHours(22);
            date.setMinutes(59);
            date.setSeconds(59);
            return date;
        })(),
        showTime: true
    },
    till: {
        id: "till",
        value: (function () {
            let date = new Date();
            date.setHours(23);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        })(),
        minDate: (function () {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(1);
            date.setSeconds(0);
            return date;
        })(),
        showTime: true
    }
};
