import { ModelConstructor, IDynamicObject } from "xa-generics";

export class PinModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as PinMData;
        ModelConstructor(data, this);
    }

    is_valid: boolean = true;
}

//If you add public methods, you may want to replace the empty string with them!
type PinMData = Partial<InstanceType<typeof PinModel>>;
