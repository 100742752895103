import AuthView from "../View/Auth.view";
import { GenericOnChange } from "xa-generics";
import { IInputChange } from "xa-inputs";
import { ILoginForm } from "../Interface/ILoginForm.interface";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useState } from "react";

export type IAuthProps = {};

const Auth: React.FC<IAuthProps> = (props) => {
    const [form, setForm] = useState<ILoginForm>({
        email: "",
        password: ""
    });
    const { login } = useAccess();

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        localStorage.removeItem("check_list");
        login({
            v3_admin: {
                email: form.email,
                password: form.password
            }
        });
    };

    return (
        <>
            <AuthView
                form={form}
                onSubmit={onSubmit}
                onChange={(e: IInputChange) => {
                    GenericOnChange(e.fieldName as keyof ILoginForm, e.value, setForm);
                }}
            />
            {props.children}
        </>
    );
};

export default Auth;
