import { ModelConstructor, IDynamicObject } from "xa-generics";

export class ProductModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as ProductMData;
        ModelConstructor(data, this);
    }

    product_name: string = "";
    category_name: string = "";
    sub_product_id: string = "";
    sub_product_name: string = "";
    original_product_name: string = "";
    quantity: number = 0;
    ranking: number = 0;
    order_ids: string[] = [];
    order_item_ids: string[] = [];
}

//If you add public methods, you may want to replace the empty string with them!
type ProductMData = Partial<InstanceType<typeof ProductModel>>;
