import moment from "moment";
import { ModelConstructor, IDynamicObject } from "xa-generics";
import { DATETIMEFORMAT } from "../Static/DateFormats.static";

export class RestaurantModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);

        if (data.last_scheme_generated_at) {
            this.last_scheme_generated_at = moment(this.last_scheme_generated_at).format(
                DATETIMEFORMAT
            );
        }

        if (!this.delay_time) {
            this.delay_time = 45;
        }
    }

    id: string = "0";
    name: string = "";
    pin: string = "";
    partner_id: string = "";
    coord_lat: number = 0;
    coord_lng: number = 0;
    delay_time: number = 0;
    created_at: string = "";
    updated_at: string = "";
    conf_auto_optimize: boolean = false;
    conf_stopover: number = 0;
    conf_km_price: number = 0;
    conf_unit_here_meter: number = 0;
    conf_sort_product_by: string = "";
    last_order_updated_at: string = "";
    conf_unit_nearby_meter: number = 0;
    conf_print_product: boolean = false;
    allow_product_sync: boolean = false;
    last_product_updated_at: string = "";
    last_scheme_generated_at: string = "";
    last_category_updated_at: string = "";
    conf_save_location_interval: number = 0;
    last_sub_product_updated_at: string = "";
    conf_conf_manual_turn_parse: boolean = false;
    conf_conf_manual_order_dispatch: boolean = false;
    conf_allow_manual_dispatch: boolean = false;
    conf_include_finish_distance: boolean = false;
    conf_print_category_column_count: number = 3;
    conf_sort_product_method: "asc" | "desc" = "asc";
}
