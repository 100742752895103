import { IDynamicObject, ModelConstructor } from "xa-generics";
import { PaymentSummaryModel } from "./PaymentSummary.model";

export class CourierSummaryModel extends PaymentSummaryModel {
    constructor(data?: IDynamicObject<any>) {
        super(data);
        if (!data) return;
        ModelConstructor(data, this);
    }

    distance: number = 0;
    distance_km: number = 0;
    distance_price: number = 0;
}
