import { Statuses } from "../../../Static/Statuses.static";
import { useState } from "react";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useOrders } from "../../../Contexts/Orders.context";
import { OrderModel } from "../../../Models/Order.model";
import { IOrderContext } from "../../../Interfaces/IOrderContext.interface";
import { FloatingError } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IError, ILoading } from "xa-generics";
import { IOrderFilterForm } from "../Interface/IOrderFilterForm.interface";
import { IOrderStatusCount } from "../Interface/IOrderStatusCount.interface";
import { swapString, textFilter } from "../../../Utils/TextFilter.util";
import { IOnChange, TextInput, Button } from "xa-inputs";
import OrderElementView from "./Order.element.view";
import OrderFilterView from "./OrderFilter.view";
import DrawerHeader from "../../Drawer/View/DrawerHeader";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/Orders.css";

export interface IOrderElementFunctions {
    updateOrderElement: (
        method: "UPDATE" | "REMOVE",
        order: OrderModel,
        identifier: keyof OrderModel,
        propertyName: keyof OrderModel,
        newValue?: any
    ) => void;
    assignToCourier: (order: OrderModel) => void;
    printBigBlock: (orderId: string) => void;
    editOrder: (order: OrderModel) => void;

    loading: ILoading;
    error: IError;
}

export interface IOrdersViewProps extends IOrderElementFunctions {
    setTextFilter: (newValue: string) => void;
    assignCheckedOrders: () => void;
    statusCount: IOrderStatusCount;
    increaseShowCount: () => void;
    form: IOrderFilterForm;
    onChange: IOnChange;
    textFilter: string;
    showCount: number;
}

const OrdersView: React.FC<IOrdersViewProps> = (props): React.ReactElement => {
    const [counterFilter, setCounterFilter] = useState<string>("");
    const { restaurant } = useAccess();
    const orderContext: IOrderContext = useOrders();
    const regex: RegExp = RegExp(swapString(props.textFilter), "i");
    const { t } = useTranslation();

    let showAssign: boolean = false;

    //Sorts the list elements
    const sorted_list: OrderModel[] = orderContext.list.sort(
        (a: OrderModel, b: OrderModel) => a.counter - b.counter
    );

    //Create a container for the react nodes
    const orderNodes: React.ReactNode[] = [];

    const orderFilter = (filter: string, order: OrderModel): boolean => {
        if (order.courier_turn_id) return false;

        // if (filter === orderFilterID.all) return order.status in OrderStatus.orderStatuses;
        if (filter === props.form.filters.objRadioIDS.all) return true;

        if (filter === props.form.filters.objRadioIDS.done) return order.status === Statuses.done;

        if (filter === props.form.filters.objRadioIDS.kitchen)
            return order.status === Statuses.in_progress;

        return order.isLate;
    };

    for (const order of sorted_list) {
        //Run the various filters and continue (aka, exclude the elements if any of the filters return false)
        if (props.textFilter !== "") {
            if (!textFilter(regex, order)) continue;
        }
        if (counterFilter !== "") {
            if (counterFilter !== `${order.counter}`) continue;
        }
        if (!orderFilter(props.form.filters.value, order)) continue;

        //Display the assign button if any of the orders are checked
        if (order.isCheckedToAssign) showAssign = true;

        //Push the react node into the node array which can be rendered without mapping
        //reducing the loops to one + the sorting
        orderNodes.push(
            <OrderElementView
                updateOrderElement={props.updateOrderElement}
                assignToCourier={props.assignToCourier}
                printBigBlock={props.printBigBlock}
                key={`${order.id}-order-element`}
                maxDelay={restaurant.delay_time}
                editOrder={props.editOrder}
                loading={props.loading}
                error={props.error}
                order={order}
            />
        );
    }

    const listLength: number = orderNodes.length;

    return (
        <div className="orders-view">
            <DrawerHeader title={t("orders_title")}>
                <OrderFilterView {...props} />
            </DrawerHeader>

            <div className="orders-view__assign-button">
                <Button textButton disabled={!showAssign} onClick={props.assignCheckedOrders}>
                    {t("assign_order_to_courier")}{" "}
                    <span className="flaticon-connection icon"></span>
                </Button>
            </div>
            <div className="orders-view__text-filter">
                <TextInput
                    onChange={(e) => setCounterFilter(e.value)}
                    className="orders-view__text-filter--counter"
                    placeholder={t("counter_filter")}
                    id={"order-counter-filter"}
                    value={counterFilter}
                    isNumeric
                />
                <TextInput
                    id={"order-text-filter"}
                    value={props.textFilter}
                    placeholder={t("order_text_filter")}
                    className="orders-view__text-filter--text"
                    onChange={(e) => props.setTextFilter(e.value)}
                />
            </div>

            {(orderContext.loading || props.loading) && <Spinner />}
            <FloatingError
                error={orderContext.error || props.error}
                resetError={orderContext.resetError}
            />

            <div className="orders-view__list-container">
                {listLength > 0 ? (
                    orderNodes
                ) : (
                    <div className="orders-view__list-container--no-data no-data">
                        <span>{t("no_orders_in_list")}</span>
                    </div>
                )}
            </div>
            {listLength > 15 && props.showCount < listLength ? (
                <div className="orders-container__more-button">
                    <Button onClick={props.increaseShowCount}>{t("show_more_btn")}</Button>
                </div>
            ) : null}
        </div>
    );
};

export default OrdersView;
