import { IAssignCourierControllerProps } from "../Controller/AssignCourier.controller";
import { ILoading, IError } from "xa-generics";
import { useTranslation } from "react-i18next";
import { Button, Radio } from "xa-inputs";
import { ErrorHandler } from "xa-error-with-lang";
import { CourierModel } from "../../../Models/Courier.model";
import { useDelivery } from "../../../Contexts/DeliveryRounds.context";
import { OrderModel } from "../../../Models/Order.model";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/AssignCourier.css";

export interface IAssignCourierViewProps extends IAssignCourierControllerProps {
    setSelectedCourier: (courier: CourierModel) => void;
    assignOrderToCourier: () => void;
    selected: CourierModel | null;
    couriers: CourierModel[];
    orders: OrderModel[];
    loading: ILoading;
    error: IError;
}

const AssignCourierView: React.FC<IAssignCourierViewProps> = (props): React.ReactElement => {
    const { t } = useTranslation();

    const deliveryContext = useDelivery();

    const findRoundOrderCount = (courier: CourierModel): number => {
        let order_count: number = 0;
        for (let item of deliveryContext.list) {
            if (item.courier_id === courier.id) {
                order_count = item.all_order_qty;
            }
        }
        return order_count;
    };

    const nodes: JSX.Element[] = [];

    for (const courier of props.couriers) {
        if (!courier.is_available) continue;

        nodes.push(
            <div className="assign-courier__list--element" key={`${courier.id}-assign-courier-row`}>
                <div className="assign-courier-column">
                    <Radio
                        disabled={props.courier ? props.courier.id === courier.id : false}
                        onChange={() => props.setSelectedCourier(courier)}
                        className="assign-courier-column--radio-button"
                        labelText={`${courier.name}`}
                        value={props.selected?.id || ""}
                        radioID={`${courier.id}`}
                        id="courier-radios"
                    />
                </div>
                <div className="assign-courier-column">
                    {t("courier_addresses")}:{" "}
                    <span className="assign-courier-column--count">
                        {findRoundOrderCount(courier)} {t("courier_order_count_unit")}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="assign-courier-view">
            <h3 className="assign-courier__title">{t("assign_courier_title")}</h3>
            <div className="assign-courier__list">{nodes}</div>
            <ErrorHandler error={props.error} />
            <Button
                hasContainer
                onClick={props.assignOrderToCourier}
                disabled={props.selected ? false : true}
            >
                {props.loading ? <Spinner isNegatedColor isTiny /> : t("assign_courier_button")}
            </Button>
        </div>
    );
};

export default AssignCourierView;
