import { IArchivedDeliveryElementFunctions } from "./ArchivedDeliveries.view";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { useTranslation } from "react-i18next";
import { useCouriers } from "../../../Contexts/Courier.context";
import { OrderModel } from "../../../Models/Order.model";
import { textFilter } from "../../../Utils/TextFilter.util";
import { useState } from "react";
import { ToMoney } from "xa-generics";
import ArchivedOrderElement from "./ArchivedOrder.element.view";
import DropMenu from "../../UI/DropMenu/DropMenu.view";
import moment from "moment";
import { DATETIMEFORMAT } from "../../../Static/DateFormats.static";

export interface IArchivedDeliveryElementViewProps extends IArchivedDeliveryElementFunctions {
    archivedDelivery: CourierTurnModel;
    counterFilter: number;
    textFilter: RegExp;
}

const ArchivedDeliveryElementView: React.FC<IArchivedDeliveryElementViewProps> = (
    props
): React.ReactElement => {
    const { couriersById } = useCouriers();
    const round: CourierTurnModel = props.archivedDelivery;
    const orders: OrderModel[] = props.archivedDelivery.orders;
    const courier = props.archivedDelivery.getCourier(couriersById);

    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    let classes: string = "delivery-unit archived-delivery";
    if (isOpened) classes += " delivery-unit-opened";

    return (
        <>
            <div className={classes}>
                <div className="delivery-unit__opener">
                    <div
                        className="delivery-unit__opener--icon-container"
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        <span className="arrow icon"></span>
                    </div>
                </div>
                <div className="delivery-unit__data">
                    <div className="delivery-unit__data--name">{courier?.name || "-"}</div>
                    <div className="delivery-unit__data--counters">
                        <div className="delivery-unit__data--counters--leftover">
                            {t("delivery_start")}
                        </div>
                        <div className="delivery-unit__data--counters--delivered">
                            : {moment(round.started_at).format(DATETIMEFORMAT)}
                        </div>
                    </div>
                    <div className="delivery-unit__data--counters">
                        <div className="delivery-unit__data--counters--leftover">
                            {t("finished_round_at")}
                        </div>
                        <div className="delivery-unit__data--counters--delivered">
                            : {moment(round.finished_at).format(DATETIMEFORMAT)}
                        </div>
                    </div>
                </div>
                <div className="delivery-unit__price">
                    <span className="delivery-unit__price--label">{t("total_delivery_price")}</span>
                    {ToMoney(`${props.archivedDelivery.totalPrice}`).split(".")[0]} Ft
                </div>
                <div className="delivery-unit__menu">
                    <DropMenu>
                        <div
                            className="menu-item"
                            onClick={() => props.printTurnOrders(orders.map((order) => order.id))}
                        >
                            {t("print_big_block")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                props.revoke(round.id);
                            }}
                        >
                            {t("delivery_revoke")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                window.open(`/print/${round.id}`, "_blank");
                            }}
                        >
                            {t("delivery_print_sequence")}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                window.open(`/print/menu/${round.id}`, "_blank");
                            }}
                        >
                            {t("delivery_print_menu")}
                        </div>
                    </DropMenu>
                </div>
            </div>
            <div className={"unit-order-container archived-order-container"}>
                {isOpened &&
                    orders.map((order: OrderModel, index: number) => {
                        if (props.textFilter && !textFilter(props.textFilter, order)) return null;
                        if (props.counterFilter && order.counter !== props.counterFilter)
                            return null;
                        return (
                            <ArchivedOrderElement
                                reloadArchivedDeliveries={props.reloadArchivedDeliveries}
                                assignToAnotherCourier={props.assignToOtherCourier}
                                backToAddresses={props.backToAddresses}
                                key={`${index}-archived-order-index`}
                                delivery={props.archivedDelivery}
                                order={order}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default ArchivedDeliveryElementView;
