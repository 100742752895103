import { cloneDeep } from "lodash";
import { useOrders } from "./Orders.context";
import { useSocket } from "./Socket.context";
import { useAccess } from "../Modules/Access/Context/Access.context";
import { useNotify } from "../Components/Notify/Context/Notify.context";
import { useDelivery } from "./DeliveryRounds.context";
import { useCouriers } from "./Courier.context";
import { INotification } from "../Components/Notify/Interface/INotification.interface";
import { useTranslation } from "react-i18next";
import { Statuses, IUpdateType } from "../Static/Statuses.static";
import { useDidMount, useTimeout } from "xa-generics";
import { Context, createContext, useContext } from "react";
import {
    ISocketOrder,
    ISocketAction,
    IReloadStacker,
    ISocketUnitCoord,
    ISocketCourierCoordList
} from "../Interfaces/ISocketTask.interface";

/**
 * ## Reload stacker
 *
 * Essentially, all socket messages end up reloading the couriers, orders, or rounds,
 * but it may be possible that the system would reload the same thing multiple
 * times at the same time based on some socket messages.
 *
 * To avoid this behaviour, every reload is redirected to three separate timeout
 * containers that put some delay on the reload request and stack the same kind,
 * also deleting the previous ones to prevent multi-calling the same method.
 *
 * By doing this, it's much less likely that the same list would be reloaded
 * multiple times in the same second.
 */
export const RabbitStacker: Context<IReloadStacker> = createContext<IReloadStacker>(null as never);

RabbitStacker.displayName = "ReloadStackerContext";

export interface IRabbitStackerProviderProps {}

export const RabbitStackerProvider: React.FC<IRabbitStackerProviderProps> = (props) => {
    const { t } = useTranslation();

    const { loadRestaurant } = useAccess();
    const orderContext = useOrders();
    const { socket } = useSocket();
    const courierContext = useCouriers();
    const deliveryContext = useDelivery();
    const notifyContext = useNotify();

    const { setTm, clearTm } = useTimeout();

    //Unit coordinate related methods
    const updateRoundUnitCoords = (coords: ISocketUnitCoord): void => {
        deliveryContext.setList((current) => {
            const newList = cloneDeep(current);
            for (let index in newList) {
                const delivery = newList[index];
                if (delivery.id === coords.courier_turn_id.toString()) {
                    newList[index].couierCoords = {
                        coord_lat: coords.coord_lat,
                        coord_lng: coords.coord_lng
                    };

                    storeLastKnownCoordinates(coords);
                }
            }
            return newList;
        });
    };

    const storeLastKnownCoordinates = (new_coord: ISocketUnitCoord): void => {
        let data: string | null = localStorage.getItem("last-coords");
        if (data) {
            let coords: ISocketCourierCoordList = JSON.parse(data);
            coords[new_coord.courier_id] = new_coord;
            localStorage.setItem("last-coords", JSON.stringify(coords));
        } else {
            localStorage.setItem(
                "last-coords",
                JSON.stringify({ [new_coord.courier_id]: new_coord })
            );
        }
    };

    //Notification handler
    const notificationHandler = (noti: INotification): void => {
        notifyContext.addNotification(noti);

        setTm(
            () => {
                notifyContext.removeNotification(noti);
            },
            8000,
            noti.action
        );
    };

    //Response parsers
    const orderStatusParser = (data: ISocketOrder): void => {
        if (data.status === "done") {
            reloadOrders();
            reloadRounds();
            return;
        }
        const actionType: IUpdateType = Statuses.getUpdateType(data.status);
        if (actionType === "ORDERS") {
            reloadOrders(() => {
                notificationHandler({
                    action: "ORDERS_CHANGED",
                    displayText: t("socket_orders_updated")
                });
            });
        }
        if (actionType === "ROUNDS") {
            reloadRounds(() => {
                notificationHandler({
                    action: "ROUNDS_CHANGED",
                    displayText: t("socket_rounds_updated")
                });
            });
        }
    };

    const parseSocketResponse = (data: ISocketAction): void => {
        switch (data.action) {
            case "DELIVERY_UNIT.COORD_CHANGED":
                updateRoundUnitCoords(data as ISocketUnitCoord);
                break;
            case "ORDER.STATUS_CHANGED":
                orderStatusParser(data as ISocketOrder);
                break;
            case "COURIER_TURN.STATUS_CHANGED":
                reloadRounds();
                break;
            case "DELIVERY_UNIT.AVAILABILITY_CHANGED":
                reloadCouriers(() =>
                    notificationHandler({
                        action: "AVAILABILITY_CHANGED",
                        displayText: t("socket_availability_changed")
                    })
                );
                break;
            case "ORDER.SEQUENCE_CHANGED":
                reloadRounds(() => {
                    notificationHandler({
                        action: "ORDER.SEQUENCE_CHANGED",
                        displayText: t("socket_sequence_updated")
                    });
                });
                break;
            case "ORDER.LOCATION_CHANGED":
                reloadOrders();
                reloadRounds(() => {
                    notificationHandler({
                        action: "ORDER.LOCATION_CHANGED",
                        displayText: t("socket_location_changed")
                    });
                });
                break;
            case "ORDER.ROUND_CALCULATED":
                reloadRounds(() => {
                    notificationHandler({
                        action: "ORDER.ROUND_CALCULATED",
                        displayText: t("socket_round_calculated")
                    });
                });
                break;
            case "ORDER.COURIER_CHANGED":
                reloadRounds(() => {
                    notificationHandler({
                        action: "ORDER.COURIER_CHANGED",
                        displayText: t("socket_courier_changed")
                    });
                });
                break;
            case "ORDER.DELETED":
                const response = data as ISocketAction<{ is_on_round: boolean }>;
                if (response.is_on_round) {
                    reloadRounds(() => {
                        notificationHandler({
                            action: "ORDER.DELETED",
                            displayText: t("socket_order_deleted")
                        });
                    });
                } else {
                    reloadOrders(() => {
                        notificationHandler({
                            action: "ORDER.DELETED",
                            displayText: t("socket_order_deleted")
                        });
                    });
                }
                break;
            case "RESTAURANT.FLUSHED":
            case "ORDER.REVOKED":
                reloadOrders();
                reloadRounds();
                break;
            case "ORDER.ASSIGNED_TO_COURIER":
                reloadOrders();
                reloadRounds(() => {
                    notificationHandler({
                        action: "ORDER.ASSIGNED_TO_COURIER",
                        displayText: t("socket_order_assigned")
                    });
                });
                break;
            case "COURIER_TURN.REVOKED":
                reloadRounds();
                break;
            case "RESTAURANT.SCHEME_GENERATED":
                loadRestaurant();
                break;
            case "RESTAURANT.ORDER_DISPATCH_DONE":
                loadRestaurant();
                deliveryContext.reloadRounds();
                orderContext.loadOrders();
                break;
            case "RESTAURANT_UPDATE":
                loadRestaurant();
                break;
            default:
                return;
        }
    };

    //Stack reloaders
    const reloadCouriers = (callFunctionAtReload?: Function): void => {
        setTm(
            () => {
                courierContext.reloadCouriers();
                if (callFunctionAtReload) callFunctionAtReload();
            },
            400,
            "courier_stack"
        );
    };

    const reloadOrders = (callFunctionAtReload?: Function): void => {
        setTm(
            () => {
                orderContext.loadOrders();
                if (callFunctionAtReload) callFunctionAtReload();
            },
            400,
            "order_stack"
        );
    };

    const reloadRounds = (callFunctionAtReload?: Function): void => {
        setTm(
            () => {
                deliveryContext.reloadRounds();
                if (callFunctionAtReload) callFunctionAtReload();
            },
            400,
            "round_stack"
        );
    };

    const closeOnUnmount = (): void => {
        clearTm("courier_stack");
        clearTm("order_stack");
        clearTm("round_stack");
        socket.close();
    };

    useDidMount(() => {
        socket.on("TASK", (data: ISocketAction<any>) => {
            parseSocketResponse(data);
        });
    }, closeOnUnmount);

    return (
        <RabbitStacker.Provider
            value={{
                reloadCouriers,
                reloadRounds,
                reloadOrders
            }}
        >
            {props.children}
        </RabbitStacker.Provider>
    );
};

export const useStacker = () => useContext(RabbitStacker);
