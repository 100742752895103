import { ICalculatedSummary } from "../Interfaces/ICalculatedSummary.interface";
import { ISummaryViewProps } from "./Summary.view";
import { calculateSummary } from "../Util/CalculateSummary.util";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../Utils/Currency.util";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import "./Less/SumaryData.css";

export interface ICenterSummaryProps extends ISummaryViewProps {}

const CenterSummary: React.FC<ICenterSummaryProps> = (props) => {
    const summary: ICalculatedSummary = calculateSummary(props.centerSummary.payment_methods);
    const { restaurant } = useAccess();
    const { t } = useTranslation();

    return (
        <div className="summary-data">
            <h1 className="summary-data__title">{restaurant.name}</h1>
            <div className="summary-data__list">
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_km_price")}</div>
                    <div className="sum-value">{formatCurrency(restaurant.conf_km_price)}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_all_address")}</div>
                    <div className="sum-value">{formatCurrency(summary.all.sum)}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_succ_addr")}</div>
                    <div className="sum-value">{formatCurrency(summary.delivered.sum)}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_unsucc_addr")}</div>
                    <div className="sum-value">{formatCurrency(summary.failed.sum)}</div>
                </div>
            </div>
        </div>
    );
};

export default CenterSummary;
