import { Context, createContext, useState, useContext } from "react";
import { IMapFilterContext } from "../Interfaces/IMapFilterContext.interface";
import { IMapFilterForm } from "../Interfaces/IMapFilterForm.interface";
import { useTranslation } from "react-i18next";
import { ChangeHandler } from "../Utils/ChangeHandler.util";
import { mapFilterForm } from "../Static/MapFilter.form";
import { CourierModel } from "../Models/Courier.model";
import { IInputChange } from "xa-inputs";
import { useTimeout } from "xa-generics";
import { useNotify } from "../Components/Notify/Context/Notify.context";
import { cloneDeep } from "lodash";

/**
 * ## MapFilterContext
 */
const MapFilterContext: Context<IMapFilterContext> = createContext<IMapFilterContext>({
    form: null as any,
    onChange: null as any
});

MapFilterContext.displayName = "MapFilterContext";

interface IMapFilterContextWrapperProps {}

/**
 * ## MapFilter context wrapper component
 *
 */
export const MapFilterContextWrapper: React.FC<IMapFilterContextWrapperProps> = (props) => {
    const notification = useNotify();
    const { t } = useTranslation();
    const { setTm } = useTimeout();
    const [form, setForm] = useState<IMapFilterForm>(
        (function () {
            let initialForm = cloneDeep(mapFilterForm);
            const value = new CourierModel({
                id: "UNASSIGNED",
                first_name: t("unassignedCourier") as string
            });
            initialForm.courierFilter.options.push(value);
            initialForm.courierFilter.value = [value];
            return initialForm;
        })()
    );

    const onChange = (e: IInputChange): void => {
        let newForm = ChangeHandler(form, e.fieldName, e.value);

        if (e.fieldName === "date") {
            sendNotification();
        }
        setForm(newForm);
    };

    const sendNotification = (): void => {
        notification.addNotification({
            action: "DATE_CHANGED",
            displayText: t("main_date_changed")
        });

        setTm(
            () => {
                notification.removeNotification({
                    action: "DATE_CHANGED",
                    displayText: t("main_date_changed")
                });
            },
            20000,
            "date_change"
        );
    };

    return (
        <MapFilterContext.Provider
            value={{
                onChange,
                form
            }}
        >
            {props.children}
        </MapFilterContext.Provider>
    );
};
export const useMapFilter = () => useContext(MapFilterContext);
