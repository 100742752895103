import { IOnChange, TextInput, Button } from "xa-inputs";
import { IOrderEditorControllerProps } from "../Controller/OrderEditor.controller";
import { ILoading, IError } from "xa-generics";
import { IOrderEditorForm } from "../Interface/IOrderEditorForm.interface";
import { useTranslation } from "react-i18next";
import { ErrorHandler } from "xa-error-with-lang";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/OrderEditor.css";

export interface IOrderEditorViewProps extends IOrderEditorControllerProps {
    onSubmit: (e: React.FormEvent) => void;
    form: IOrderEditorForm;
    onChange: IOnChange;
    saveable: boolean;
    loading: ILoading;
    error: IError;
}

const OrderEditorView: React.FC<IOrderEditorViewProps> = (props): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="order-editor-view">
            <h1 className="order-editor__title">{t("order_editor_title")}</h1>
            <form onSubmit={props.onSubmit} className="order-editor__form">
                <TextInput
                    {...props.form.city}
                    onChange={props.onChange}
                    labelText={t(props.form.city.id)}
                />
                <TextInput
                    {...props.form.street}
                    onChange={props.onChange}
                    labelText={t(props.form.street.id)}
                />
                <TextInput
                    {...props.form.house_number}
                    onChange={props.onChange}
                    labelText={t(props.form.house_number.id)}
                />
                <TextInput
                    {...props.form.floor}
                    onChange={props.onChange}
                    labelText={t(props.form.floor.id)}
                />
                <TextInput
                    {...props.form.door}
                    onChange={props.onChange}
                    labelText={t(props.form.door.id)}
                />
                <TextInput
                    {...props.form.bell}
                    onChange={props.onChange}
                    labelText={t(props.form.bell.id)}
                />
                <div className="button-wrapper">
                    <ErrorHandler error={props.error} />
                </div>
                <Button disabled={!props.saveable} hasContainer>
                    {props.loading ? <Spinner isTiny isNegatedColor /> : t("order_editor_save")}
                </Button>
            </form>
        </div>
    );
};

export default OrderEditorView;
