import { ILoading, IError, useDidMount, IDynamicObject, Objectify } from "xa-generics";
import { Context, createContext, useContext, useState } from "react";
import { ICourierContext } from "../Interfaces/ICourierContext.interface";
import { CourierModel } from "../Models/Courier.model";
import { CourierDAO } from "../Api/DAO/Courier.dao";

/**
 * ## CourierContext
 */
const CourierContext: Context<ICourierContext> = createContext<ICourierContext>(null as never);

CourierContext.displayName = "CourierContext";

interface ICourierContextWrapperProps {}

/**
 * ## Courier context wrapper component
 *
 */
export const CourierContextProvider: React.FC<ICourierContextWrapperProps> = (props) => {
    const [couriersById, setCouriersById] = useState<IDynamicObject<CourierModel>>({});
    const [couriers, setCouriers] = useState<CourierModel[]>([]);
    const [loading, setLoading] = useState<ILoading>(true);
    const [error, setError] = useState<IError>(null);

    const loadCouriers = (): void => {
        setLoading(
            CourierDAO.loadCouriers()
                .then((list) => {
                    setCouriers(list);
                    setCouriersById(Objectify(list, "id"));
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useDidMount(loadCouriers);

    const onManualSetCouriers = (courierList: CourierModel[]): void => {
        setCouriers(courierList);
        setCouriersById(Objectify(courierList, "id"));
    };

    return (
        <CourierContext.Provider
            value={{
                setCouriers: onManualSetCouriers,
                reloadCouriers: loadCouriers,
                couriersById,
                couriers,
                loading,
                error
            }}
        >
            {props.children}
        </CourierContext.Provider>
    );
};

export const useCouriers = () => useContext(CourierContext);
