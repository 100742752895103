import { IError, ILoading, useDidMount } from "xa-generics";
import { ProductCountModel } from "../Model/ProductCount.model";
import { ProductCountDAO } from "../DAO/ProductCount.dao";
import { useDateFilter } from "../../../Contexts/DateFilter.context";
import { useState } from "react";
import { APIDATE } from "../../../Static/DateFormats.static";
import ProductCountView from "../View/ProductCount.view";
import moment from "moment";

export interface IProductCountProps {
    close: () => void;
}

const ProductCount: React.FC<IProductCountProps> = (props) => {
    const { form } = useDateFilter();
    const [loading, setLoading] = useState<ILoading>(false);
    const [productCounts, setProductCounts] = useState<ProductCountModel[]>([]);
    const [error, setError] = useState<IError>(null);

    const init = (): void => {
        const date = form.date.value as Date;
        setLoading(
            ProductCountDAO.loadProductCount(moment(date).format(APIDATE))
                .then((models) => setProductCounts(models))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const onKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Escape") props.close();
    };

    const onEscKeyDown = (): (() => void) => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    };
    useDidMount(() => {
        init();
        onEscKeyDown();
    });

    return (
        <ProductCountView
            productCounts={productCounts}
            setError={setError}
            loading={loading}
            error={error}
            {...props}
        />
    );
};

export default ProductCount;
