import { APIDATE } from "./../../Static/DateFormats.static";
import { AXIOSNAMES } from "../../Static/AxiosInstanceNames.static";
import { TaxPrinterModel } from "../../Models/TaxPrinter.model";
import { RestaurantModel } from "../../Models/Restaurant.model";
import { axios, IDynamicObject } from "xa-generics";
import moment from "moment";

export abstract class RootDAO {
    private static readonly base: string = "/api/restaurants";
    protected static restId: string = "";
    protected static rest: string = "";
    protected static restTurns: string = "";
    protected static restCouriers: string = "";
    protected static restOrders: string = "";

    public static setCenterID(rid: string | number): void {
        this.restId = `${rid}`;
        this.restTurns = `${this.base}/${rid}/courier-turns`;
        this.restCouriers = `${this.base}/${rid}/couriers`;
        this.restOrders = `${this.base}/${rid}/orders`;
        this.rest = `${this.base}/${rid}`;
    }

    public static async getCenterData(rid: string | number): Promise<RestaurantModel> {
        this.setCenterID(rid);
        const request = await axios.getInstance().get<IDynamicObject>(`${this.rest}`);
        return new RestaurantModel(request.data);
    }

    public static async updateRestaurant(data: IDynamicObject<any>): Promise<RestaurantModel> {
        const request = await axios.getInstance().patch<IDynamicObject<any>>(this.rest, data);
        return new RestaurantModel(request.data);
    }

    public static async setDispatch(
        data: IDynamicObject<any>,
        date: Date = new Date()
    ): Promise<void> {
        await axios
            .getInstance()
            .patch<void>(`${this.rest}/dispatch?date=${moment(date).format(APIDATE)}`, data);
        return;
    }

    public static async loadTaxPrinter(rest: RestaurantModel): Promise<TaxPrinterModel> {
        const request = await axios
            .getInstance(AXIOSNAMES.PRINT)
            .get<IDynamicObject<any>>(
                `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/stats/tax-printer`
            );
        return new TaxPrinterModel(request.data);
    }
}
