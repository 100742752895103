import moment from "moment";
import { ModelConstructor, IDynamicObject } from "xa-generics";
import { ICoords } from "../Interfaces/ICoords.interface";
import { CourierModel } from "./Courier.model";
import { OrderModel } from "./Order.model";

export class CourierTurnModel {
    constructor(rawData?: IDynamicObject<any>, maxDelay: number = 45) {
        if (!rawData) return;
        const data = rawData as CourierTurnMData;
        ModelConstructor(data, this);

        this.init(rawData, maxDelay);
    }

    auto_fill: boolean = false;
    id: string = "";
    courier_id: string = "";
    restaurant_id: string = "";
    status: string = "";
    created_at: string = "";
    updated_at: string = "";
    finish_estimated_travel_time: number = 0;
    finish_distance: number = 0;
    all_order_qty: number = 0;
    delivered_order_qty: number = 0;
    failed_order_qty: number = 0;
    full_travel_time: number = 0;
    finish_real_travel_time: number = 0;
    started_at: string = "";
    finished_at: string = "";
    is_optimized: boolean = false;
    is_calculated: boolean = false;
    full_time: string = "";
    miss_time: string = "";
    success_rate: string = "";
    need_order_parsing: boolean = false;
    is_force_finished: string = "";
    delivery_at: string = "";

    orders: OrderModel[] = [];

    //Own props
    totalPrice: number = 0;
    created_at_as_date: Date = new Date();
    started_at_as_date: Date = new Date();

    couierCoords: ICoords | null = null;

    protected init(rawData: IDynamicObject<any>, maxDelay: number): void {
        const data = rawData as CourierTurnMData;
        this.created_at_as_date = moment(this.created_at).toDate();
        this.started_at_as_date = moment(this.started_at).toDate();

        if (data.orders instanceof Array) {
            for (let index in data.orders) {
                const orderData = data.orders[index];
                const order = new OrderModel(orderData, maxDelay, parseInt(index));
                if (
                    order.progress !== "failed" &&
                    !order.is_paid &&
                    order.payment_method !== "simplepay"
                ) {
                    this.totalPrice += order.total_price;
                }
                this.orders.push(order);
            }
        }
    }

    public getCourier(couriers: IDynamicObject<CourierModel>): CourierModel | null {
        return couriers[this.courier_id] || null;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type CourierTurnMData = Partial<InstanceType<typeof CourierTurnModel>>;
