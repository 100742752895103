import { IDynamicObject } from "xa-generics";
import { ISelectConfig } from "xa-inputs";

export const inputLang: IDynamicObject<ISelectConfig> = {
    hu: {
        noOption: "Nincsenek opciók...",
        loadingMessage: "Töltés...",
        placeholder: "Válassz a listából...",
        searching: "Keresés..."
    },
    en: {
        noOption: "There are no options...",
        loadingMessage: "Loading...",
        placeholder: "Select an option...",
        searching: "Searching..."
    }
};
