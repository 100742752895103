import { Context, createContext, useState, useEffect, useContext } from "react";

/**
 * ## NowContext
 */
const NowContext: Context<number> = createContext<number>(Date.now());

NowContext.displayName = "NowContext";

export const NowContextWrapper: React.FC = (props): JSX.Element | null => {
    const [now, setNow] = useState<number>(Date.now());

    useEffect(
        () => {
            const interval = setInterval(() => {
                setNow(Date.now());
            }, 1000);
            return () => clearInterval(interval);
        },
        //eslint-disable-next-line
        []
    );

    return <NowContext.Provider value={now}>{props.children}</NowContext.Provider>;
};

export const useNow = () => useContext(NowContext);
