import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { useTranslation } from "react-i18next";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { ModalContext } from "xa-modal";
import { CourierModel } from "../../../Models/Courier.model";
import { useCouriers } from "../../../Contexts/Courier.context";
import { OrderModel } from "../../../Models/Order.model";
import { useContext } from "react";
import { ToMoney } from "xa-generics";
import AssignCourierController from "../../AssignCourier/Controller/AssignCourier.controller";
import OrderEditorController from "../../OrderEditor/Controller/OrderEditor.controller";
import DropMenu from "../../UI/DropMenu/DropMenu.view";
import moment from "moment";
import { DATETIMEFORMAT } from "../../../Static/DateFormats.static";

export interface IArchivedOrderElementProps {
    assignToAnotherCourier: (order: OrderModel, courier: CourierModel) => void;
    backToAddresses: (order: OrderModel) => void;
    reloadArchivedDeliveries: () => void;
    delivery: CourierTurnModel;
    order: OrderModel;
}

const ArchivedOrderElement: React.FC<IArchivedOrderElementProps> = ({ order, ...props }) => {
    const dictionary = useDictionary();
    const modal = useContext(ModalContext);
    const { couriersById } = useCouriers();
    const { t } = useTranslation();

    let classes: string[] = ["unit-order", "archived-order-element"];
    if (order.progress === "failed" || order.archived_at) {
        classes.push("failed-order");
    }

    return (
        <div className={classes.join(" ")}>
            <div className="unit-order__status">
                <div className="unit-order__status--counter">
                    <div className="unit-order__status--counter--number">{order.counter}</div>
                    <div className={`circle unit-order__status--counter--status-dot archived`} />
                </div>
            </div>
            <div className="unit-order__data">
                <div className="unit-order__data--address">{order.getFormattedAddress(t)}</div>
                <div className="delivery-unit__data--counters">
                    <div className="delivery-unit__data--counters--leftover">
                        {t("delivered_at")}
                    </div>
                    <div className="delivery-unit__data--counters--delivered">
                        : {moment(order.change_status_at).format(DATETIMEFORMAT)}
                    </div>
                </div>
                <div className="unit-order__data--price">
                    <span className="unit-order__data--price-label">
                        {t("order_delivery_price")}:{" "}
                    </span>
                    {order.is_paid ? "0" : ToMoney(`${order.total_price}`).split(".")[0]} Ft
                </div>
                <div className="unit-order__data--comment">
                    {order.name}: {order.comment || "-"}
                </div>
            </div>
            <div className="unit-order__payment">
                <div className="unit-order__payment--method">
                    {dictionary.parsePayment(order.payment_method)}
                </div>
                <div className="unit-order__payment--time">{order.showAtAsString}</div>
            </div>
            <div className="unit-order__menu">
                <DropMenu>
                    <div
                        className="menu-item"
                        onClick={() => {
                            modal.setContent(
                                <OrderEditorController
                                    order={order}
                                    returnNewOrder={() => {
                                        modal.setContent(null);
                                        props.reloadArchivedDeliveries();
                                    }}
                                />
                            );
                        }}
                    >
                        {t("order_editor_title")}
                    </div>
                    <div className="menu-item" onClick={() => props.backToAddresses(order)}>
                        {t("unit_order_menu_back_to_addresses")}
                    </div>
                    <div
                        className="menu-item"
                        onClick={() =>
                            modal.setContent(
                                <AssignCourierController
                                    order={order}
                                    courier={props.delivery.getCourier(couriersById)}
                                    returnSelectedCourier={(courier: CourierModel) => {
                                        modal.setContent(null);
                                        props.assignToAnotherCourier(order, courier);
                                    }}
                                />
                            )
                        }
                    >
                        {t("unit_order_menu_swap")}
                    </div>
                </DropMenu>
            </div>
        </div>
    );
};

export default ArchivedOrderElement;
