import { ILoading, IError, useMountWithTriggers } from "xa-generics";
import { DetailedCourierTurnModel } from "../../../Models/DetailedCourierTurn.model";
import { DetailedCourierModel } from "../../../Models/DetailedCourier.model";
import { ProductModel } from "../Model/Product.model";
import { ErrorHandler } from "xa-error-with-lang";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { orderBy } from "lodash";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/PrintRound.css";
import "./Less/PrintMenu.css";

export interface IPrintMenuViewProps {
    turn: DetailedCourierTurnModel | null;
    courier: DetailedCourierModel | null;
    products: ProductModel[];
    loading: ILoading;
    error: IError;
}

const PrintMenuView: React.FC<IPrintMenuViewProps> = (props): React.ReactElement => {
    const print = (): void => {
        if (!props.loading && !props.error && props.turn && props.courier) {
            window.print();
        }
    };

    const { restaurant } = useAccess();
    const sortDirection = restaurant.conf_sort_product_method;
    const sortProductsBy = restaurant.conf_sort_product_by;

    useMountWithTriggers(print, [props.loading, props.error, props.turn, props.courier]);

    if (props.loading || !props.turn || !props.courier) return <Spinner isLarge />;
    if (props.error) {
        return (
            <div className="print-menu">
                <ErrorHandler error={props.error} />
            </div>
        );
    }

    const productsList = !sortProductsBy
        ? props.products
        : orderBy(props.products, sortProductsBy, sortDirection);

    const nodes: React.ReactNode[] = [];
    let sum: number = 0;

    for (const item of productsList) {
        sum += item.quantity;
        nodes.push(
            <div
                className="table__row"
                key={`${item.product_name}-${item.category_name}-${item.quantity}`}
            >
                <div className="table__col col-1">{item.category_name}</div>
                <div className="table__col col-2">{item.product_name}</div>
                <div className="table__col col-3">{item.quantity}</div>
            </div>
        );
    }

    return (
        <div className="print-menu" id="printarea">
            <div className="print-menu__courier">
                Futár neve: {props.courier.first_name} {props.courier.last_name}
            </div>
            <div className="table__row table__head">
                <div className="table__col col-1">Kategória</div>
                <div className="table__col col-2">Étel megnevezés</div>
                <div className="table__col col-3">Mennyiség</div>
            </div>
            {nodes}
            <div className="table__row table__footer">
                <div className="table__col col-1" />
                <div className="table__col col-2">Összesen</div>
                <div className="table__col col-3">{sum}</div>
            </div>
        </div>
    );
};

export default PrintMenuView;
