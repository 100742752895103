import { ModelConstructor, IDynamicObject } from "xa-generics";

export class AccessModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as AccessMData;
        ModelConstructor(data, this);

        if (data.restaurant_ids instanceof Array) {
            this.restaurant_ids = data.restaurant_ids.map((id) => `${id}`);
        }
    }

    id: string = "";
    email: string = "";
    partner_id: string = "";
    has_pin: boolean = false;
    restaurant_id: string = "";
    restaurant_ids: string[] = [];
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type AccessMData = Partial<InstanceType<typeof AccessModel>>;
