import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { IInputChange, IInputText, IInputSelect } from "xa-inputs";
import { useState, useContext } from "react";
import { IOrderEditorForm } from "../Interface/IOrderEditorForm.interface";
import { OrderEditorForm } from "../Static/OrderEditor.form";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { ChangeHandler } from "../../../Utils/ChangeHandler.util";
import { ModalContext } from "xa-modal";
import { useDelivery } from "../../../Contexts/DeliveryRounds.context";
import { OrderModel } from "../../../Models/Order.model";
import { useOrders } from "../../../Contexts/Orders.context";
import { cloneDeep } from "lodash";
import { OrderDAO } from "../../../Api/DAO/Order.dao";
import OrderEditorView from "../View/OrderEditor.view";
import qs from "qs";

export interface IOrderEditorControllerProps {
    order: OrderModel;
    returnNewOrder?: (order: OrderModel) => void;
}

const OrderEditorController: React.FC<IOrderEditorControllerProps> = (
    props
): React.ReactElement => {
    const dictionary = useDictionary();
    const deliveryContext = useDelivery();
    const orderContext = useOrders();
    const modal = useContext(ModalContext);

    const initForm = (currentForm?: IOrderEditorForm): IOrderEditorForm => {
        let initialForm: IOrderEditorForm = cloneDeep(currentForm || OrderEditorForm);
        initialForm.bell.value = props.order.bell;
        initialForm.city.value = props.order.city;
        initialForm.door.value = props.order.door;
        initialForm.floor.value = props.order.floor;
        initialForm.house_number.value = props.order.house_number;
        initialForm.street.value = props.order.street;

        return initialForm;
    };

    const [form, setForm] = useState<IOrderEditorForm>(initForm());
    const [saveable, setSaveable] = useState<boolean>(false);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const reinitOnDictChange = (): void => {
        if (!dictionary.loading) {
            setForm(initForm(form));
        }
    };

    useMountWithTriggers(reinitOnDictChange, [dictionary]);

    const parseForm = (): { asString: string; model: OrderModel } => {
        let model = cloneDeep(props.order);
        for (let input of Object.values(form)) {
            const field: IInputText | IInputSelect = input;

            if ("selectType" in field) {
                model.payment_method = field.value?.id || "";
                continue;
            }

            model[field.id as keyof OrderModel] = field.value as never;
        }
        return {
            asString: qs.stringify(model),
            model: model
        };
    };

    const updateOrderList = (order: OrderModel): void => {
        let orders = cloneDeep(orderContext.list);
        const index: number = orders.findIndex((order: OrderModel) => order.id === props.order.id);
        if (index > -1) {
            orders[index] = order;
        }
        orderContext.updateList(orders);
    };

    const updateDeliveryList = (order: OrderModel): void => {
        let turns = cloneDeep(deliveryContext.list);
        const indexOfRound: number = turns.findIndex((turn) => turn.id === order.courier_turn_id);
        if (indexOfRound > -1) {
            const indexOfOrder: number = turns[indexOfRound].orders.findIndex(
                (o) => o.id === order.id
            );
            if (indexOfOrder > -1) {
                turns[indexOfRound].orders[indexOfOrder] = order;
            }
        }
        deliveryContext.setList(turns);
    };

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();

        const data: {
            asString: string;
            model: OrderModel;
        } = parseForm();
        setLoading(
            OrderDAO.updateOrder(data.asString, props.order.id)
                .then(() => {
                    if (props.returnNewOrder) return props.returnNewOrder(data.model);
                    if (props.order.courier_turn_id) {
                        updateDeliveryList(data.model);
                    } else {
                        updateOrderList(data.model);
                    }
                    modal.setContent(null);
                })
                .catch((error: IError) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    return (
        <OrderEditorView
            {...props}
            onChange={(e: IInputChange) => {
                setForm(ChangeHandler(form, e.fieldName, e.value));
                setSaveable(true);
            }}
            onSubmit={onSubmit}
            saveable={saveable}
            loading={loading}
            error={error}
            form={form}
        />
    );
};

export default OrderEditorController;
