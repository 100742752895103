import { ILoading, IError, useMountWithTriggers } from "xa-generics";
import { IDeliveryStatusCount } from "../Interfaces/IDeliveryStatusCount.interface";
import { DeliveryStatusCount } from "../Static/DeliveryStatusCount.static";
import { IAutoDispatchTypes } from "../Interfaces/IParsingTypes.type";
import { IDeliveryContext } from "../../../Interfaces/IDeliveryContext.interface";
import { RestaurantModel } from "../../../Models/Restaurant.model";
import { useDateFilter } from "../../../Contexts/DateFilter.context";
import { useDelivery } from "../../../Contexts/DeliveryRounds.context";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { cloneDeep } from "lodash";
import { Statuses } from "../../../Static/Statuses.static";
import { useState } from "react";
import { RootDAO } from "../../../Api/DAO/Root.dao";
import DeliveryView from "../View/Delivery.view";

export interface IDeliveryControllerProps {}

const DeliveryController: React.FC<IDeliveryControllerProps> = (props): React.ReactElement => {
    const deliveryContext: IDeliveryContext = useDelivery();
    const { restaurant, setRestaurant } = useAccess();
    const dateFilter = useDateFilter();

    const [statusCount, setStatusCount] = useState<IDeliveryStatusCount>(DeliveryStatusCount);
    const [textFilter, setTextFilter] = useState<string>("");
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const countStatus = (): void => {
        let counter = cloneDeep(DeliveryStatusCount);

        for (let delivery of deliveryContext.list) {
            counter.allCount += 1;

            if (delivery.status === Statuses.deliveryStatuses.on_road) {
                counter.onRoadCount += 1;
                continue;
            }
            if (delivery.status === Statuses.deliveryStatuses.go_back) {
                counter.goBackCount += 1;
                continue;
            }
            if (delivery.status === Statuses.deliveryStatuses.created) {
                counter.freeCount += 1;
                continue;
            }
        }
        setStatusCount(counter);
    };

    useMountWithTriggers(countStatus, [deliveryContext.list]);

    const manualAutoDispatch = (type: IAutoDispatchTypes): void => {
        const data = { [type]: true };

        setLoading(
            RootDAO.setDispatch(data, dateFilter.form.date.value as Date)
                .then(() => {
                    let newCenter = cloneDeep(restaurant);
                    let key = `conf_${type}` as keyof RestaurantModel;
                    newCenter[key] = true as never;
                    setRestaurant(newCenter);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false)) //autoFillValue will cause a spinner to be displayed
        );
    };

    return (
        <DeliveryView
            {...props}
            center={restaurant}
            textFilter={textFilter}
            statusCount={statusCount}
            form={deliveryContext.form}
            turns={deliveryContext.list}
            setTextFilter={setTextFilter}
            onChange={deliveryContext.onChange}
            error={error || deliveryContext.error}
            manualAutoDispatch={manualAutoDispatch}
            loading={loading || deliveryContext.loading}
        />
    );
};

export default DeliveryController;
