import { IPaymentMethodBlock } from "./../Interfaces/IPaymentBlock.interface";
import { IDynamicObject, setNumber } from "xa-generics";

export class PaymentSummaryModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;

        //This assures that each payment method will have all three (failed/delivered/in_progress) properties,
        //and that all of them will have three NUMBER type properites, even if something returns null or string
        for (let method in data.payment_methods) {
            this.payment_methods[method] = {
                failed: {
                    delivery_price: setNumber(data.payment_methods[method].failed.delivery_price),
                    quantity: setNumber(data.payment_methods[method].failed.quantity),
                    sum: setNumber(data.payment_methods[method].failed.sum)
                },
                delivered: {
                    delivery_price: setNumber(
                        data.payment_methods[method].delivered.delivery_price
                    ),
                    quantity: setNumber(data.payment_methods[method].delivered.quantity),
                    sum: setNumber(data.payment_methods[method].delivered.sum)
                },
                in_progress: {
                    delivery_price: setNumber(
                        data.payment_methods[method].in_progress.delivery_price
                    ),
                    quantity: setNumber(data.payment_methods[method].in_progress.quantity),
                    sum: setNumber(data.payment_methods[method].in_progress.sum)
                }
            };
        }

        for (let method in data.payment_types) {
            this.payment_types[method] = {
                failed: {
                    delivery_price: setNumber(data.payment_types[method].failed.delivery_price),
                    quantity: setNumber(data.payment_types[method].failed.quantity),
                    sum: setNumber(data.payment_types[method].failed.sum)
                },
                delivered: {
                    delivery_price: setNumber(data.payment_types[method].delivered.delivery_price),
                    quantity: setNumber(data.payment_types[method].delivered.quantity),
                    sum: setNumber(data.payment_types[method].delivered.sum)
                },
                in_progress: {
                    delivery_price: setNumber(
                        data.payment_types[method].in_progress.delivery_price
                    ),
                    quantity: setNumber(data.payment_types[method].in_progress.quantity),
                    sum: setNumber(data.payment_types[method].in_progress.sum)
                }
            };
        }
    }

    payment_methods: IDynamicObject<IPaymentMethodBlock> = {};
    payment_types: IDynamicObject<IPaymentMethodBlock> = {};
}
