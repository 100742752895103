import { axios, IDynamicObject } from "xa-generics";
import { PinModel } from "../../Models/Pin.model";

export abstract class PinDAO {
    private static readonly validatePin: string = "/api/admins/validate-pin";

    public static async postRestaurantPin(data: IDynamicObject<any>): Promise<PinModel> {
        const request = await axios.getInstance().post<IDynamicObject>(`${this.validatePin}`, data);
        return new PinModel(request.data);
    }
}
