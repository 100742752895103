import { ModelConstructor, IDynamicObject } from "xa-generics";

export class CourierModel {
    constructor(data?: IDynamicObject<any>) {
        if (!data) return;
        ModelConstructor(data, this);

        this.initName();
    }

    // Model properties
    active: boolean = false;
    created_at: string = "";
    email: string = "";
    first_name: string = "";
    id: string = "";
    is_available: boolean = false;
    last_name: string = "";
    restaurant_id: string = "";
    updated_at: string = "";

    // Own functions and properties
    name: string = "";

    protected initName(): void {
        this.name = `${this.first_name} ${this.last_name}`.trim();
    }
}
