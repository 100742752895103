import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { useState, useContext, useRef } from "react";
import { PrintBlock, PrintBlockArray } from "../../../Utils/PrintBlock.util";
import { IDragEventStart, IDragData } from "../Interfaces/IDragEvent.interface";
import { dragEventReorder } from "../Util/DragEvent.util";
import { clone, cloneDeep } from "lodash";
import { IRoundDaoMethods } from "../Interfaces/IRoundDaoMethods.type";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { ModalContext } from "xa-modal";
import { useDelivery } from "../../../Contexts/DeliveryRounds.context";
import { useStacker } from "../../../Contexts/RabbitStacker.context";
import { OrderModel } from "../../../Models/Order.model";
import { IMoveTypes } from "../Interfaces/IMoves.type";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { RoundDAO } from "../../../Api/DAO/CourierTurn.dao";
import { OrderDAO } from "../../../Api/DAO/Order.dao";
import DeliveryUnitView from "../View/DeliveryUnit.view";
import DeleteModal from "../../UI/Modals/DeleteModal.view";

export interface IDeliveryUnitProps {
    textFilter: RegExp;
    counterFilter: number;
    turn: CourierTurnModel;
}

/**
 * ## Some important note!
 *
 * The loading state is intentionally not set to false in the various
 * api calls. This is because there is a useEffect at the bottom which
 * is listening to the deliveryContext.list changes. Since every method
 * triggers a change in the delivery list, the loading and error will be
 * always reset. This way, the loading will be true during the stacking timeout
 * as well, which is exactly what we want.
 */
const DeliveryUnit: React.FC<IDeliveryUnitProps> = (props) => {
    const turn = props.turn;
    const { restaurant, host } = useAccess();
    const deliveryContext = useDelivery();
    const modal = useContext(ModalContext);
    const stack = useStacker();

    let lastMovedRef = useRef<IDragEventStart<OrderModel> | null>(null);

    const [dragOrigin, setDragOrigin] = useState<IDragEventStart<OrderModel> | null>(null);
    const [dropTarget, setDropTarget] = useState<IDragData | null>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    //prop public
    const onDragStart = (order: OrderModel, index: number): void => {
        const data = {
            element: order,
            elementID: order.id,
            roundID: props.turn.id,
            index: index,
            rawID: `${props.turn.id}||${order.id}`
        };
        setDragOrigin(data);
        lastMovedRef.current = data;
    };

    //prop public
    const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        const currentTargetID: string = event.currentTarget.id;
        if (currentTargetID === dropTarget?.rawID) return;

        const ids: string[] = currentTargetID.split("||");

        //ids is a concated string with the following params
        //"delivery.round.id||delivery.orders[x].id

        if (dragOrigin && ids[0] === dragOrigin.roundID) {
            setDropTarget({
                elementID: ids[1],
                roundID: ids[0],
                rawID: currentTargetID
            });
        }
    };

    //private
    const dragEventCleanup = (): void => {
        setDragOrigin(null);
        setDropTarget(null);
    };

    //prop public
    const onDragEnd = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        if (!dragOrigin || !dropTarget) {
            dragEventCleanup();
            lastMovedRef.current = null;
            return;
        }

        const newDeliveryOrders = dragEventReorder(turn.orders, dropTarget, dragOrigin, "id");
        if (!newDeliveryOrders) {
            dragEventCleanup();
            lastMovedRef.current = null;
            return;
        } else {
            return saveNewOrderSequence(newDeliveryOrders);
        }
    };

    //private
    const rebuildOrderSequence = (orderList: OrderModel[]): number[] => {
        let orders: number[] = [];
        for (let order of orderList) {
            orders.push(parseInt(order.id));
        }
        return orders;
    };

    const printBigBlock = (orderId: string): void => {
        const request = host?.is_cloud
            ? OrderDAO.getBlockPDF(restaurant, orderId)
            : OrderDAO.printBigBlock(restaurant, orderId);
        setLoading(
            request
                .then((blob) => PrintBlock(blob, orderId))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    //private
    const saveNewOrderSequence = (orderList: OrderModel[]): void => {
        const data = rebuildOrderSequence(orderList);

        setLoading(
            RoundDAO.reorganiseSequence(turn.id, data)
                .then(() => {
                    //clone delivery and replace its 'orders' list
                    const newDelivery = clone(turn);
                    newDelivery.orders = orderList;

                    //clone delivery list and replace the old 'delivery' element
                    //with the new one if it could be found, and then update the whole list
                    const newDeliveryList = clone(deliveryContext.list);
                    const index: number = newDeliveryList.findIndex((item) => item.id === turn.id);
                    if (index > -1) {
                        newDeliveryList[index] = newDelivery;
                        deliveryContext.setList(newDeliveryList);
                    }
                    if (error) setError(null);
                    dragEventCleanup();
                    stack.reloadRounds();
                })
                .catch((error: IError) => {
                    setError(error);
                })
        );
    };

    //prop public
    const printTurnOrders = (): void => {
        const orderIds = props.turn.orders.map((order) => order.id);
        const request = host?.is_cloud
            ? RoundDAO.getTurnPDFs(restaurant, orderIds)
            : RoundDAO.printTurnOrders(restaurant, orderIds);
        setLoading(
            request
                .then((blobs) => PrintBlockArray(blobs))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const printTurnTaxes = (): void => {
        const orderIds = props.turn.orders.map((order) => order.id);
        setLoading(
            RoundDAO.printTaxes(restaurant, orderIds)
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    //prop public
    const backToAddresses = (order: OrderModel): void => {
        setLoading(
            OrderDAO.revoke(order.id)
                .then(() => {
                    stack.reloadRounds();
                    stack.reloadOrders();
                })
                .catch((error: IError) => {
                    setError(error);
                })
        );
    };

    //prop public
    const cancelOrder = (order: OrderModel): void => {
        modal.setContent(
            <DeleteModal
                noFunction={() => {
                    modal.setContent(null);
                    setLoading(false);
                }}
                yesFunction={() => {
                    modal.setContent(null);
                    setLoading(
                        OrderDAO.cancelOrder(order.id)
                            .then(() => {
                                stack.reloadRounds();
                            })
                            .catch((error: IError) => {
                                setError(error);
                                setLoading(false);
                            })
                    );
                }}
            />
        );
    };

    //prop public
    const changeAutoFill = (): void => {
        setLoading(
            RoundDAO.changeAutoFill(turn.id, !turn.auto_fill)
                .then(() => {
                    const newDeliveryList = cloneDeep(deliveryContext.list);
                    const index: number = newDeliveryList.findIndex((item) => item.id === turn.id);
                    if (index > -1) {
                        newDeliveryList[index].auto_fill = !turn.auto_fill;
                        deliveryContext.setList(newDeliveryList);
                    }
                    setError(null);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    //prop public
    const deliveryDaoCalls = (daoMethod: IRoundDaoMethods): void => {
        setLoading(
            RoundDAO[daoMethod](turn.id)
                .then(() => stack.reloadRounds())
                .catch((error: IError) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    //prop public
    const setOrderToBeLate = (order: OrderModel): void => {
        let newDeliveries: CourierTurnModel[] = cloneDeep(deliveryContext.list);

        for (let delivery of newDeliveries) {
            for (let deliveryOrder of delivery.orders) {
                if (deliveryOrder.id === order.id) {
                    deliveryOrder.isLate = true;
                    break;
                }
            }
        }

        deliveryContext.setList(newDeliveries);
    };

    //prop public
    const moveOrder = (order: OrderModel, direction: IMoveTypes): void => {
        const roundID: string = props.turn.id;
        const originIndex: number = props.turn.orders.findIndex(
            (o: OrderModel) => o.id === order.id
        );
        if (originIndex < 0) return;

        let target: IDragData;
        const origin: IDragEventStart<OrderModel> = {
            element: order,
            elementID: order.id,
            index: originIndex,
            rawID: `${roundID}||${order.id}`,
            roundID: roundID
        };
        lastMovedRef.current = origin;

        if (direction === "UP") {
            const targetID: string = `${roundID}||${props.turn.orders[originIndex - 1].id}`;
            target = {
                rawID: targetID,
                elementID: props.turn.orders[originIndex - 1].id,
                roundID: roundID
            };
        } else {
            const targetID: string = `${roundID}||${props.turn.orders[originIndex + 1].id}`;
            target = {
                rawID: targetID,
                elementID: props.turn.orders[originIndex + 1].id,
                roundID: roundID
            };
        }

        const newDeliveryOrders = dragEventReorder(props.turn.orders, target, origin, "id");
        if (!newDeliveryOrders) {
            lastMovedRef.current = null;
        } else {
            return saveNewOrderSequence(newDeliveryOrders);
        }
    };

    //private
    const resetLoadingAndError = (): void => {
        setLoading(false);
        setError(null);
    };

    useMountWithTriggers(resetLoadingAndError, [deliveryContext.list]);

    return (
        <DeliveryUnitView
            {...props}
            error={error}
            loading={loading}
            setError={setError}
            onDragEnd={onDragEnd}
            moveOrder={moveOrder}
            onDragOver={onDragOver}
            dragOrigin={dragOrigin}
            dropTarget={dropTarget}
            lastMoved={lastMovedRef}
            onDragStart={onDragStart}
            cancelOrder={cancelOrder}
            printBigBlock={printBigBlock}
            printTurnTaxes={printTurnTaxes}
            changeAutoFill={changeAutoFill}
            backToAddresses={backToAddresses}
            printTurnOrders={printTurnOrders}
            setOrderToBeLate={setOrderToBeLate}
            deliveryDaoCalls={deliveryDaoCalls}
        />
    );
};

export default DeliveryUnit;
