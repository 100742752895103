import moment from "moment";
import { OrderModel } from "./../Models/Order.model";
/** This is for the orders which are running late and aren't delivered yet
 * even after reaching the maximum delay, which can be set for each restaurant
 */
export const checkIsLate = (order: OrderModel, maxDelay: number): boolean => {
    const now: number = Date.now();
    const elapsed: number = moment(order.created_at).toDate().getTime();
    return Math.round((now - elapsed) / 1000) > maxDelay;
};
