import { IDeliveryViewProps } from "./Delivery.view";
import { useTranslation } from "react-i18next";
import { Radio } from "xa-inputs";

export interface IDeliveryFilterViewProps extends IDeliveryViewProps {}

const DeliveryFilterView: React.FC<IDeliveryFilterViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="delivery-view__filter-view">
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.all}
                onChange={props.onChange}
                className="radio__all"
            >
                <>
                    {t("delivery_filter_r1")}{" "}
                    <span className="radio-count">| {props.statusCount.allCount}</span>
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.on_road}
                onChange={props.onChange}
                className="radio__on-delivery"
            >
                <>
                    {t("delivery_filter_r2")}{" "}
                    <span className="radio-count">| {props.statusCount.onRoadCount}</span>
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.go_back}
                onChange={props.onChange}
                className="radio__delivered"
            >
                <>
                    {t("delivery_filter_r3")}{" "}
                    <span className="radio-count">| {props.statusCount.goBackCount}</span>
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.created}
                onChange={props.onChange}
                className="radio__not-occupied"
            >
                <>
                    {t("delivery_filter_r4")}{" "}
                    <span className="radio-count">| {props.statusCount.freeCount}</span>
                </>
            </Radio>
        </div>
    );
};

export default DeliveryFilterView;
