import Navbar from "../../Navigation/View/Navbar.view";
import DrawerController from "../../Drawer/Controller/Drawer.controller";
import MapOverlayAssignButton from "../../Orders/View/MapOverlayAssignButton.view";
import { IPageContainerControllerProps } from "../Controller/PageContainer.controller";
import { Map } from "../../MapContainer/Controller/Map.controller";
import "./Less/PageContainer.css";

export interface IPageContainerViewProps extends IPageContainerControllerProps {}

const PageContainerView: React.FC<IPageContainerViewProps> = (props): React.ReactElement => {
    return (
        <div className="page-container-view">
            <Navbar />
            <DrawerController />
            <Map />
            <MapOverlayAssignButton />
        </div>
    );
};

export default PageContainerView;
