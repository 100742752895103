import { IDynamicObject, ISelectOption } from "xa-generics";

export class PayMethodsModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;

        let options: ISelectOption[] = [];
        for (let key in data) {
            const option: ISelectOption = { id: key, name: data[key] };
            options.push(option);
        }

        this.payMethodsAsArr = options;
        this.payMethodsAsObj = data;
    }

    payMethodsAsObj: IDynamicObject = {};
    payMethodsAsArr: ISelectOption[] = [];
}
