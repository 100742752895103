import { ICourierElementFunctions } from "./Couriers.view";
import { useTranslation } from "react-i18next";
import { CourierModel } from "../../../Models/Courier.model";
import { Switch } from "xa-inputs";

export interface ICourierElementViewProps extends ICourierElementFunctions {
    courier: CourierModel;
}

const CourierElementView: React.FC<ICourierElementViewProps> = (props): React.ReactElement => {
    const courier: CourierModel = props.courier;
    const { t } = useTranslation();
    return (
        <div className="courier">
            <div className="name">{courier.name}</div>
            <div className="email">{courier.email}</div>
            <div className="input">
                {courier.is_available ? t("courier_available") : t("courier_not_available")}
                <Switch
                    onChange={(e) => props.changeAvailability(courier.id, e.value)}
                    disabled={props.loading ? true : false}
                    id={`${courier.id}-courier-handler`}
                    value={courier.is_available}
                />
            </div>
        </div>
    );
};

export default CourierElementView;
