import { OrderModel } from "../Models/Order.model";
import { CourierModel } from "../Models/Courier.model";
import { letter_swaps } from "../Static/Swaps.static";

export const swapString = (text: string): string => {
    text = text.replace(/^\s+|\s+$/g, ""); // trim
    text = text.toLowerCase();

    Object.keys(letter_swaps).forEach((swap: string) => {
        letter_swaps[swap].forEach((s: string) => {
            text = text.replace(RegExp(s, "g"), swap);
        });
    });
    return text.replace(/[^a-z0-9 -]/g, ""); // remove invalid chars
};

export const textFilter = (regex: RegExp, order: OrderModel): boolean => {
    if (!regex) return true;

    if (regex.test(swapString(order.name))) return true;
    if (regex.test(order.tel)) return true;
    if (regex.test(swapString(order.address))) return true;

    return false;
};

export const courierNameFilter = (regex: RegExp, courier: CourierModel): boolean => {
    if (!regex) return true;

    if (regex.test(swapString(courier.name))) return true;
    return false;
};
