import { map } from "lodash";
import { ProductModel } from "../Model/Product.model";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { formatCurrency } from "../../../Utils/Currency.util";
import { FromatPhoneNumber } from "../../../Utils/FormatPhoneNumber.util";
import { DetailedCourierModel } from "../../../Models/DetailedCourier.model";
import { DetailedCourierTurnModel } from "../../../Models/DetailedCourierTurn.model";
import * as Generics from "xa-generics";
import "./Less/PrintRound.css";

interface IPrintRoundViewProps {
    conf_print_category_column_count: number;
    turn: DetailedCourierTurnModel;
    courier: DetailedCourierModel;
    conf_print_product: boolean;
    products: ProductModel[];
    loading: Generics.ILoading;
    error: Generics.IError;
}

const PrintRoundView: React.FC<IPrintRoundViewProps> = (props): React.ReactElement => {
    const { parsePayment, loading, translations } = useDictionary();
    const print = (): void => {
        if (!props.loading && !loading && !props.error && props.turn && props.courier) {
            window.print();
        }
    };

    Generics.useMountWithTriggers(print, [
        loading,
        props.turn,
        props.error,
        props.courier,
        props.loading
    ]);
    const nodes: JSX.Element[] = [];

    let sum: number = 0;
    let sumOfTotal: number = 0;

    let productNameKeys: Generics.IDynamicObject<Generics.ISelectOption> = {};
    const productNameNodes: JSX.Element[] = map(
        props.products
            .sort((a, b) => a.ranking - b.ranking)
            .slice(0, props.conf_print_category_column_count),
        (product, index) => {
            productNameKeys[product.product_name] = {
                id: `${index}`,
                name: product.product_name
            };
            return (
                <div
                    className={`print-view__data-block--col col-10`}
                    key={`${index}-col-product-key`}
                >
                    {product.product_name.substring(0, 3)}
                </div>
            );
        }
    );

    for (let order of props.turn.orders) {
        for (let item of order.order_items) {
            productNameKeys[item.product.name] = {
                id: `${item.id}`,
                name: item.product.name
            };
            productNameNodes.push();
        }
    }

    const modes: { [key: string]: number } = {};

    for (const order of props.turn.orders) {
        if (order.total_price < 0) order.total_price = 0;
        sumOfTotal += order.total_price;
        if (!order.is_paid) {
            sum += order.total_price;
            if (!(order.payment_method in modes)) {
                Object.assign(modes, { [order.payment_method]: 0 });
            }
            modes[order.payment_method] += order.total_price;
        }

        let productQuantities: Generics.IDynamicObject<number> = {};

        for (let item of order.order_items) {
            for (let product of props.products) {
                if (
                    product.product_name === item.product_name &&
                    productNameKeys[item.product_name]
                ) {
                    if (!productQuantities[productNameKeys[item.product_name].id]) {
                        productQuantities[productNameKeys[item.product_name].id] = 0;
                    }
                    productQuantities[productNameKeys[item.product_name].id] += item.quantity;
                }
            }
        }

        let categoryColumnNodes: JSX.Element[] = [];
        const nodeLength: number = productNameNodes.length;

        for (let ci = 0; ci < nodeLength; ci++) {
            if (props.conf_print_product) {
                categoryColumnNodes.push(
                    <div className="print-view__data-block--col col-11" key={`${ci}-prod-q-name`}>
                        {productQuantities[ci] || "-"}
                    </div>
                );
            }
        }

        nodes.push(
            <section className="print-view__data-block" key={`${order.id}-order-element`}>
                <div className="print-view__data-block--col col-1">{order.counter || "-"}</div>
                <div className="print-view__data-block--col col-2">
                    {order.first_name} {order.last_name}
                </div>
                <div className="print-view__data-block--col col-3">
                    {order.city} {order.street} {order.house_number} {order.floor} {order.door}{" "}
                    {order.bell} {order.address_notes && `(${order.address_notes})`}
                </div>
                <div className="print-view__data-block--col col-4">
                    {FromatPhoneNumber(order.tel)}
                </div>
                <div className="print-view__data-block--col col-5">
                    {order.payable_transactions.map((trans) => (
                        <div key={`${trans.id}-transaction`}>
                            {translations.getById(trans.payment_method_id)} |{" "}
                            {Generics.ToMoneyInt(trans.value)} Ft
                        </div>
                    ))}
                </div>
                <div className="print-view__data-block--col col-6">{order.id}</div>
                {props.turn.has_balance && (
                    <div className="print-view__data-block--col col-8">
                        {formatCurrency(order.balance || 0)}
                    </div>
                )}
                {props.conf_print_product && categoryColumnNodes}
                <div className="print-view__data-block--col col-7">{order.comment || "-"}</div>
            </section>
        );
    }

    return (
        <div className="print-view" id="printarea">
            <h1 className="print-view__title">
                <div>
                    <strong>Futár: </strong>
                    {props.courier.name}
                </div>
            </h1>
            <section className="print-view__data-block data-block-head">
                <div className="print-view__data-block--col col-1">#</div>
                <div className="print-view__data-block--col col-2">Név</div>
                <div className="print-view__data-block--col col-3">Cím</div>
                <div className="print-view__data-block--col col-4">Telefonszám</div>
                <div className="print-view__data-block--col col-5">Fiz. mód és összeg</div>
                <div className="print-view__data-block--col col-6">ID</div>
                {props.turn.has_balance && (
                    <div className="print-view__data-block--col col-8">Egyenleg</div>
                )}
                {props.conf_print_product && productNameNodes}
                <div className="print-view__data-block--col col-7">Megjegyzés</div>
            </section>
            {nodes}
            <section className="print-view__data-block payment-section">
                {map(Object.keys(modes), (payment_method: string, index: string) => (
                    <div
                        className="print-view__data-block--col sum-col"
                        key={`${index}-payment-row`}
                    >
                        {parsePayment(payment_method)}: {modes[payment_method]} Ft
                    </div>
                ))}
            </section>
            <section className="print-view__data-block print-view__data-block--footer">
                <div className="print-view__data-block--col sum-column">
                    Összesen fizetendő:{" "}
                    <strong className="price-sum">{Generics.ToMoneyInt(sum)}</strong> Ft és{" "}
                    <strong className="price-sum">{props.turn.orders.length}</strong> darab rendelés
                </div>
                <div className="print-view__data-block--col sum-column">
                    Összes rendelés értéke:{" "}
                    <strong className="price-sum">{Generics.ToMoneyInt(sumOfTotal)}</strong> Ft
                </div>
            </section>
        </div>
    );
};

export default PrintRoundView;
