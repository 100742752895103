import { ErrorHandler } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { ILoading, IError } from "xa-generics";
import { IRestaurantConfigForm } from "../Interfaces/IRestaurantConfigForm.interface";
import { IConfigControllerProps } from "../Controller/Config.controller";
import { IOnChange, Button, Select, TextInput, Checkbox } from "xa-inputs";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/Config.css";

export interface IConfigViewProps extends IConfigControllerProps {
    onSubmit: (e: React.FormEvent) => void;
    resetError: () => void;
    onChange: IOnChange;
    form: IRestaurantConfigForm;
    loading: ILoading;
    error: IError;
}

const ConfigView: React.FC<IConfigViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="config-view">
            <form onSubmit={props.onSubmit} className="config-form">
                <Select
                    {...props.form.conf_sort_product_by}
                    onChange={props.onChange}
                    labelText={t("conf_sort_by")}
                    description={t("conf_sort_by_desc")}
                />
                <Select
                    {...props.form.conf_sort_product_method}
                    onChange={props.onChange}
                    labelText={t("sort_method")}
                />
                <TextInput
                    {...props.form.conf_km_price}
                    onChange={props.onChange}
                    labelText={`${t("summary_km_price")} (${t("price_unit")})`}
                />
                <Checkbox
                    {...props.form.conf_include_finish_distance}
                    onChange={props.onChange}
                    labelText={t("summary_km_include_back")}
                />
                <Checkbox
                    {...props.form.conf_auto_optimize}
                    onChange={props.onChange}
                    labelText={t("conf_auto_optimize")}
                />

                <Button hasContainer type="submit">
                    {props.loading ? <Spinner isTiny isNegatedColor /> : t("save")}
                </Button>
            </form>
            <ErrorHandler error={props.error} resetError={props.resetError} />
        </div>
    );
};

export default ConfigView;
