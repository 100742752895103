import { IDictionaryContext, IDictionaryModels } from "../Interfaces/IDictionaryContext.interface";
import { Context, createContext, useContext, useState } from "react";
import { IError, ILoading, useDidMount } from "xa-generics";
import { OrderStatusModel } from "../Models/OrderStatus.model";
import { PayMethodsModel } from "../Models/PayMethods.model";
import { useTranslation } from "react-i18next";
import DictionaryDAO from "../DAO/Dictionary.dao";
import { PaymentTranslationModel } from "../../../Models/PaymentTranslation.model";

/**
 * ## DictionaryContext
 */
const DictionaryContext: Context<IDictionaryContext> = createContext<IDictionaryContext>({
    parsePayment: null as any,
    parseStatus: null as any,
    payment: null as any,
    status: null as any,
    loadDictionary: null as any,
    translations: null as any,
    loading: true,
    error: null
});

DictionaryContext.displayName = "DictionaryContext";

interface IDictCtxWrapperProps {}

/**
 * ## Dictionary context wrapper component
 *
 */
export const DictCtxWrapper: React.FC<IDictCtxWrapperProps> = (props) => {
    const { t } = useTranslation();

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [dictionary, setDictionary] = useState<IDictionaryModels>({
        payment: new PayMethodsModel(),
        status: new OrderStatusModel(),
        translations: new PaymentTranslationModel()
    });

    const loadDictionary = (): void => {
        setLoading(
            DictionaryDAO.loadDictionary()
                .then((data: IDictionaryModels) => setDictionary(data))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const parsePayment = (value: string): string => {
        if (loading) return t("loading");
        return dictionary.payment.payMethodsAsObj[value] || t("unknown_dict_payment");
    };

    const parseStatus = (value: string): string => {
        if (loading) return t("loading");
        return dictionary.status.statusAsObj[value] || t("unknown_dict_status");
    };

    useDidMount(loadDictionary);

    return (
        <DictionaryContext.Provider
            value={{
                translations: dictionary.translations,
                payment: dictionary.payment,
                status: dictionary.status,
                loadDictionary,
                parsePayment,
                parseStatus,
                loading,
                error
            }}
        >
            {props.children}
        </DictionaryContext.Provider>
    );
};
export const useDictionary = () => useContext(DictionaryContext);
