import { Context, createContext, useState, useContext } from "react";
import { ILoading, IError, useMountWithTriggers } from "xa-generics";
import { IOrderFilterForm } from "../Components/Orders/Interface/IOrderFilterForm.interface";
import { OrderFilterForm } from "../Components/Orders/Static/OrderFilter.form";
import { IOrderContext } from "../Interfaces/IOrderContext.interface";
import { ChangeHandler } from "../Utils/ChangeHandler.util";
import { useDateFilter } from "./DateFilter.context";
import { IInputChange } from "xa-inputs";
import { OrderModel } from "../Models/Order.model";
import { cloneDeep } from "lodash";
import { useAccess } from "../Modules/Access/Context/Access.context";
import { OrderDAO } from "../Api/DAO/Order.dao";

/**
 * ## OrderContext
 */
const OrderContext: Context<IOrderContext> = createContext<IOrderContext>({
    error: null,
    loading: null,
    loadOrders: null as any, //These are just default values that will never
    resetError: null as any, //get to be rendered since there is a provider
    updateList: null as any, //we can init them with anything here, it's just a placeholder anyway.
    list: null as any,
    form: null as any,
    onChange: null as any
});

OrderContext.displayName = "OrderContext";

interface IOrderContextWrapperProps {}

/**
 * ## Order context wrapper component
 *
 */
export const OrderContextWrapper: React.FC<IOrderContextWrapperProps> = (props) => {
    const {
        form: { date }
    } = useDateFilter();
    const [form, setForm] = useState<IOrderFilterForm>(cloneDeep(OrderFilterForm));
    const [loading, setLoading] = useState<ILoading>(true);
    const [orders, setOrders] = useState<OrderModel[]>([]);
    const [error, setError] = useState<IError>(null);

    const { restaurant } = useAccess();

    const loadOrders = (): void => {
        setLoading(
            OrderDAO.getOrders(restaurant.delay_time, date.value as Date)
                .then((data) => setOrders(data))
                .catch((error: IError) => {
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    const onChange = (args: IInputChange): void => {
        setForm(ChangeHandler(form, args.fieldName, args.value));
    };

    useMountWithTriggers(loadOrders, [date]);

    return (
        <OrderContext.Provider
            value={{
                updateList: (list: OrderModel[]) => setOrders(list),
                resetError: () => setError(null),
                list: orders,
                loadOrders,
                onChange,
                loading,
                error,
                form
            }}
        >
            {props.children}
        </OrderContext.Provider>
    );
};
export const useOrders = () => useContext(OrderContext);
