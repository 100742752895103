import { useTranslation } from "react-i18next";
import CarAtRestaurant from "../../../Assets/CarAtRestaurant__sign.png";
import FoodDelivered from "../../../Assets/FoodDelivered__sign.png";
import FoodKitchen from "../../../Assets/FoodKitchen__sign.png";
import FoodOnRoad from "../../../Assets/FoodOnRoad__sign.png";
import Restaurant from "../../../Assets/Restaurant__sign.png";
import CarOnRoad from "../../../Assets/CarOnRoad__sign.png";
import FoodDone from "../../../Assets/FoodDone__sign.png";
import CarBack from "../../../Assets/CarBack__sign.png";
import Late from "../../../Assets/Late__sign.png";
import Name from "../../../Assets/Name__sign.png";
import "./Less/Signs.css";

const Signs = () => {
    const { t } = useTranslation();

    return (
        <div className="signs">
            <div className="signs__header">{t("signs_title")}</div>

            <div className="signs__list">
                <div className="signs__element">
                    <div className="signs__element--header">{t("signs_sect_1_sm_t")}</div>
                    <div className="signs__row">
                        <img
                            src={CarAtRestaurant}
                            alt="Unable to display"
                            className="signs__row--img"
                        />
                        <p className="signs__row--text">{t("signs_sect_1_st_1")}</p>
                    </div>
                    <div className="signs__row">
                        <img src={CarOnRoad} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_1_st_2")}</p>
                    </div>
                    <div className="signs__row">
                        <img src={CarBack} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_1_st_3")}</p>
                    </div>
                </div>

                <div className="signs__element">
                    <div className="signs__element--header">{t("signs_sect_2_sm_t")}</div>
                    <div className="signs__row">
                        <img
                            src={FoodKitchen}
                            alt="Unable to display"
                            className="signs__row--img"
                        />
                        <p className="signs__row--text">{t("signs_sect_2_st_1")}</p>
                    </div>
                    <div className="signs__row">
                        <img src={FoodDone} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_2_st_2")}</p>
                    </div>
                    <div className="signs__row">
                        <img src={FoodOnRoad} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_2_st_3")}</p>
                    </div>
                    <div className="signs__row">
                        <img
                            src={FoodDelivered}
                            alt="Unable to display"
                            className="signs__row--img"
                        />
                        <p className="signs__row--text">{t("signs_sect_2_st_4")}</p>
                    </div>
                </div>

                <div className="signs__element">
                    <div className="signs__element--header">{t("signs_sect_3_sm_t")}</div>
                    <div className="signs__row">
                        <img src={Name} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_3_st_1")}</p>
                    </div>
                    <div className="signs__row">
                        <img src={Late} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_3_st_2")}</p>
                    </div>
                </div>

                <div className="signs__element">
                    <div className="signs__element--header">{t("signs_sect_4_sm_t")}</div>
                    <div className="signs__row">
                        <img src={Restaurant} alt="Unable to display" className="signs__row--img" />
                        <p className="signs__row--text">{t("signs_sect_4_st_1")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signs;
