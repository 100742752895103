import { ModelConstructor, IDynamicObject } from "xa-generics";
import { CourierModel } from "./Courier.model";

export class DetailedCourierModel extends CourierModel {
    constructor(rawData?: IDynamicObject<any>) {
        super();
        if (!rawData) return;
        const data = rawData as DetailedCourierMData;
        ModelConstructor(data, this);

        this.initName();
    }

    conf_auto_dispatching: boolean = false;
    conf_auto_finish_round: boolean = false;
    conf_auto_finish_round_meter: number = 0;
    conf_auto_status_to_done: boolean = false;
    conf_auto_status_to_kitchen: boolean = false;
    conf_courier_here_meter: number = 0;
    conf_courier_nearby_meter: number = 0;
    conf_expected_at_slide_sec: number = 0;
    conf_include_finish_distance: boolean = false;
    conf_km_price: number = 0;
    conf_manually_finish_round: boolean = false;
    conf_manually_start_round: boolean = false;
    conf_print_product: boolean = false;
    conf_save_location_interval: number = 0;
    conf_save_location_meter: number = 0;
    conf_send_sms: boolean = false;
    conf_stopover: boolean = false;
    conf_to_done_interval: number = 0;
    conf_to_kitchen_interval: number = 0;
    courier_turn_id: string = "";
    delay_time: number = 0;
}

//If you add public methods, you may want to replace the empty string with them!
type DetailedCourierMData = Partial<InstanceType<typeof DetailedCourierModel>>;
