import { ModelConstructor, IDynamicObject } from "xa-generics";
import { getAddressLayer } from "./../Utils/GetAddress.util";
import { DATETIMEFORMAT } from "../Static/DateFormats.static";
import { checkIsLate } from "../Utils/CheckIsLate.util";
import { TFunction } from "i18next";
import moment from "moment";

export abstract class BaseOrderModel {
    accepted_at: string = "";
    archived_at: string = "";
    auto_done: boolean = false;
    auto_kitchen: boolean = false;
    bell: string = "";
    change_status_at: string = "";
    city: string = "";
    comment: string = "";
    coord_lat: number = 0;
    coord_lng: number = 0;
    counter: number = 0;
    courier_turn_id: string = "";
    created_at: string = "";
    delay_at: string = "";
    delivery_price: number = 0;
    descriptor: string = "";
    distance: number = 0;
    door: string = "";
    email: string = "";
    estimated_travel_time: number = 0;
    expected_at: string = "";
    first_name: string = "";
    floor: string = "";
    house_number: string = "";
    id: string = "";
    is_email_sent: boolean = false;
    is_paid: boolean = false;
    is_preorder: boolean = false;
    is_revoked: boolean = false;
    is_round_started: boolean = false;
    last_name: string = "";
    location: string = "";
    order_scheme_id: string = "";
    payment_method: string = "";
    products_price: number = 0;
    progress: string = "";
    real_travel_time: number = 0;
    restaurant_id: string = "";
    sequence: number = 0;
    shadow_customer_id: string = "";
    show_at: string = "";
    stand_by: boolean = false;
    stand_by_at: string = "";
    status: string = "";
    street: string = "";
    synced_at: string = "";
    synched_status_1: string = "";
    synched_status_2: string = "";
    tel: string = "";
    total_price: number = 0;
    updated_at: string = "";
    zipcode: string = "";
    address_notes: string = "";

    //Custom props
    showAtAsString: string = "";
    showAtAsDate: Date = new Date();
    name: string = "";

    protected init(): void {
        this.name = `${this.first_name.trimLeft()} ${this.last_name.trimRight()}`.trim();
        const showMoment = moment(this.show_at);
        this.showAtAsString = showMoment.format(DATETIMEFORMAT);
        this.showAtAsDate = showMoment.toDate();
    }

    public getFormattedAddress(t: TFunction): string {
        return (
            getAddressLayer(this.city, ",") +
            " " +
            getAddressLayer(this.street, "") +
            " " +
            getAddressLayer(this.house_number, `${t("order_short_house")}`) +
            " " +
            getAddressLayer(this.floor, `${t("order_short_floor")}`) +
            " " +
            getAddressLayer(this.door, `${t("order_short_door")}`) +
            " " +
            getAddressLayer(this.bell, t("order_short_bell"))
        ).trim();
    }
}

export class OrderModel extends BaseOrderModel {
    constructor(order?: IDynamicObject<any>, maxDelay: number = 45, index: number = 0) {
        super();
        if (!order) return;
        ModelConstructor(order, this);

        const raw_data: string | null = localStorage.getItem("check_list");
        if (raw_data) {
            const data = JSON.parse(raw_data);
            if (data[this.id]) this.isCheckedToAssign = true;
        }
        this.index = index;
        this.isLate = checkIsLate(this, maxDelay);
        this.address = this.getAddress();
        this.init();

        if (this.payment_method === "simplepay") {
            this.total_price = 0;
        }
    }

    //Own properties
    isLate: boolean = false; //need for the filter result
    isCheckedToAssign: boolean = false; //assign to courier checkbox
    address: string = "";
    index: number = 0;

    public getName(): string {
        return `${this.first_name} ${this.last_name}`;
    }

    public getAddress(): string {
        return `${this.city} ${this.street} ${this.house_number} ${this.floor} ${this.door} ${this.bell}`;
    }
}
