import { ModelConstructor, IDynamicObject } from "xa-generics";
import { IPlatformType } from "../Interfaces/IPlatformTyle.type";
import { IOrderStatus } from "../Interfaces/IOrderStatus.type";

export class PayableTransactionModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as PayableTransactionMData;
        ModelConstructor(data, this);
    }

    archived_at: string = "";
    created_at: string = "";
    id: string = "";
    paid_at: string = "";
    payment_info: string = "";
    payment_method_id: string = "";
    pickup: boolean = false;
    platform: IPlatformType = "";
    restaurant_id: string = "";
    shadow_customer_id: string = "";
    status: IOrderStatus = "succeeded";
    updated_at: string = "";
    value: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type PayableTransactionMData = Partial<InstanceType<typeof PayableTransactionModel>>;
