import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { PrintBlockArray } from "../../../Utils/PrintBlock.util";
import { useDateFilter } from "../../../Contexts/DateFilter.context";
import { CourierModel } from "../../../Models/Courier.model";
import { useCouriers } from "../../../Contexts/Courier.context";
import { useStacker } from "../../../Contexts/RabbitStacker.context";
import { OrderModel } from "../../../Models/Order.model";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useState } from "react";
import { OrderDAO } from "../../../Api/DAO/Order.dao";
import { RoundDAO } from "../../../Api/DAO/CourierTurn.dao";
import ArchivedDeliveriesView from "../View/ArchivedDeliveries.view";

export interface IArchivedDeliveriesProps {}

const ArchivedDeliveries: React.FC<IArchivedDeliveriesProps> = (props): React.ReactElement => {
    const stack = useStacker();
    const { form } = useDateFilter();
    const { couriersById } = useCouriers();
    const { restaurant, host } = useAccess();

    const [archivedDeliveries, setArchivedDeliveries] = useState<CourierTurnModel[]>([]);
    const [textFilter, setTextFilter] = useState<string>("");
    const [loading, setLoading] = useState<ILoading>(null);
    const [error, setError] = useState<IError>(null);

    const loadArchivedDeliveries = (): void => {
        setLoading(
            RoundDAO.getArchivedDeliveries(form.date.value as Date)
                .then((unsortedList) => {
                    return unsortedList.sort((aTurn, bTurn) => {
                        const aCourier = aTurn.getCourier(couriersById);
                        const bCourier = bTurn.getCourier(couriersById);
                        if (!aCourier || !bCourier) return -1;
                        if (aCourier.name < bCourier.name) return -1;
                        if (aCourier.name > bCourier.name) return 1;
                        return 0;
                    });
                })
                .then((sorterArchiveTurns) => {
                    setArchivedDeliveries(sorterArchiveTurns);
                    if (error) setError(null);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(null))
        );
    };

    useMountWithTriggers(loadArchivedDeliveries, [form]);

    const revokeDeliveryRound = (roundID: string): void => {
        setLoading(
            RoundDAO.revoke(roundID)
                .then(() => {
                    loadArchivedDeliveries();
                    stack.reloadRounds();
                })
                .catch((error: IError) => setError(error))
        );
    };

    const backToAddresses = (order: OrderModel): void => {
        setLoading(
            OrderDAO.revoke(order.id)
                .then(() => {
                    loadArchivedDeliveries();
                    stack.reloadOrders();
                })
                .catch((error: IError) => {
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    const closeStartedRound = (newDeliveryRound: CourierTurnModel): void => {
        RoundDAO.closeRoundAsSuccessful(newDeliveryRound.id)
            .then(() => {
                loadArchivedDeliveries();
                setLoading(false);
            })
            .catch((error: IError) => {
                setError(error);
                setLoading(false);
            });
    };

    const assignCourierAndStartRound = (order: OrderModel, courier: CourierModel): void => {
        OrderDAO.assignToCourier(order.id, courier.id)
            .then((newDeliveryRound) => {
                RoundDAO.startRound(newDeliveryRound.id)
                    .then(() => closeStartedRound(newDeliveryRound))
                    .catch((error: IError) => {
                        setError(error);
                        setLoading(false);
                    });
            })
            .catch((error: IError) => {
                setError(error);
                setLoading(false);
            });
    };

    const assignToOtherCourier = (order: OrderModel, courier: CourierModel): void => {
        setLoading(
            OrderDAO.revoke(order.id)
                .then(() => {
                    assignCourierAndStartRound(order, courier);
                })
                .catch((error: IError) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    const printTurnOrders = (orderIds: string[]): void => {
        const request = host?.is_cloud
            ? RoundDAO.getTurnPDFs(restaurant, orderIds)
            : RoundDAO.printTurnOrders(restaurant, orderIds);
        setLoading(
            request
                .then((blobs) => PrintBlockArray(blobs))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    return (
        <ArchivedDeliveriesView
            reloadArchivedDeliveries={loadArchivedDeliveries}
            assignToOtherCourier={assignToOtherCourier}
            archivedDeliveries={archivedDeliveries}
            backToAddresses={backToAddresses}
            resetError={() => setError(null)}
            printTurnOrders={printTurnOrders}
            setTextFilter={setTextFilter}
            revoke={revokeDeliveryRound}
            textFilter={textFilter}
            loading={loading}
            error={error}
        />
    );
};

export default ArchivedDeliveries;
