import { useNow } from "../../../Contexts/Now.context";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getElapsedSeconds } from "../../../Utils/GetElapsedSeconds.util";
import { useMountWithTriggers, useTimeout } from "xa-generics";

export interface ITimeSpanProps {
    date: Date;
    id: string;
    maxDelay: number;
    className: string;
    elementIsLate: boolean;
    warningClassName: string;
    onChangeLate: () => void;
}

const TimeSpan: React.FC<ITimeSpanProps> = (props) => {
    const now: number = useNow();
    const [node, setnode] = useState<JSX.Element | null>(null);
    const { t } = useTranslation();

    const { setTm, clearTm } = useTimeout();

    const JSXGetTimeSpan = (): JSX.Element => {
        const seconds: number = getElapsedSeconds(props.date, now);

        if (seconds < 60)
            return (
                <span className={props.className}>
                    {seconds} {t("time_seconds")}
                </span>
            );

        const minutes: number = Math.round(seconds / 60);
        if (minutes >= props.maxDelay) {
            if (!props.elementIsLate) {
                props.onChangeLate();
            }
            return (
                <span className={props.warningClassName}>
                    {minutes} {t("time_minutes")}
                </span>
            );
        }
        return (
            <span className={props.className}>
                {minutes} {t("time_minutes")}
            </span>
        );
    };

    const initTimeSpan = (): (() => void) => {
        if (!node) {
            setTm(() => setnode(JSXGetTimeSpan()), 500, props.id);
        } else {
            setnode(JSXGetTimeSpan());
        }

        return () => {
            clearTm(props.id);
        };
    };

    useMountWithTriggers(initTimeSpan, [now]);

    return <>{node || t("calculating")}</>;
};

export default TimeSpan;
