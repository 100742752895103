import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDidMount } from "xa-generics";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { Redirect } from "../../UI/Redirect/Redirect.view";
import Querystring from "querystring";
import Spinner from "../../UI/Spinner/Spinner";

export interface IPosAuthProps {}

const PosAuth: React.FC<IPosAuthProps> = (props) => {
    const { loadMeWithToken, accessModel, restaurant } = useAccess<false>();
    const { search } = useLocation();
    const { t } = useTranslation();
    const parsedURl: Querystring.ParsedUrlQuery = Querystring.parse(search.slice(1));

    const handlePosToken = (): void => {
        const token = `Bearer ${parsedURl.access_token}`;

        loadMeWithToken(token);
    };

    useDidMount(handlePosToken);

    if (accessModel && restaurant) return <Redirect to="/" />;

    return (
        <Spinner isLarge className="padded-spinner">
            {t("login")}
        </Spinner>
    );
};

export default PosAuth;
