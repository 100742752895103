import HU from "../Lang/HU.json";
import EN from "../Lang/EN.json";
import i18n from "i18next";
import huHU from "date-fns/locale/hu";
import enGB from "date-fns/locale/en-GB";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { initReactI18next } from "react-i18next";
import { XAInputDefaults } from "xa-inputs";
import { ErrorSettings } from "xa-error-with-lang";
import { inputLang } from "./../Lang/Inputs.lang";

export default function Config(defaultLanguage: string) {
    //Error
    ErrorSettings.setTexts("warning", "error", "close", "unknown_error_type");

    //Language and Input language locales
    registerLocale("hu-HU", huHU);
    registerLocale("en-GB", enGB);

    XAInputDefaults.Select.noOption = inputLang[defaultLanguage].noOption;
    XAInputDefaults.Select.loadingMessage = inputLang[defaultLanguage].loadingMessage;
    XAInputDefaults.Select.placeholder = inputLang[defaultLanguage].placeholder;
    XAInputDefaults.Select.searching = inputLang[defaultLanguage].searching;

    setDefaultLocale(defaultLanguage === "hu" ? "hu-HU" : "en-GB");

    i18n.use(initReactI18next).init({
        resources: {
            //Keys MUST match the registerLocale keys above.
            hu: {
                translation: HU
            },
            en: {
                translation: EN
            }
        },
        lng: defaultLanguage,
        fallbackLng: "hu",
        interpolation: {
            escapeValue: true
        }
    });
}
