export const FromatPhoneNumber = (phone: string): string => {
    let tel: string = phone.replace(/\s|\+|-/g, "");
    if (phone.startsWith("00")) tel = tel.slice(2);

    if (tel.length === 6) {
        return `${tel.slice(0, 3)} ${tel.slice(3)}`;
    }

    if (tel.length === 8) {
        return `${tel.slice(0, 2)} ${tel.slice(2, 5)} ${tel.slice(5)}`;
    }

    return `${tel.slice(0, 2)} ${tel.slice(2, 4)} ${tel.slice(4, 7)} ${tel.slice(7)}`;
};
