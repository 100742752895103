export type IUpdateType = "ORDERS" | "ROUNDS" | "UNITS";

export type IStatusUnion =
    | "done"
    | "in_progress"
    | "order_created"
    | "order_accepted"
    | "order_delivered"
    | "courier_started"
    | "assigned_to_courier";

export type IDeliveryStatuses = "created" | "on_road" | "go_back" | "finished";

export abstract class Statuses {
    public static readonly done: IStatusUnion = "done";
    public static readonly in_progress: IStatusUnion = "in_progress";
    public static readonly order_created: IStatusUnion = "order_created";
    public static readonly order_accepted: IStatusUnion = "order_accepted";
    public static readonly order_delivered: IStatusUnion = "order_delivered";
    public static readonly courier_started: IStatusUnion = "courier_started";
    public static readonly assigned_to_courier: IStatusUnion = "assigned_to_courier";

    public static getUpdateType(status: string | IStatusUnion): IUpdateType {
        switch (status) {
            case this.done:
            case this.in_progress:
            case this.order_created:
            case this.order_accepted:
                return "ORDERS";
            default:
                return "ROUNDS";
        }
    }

    public static readonly orderStatuses = {
        [Statuses.done]: Statuses.done,
        [Statuses.in_progress]: Statuses.in_progress,
        [Statuses.order_accepted]: Statuses.order_accepted,
        [Statuses.order_created]: Statuses.order_created
    };

    public static readonly deliveryStatuses = {
        created: "created",
        on_road: "on_road",
        go_back: "go_back",
        finished: "finished"
    };
}
