import { cloneDeep } from "lodash";
import { IDragData, IDragEventStart } from "./../Interfaces/IDragEvent.interface";

export const dragEventReorder = <T>(
    list: T[],
    target: IDragData,
    origin: IDragEventStart<T>,
    accessor: keyof T
): T[] | null => {
    if (target.roundID !== origin.roundID) return null;
    if (target.elementID === origin.elementID) return null;

    const newList: T[] = cloneDeep(list);
    const item: T = cloneDeep(origin.element);

    const indexOfOrigin: number = list.findIndex(
        (o: T) => o[accessor] === origin.element[accessor]
    );
    //Remove the origin order item from the list
    newList.splice(indexOfOrigin, 1);

    //Find the new index of the target element in the new list
    const indexOfTarget: number = list.findIndex((t: T) => t[accessor] === target.elementID);

    // Push the dragged element after the target element
    if (indexOfOrigin > indexOfTarget) {
        if (indexOfTarget === 0) {
            newList.unshift(item);
        } else {
            newList.splice(indexOfTarget, 0, item);
        }
    } else {
        const listLength: number = list.length - 1;
        if (indexOfTarget === listLength) {
            newList.push(item);
        } else {
            newList.splice(indexOfTarget, 0, item);
        }
    }

    return newList;
};
