import { useEnv } from "../../../Contexts/Environment.context";
import { useOrders } from "../../../Contexts/Orders.context";
import { OrderModel } from "../../../Models/Order.model";
import { useDelivery } from "../../../Contexts/DeliveryRounds.context";
import { useCouriers } from "../../../Contexts/Courier.context";
import { ModalContext } from "xa-modal";
import { MapOrderList } from "../Util/MapOrderList.util";
import { useMapFilter } from "../../../Contexts/MapFilter.context";
import { IMapDelivery } from "../Interface/IMapDelivery.interface";
import { IOrderContext } from "../../../Interfaces/IOrderContext.interface";
import { RestaurantModel } from "../../../Models/Restaurant.model";
import { MarkerWithLabel } from "../Util/MarkerWithLabel.util";
import { MapDeliveryList } from "../Util/MapDeliveryList.util";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { FC, useContext, useRef } from "react";
import { ISocketCourierCoordList } from "../../../Interfaces/ISocketTask.interface";
import { useDidMount, useMountWithTriggers } from "xa-generics";
import InfoboxController from "../../Infobox/Controller/Infobox.controller";
import CourierInfobox from "../../Infobox/View/CourierInfobox.view";
import "maplibre-gl/dist/maplibre-gl.css";
import "./Less/Map.css";

//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "!maplibre-gl"; // ! is important here
//@ts-ignore
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
import { Map, Marker } from "maplibre-gl";

maplibregl.workerClass = maplibreglWorker;

interface IMapViewProps {
    mapElement: JSX.Element;
    restaurant: RestaurantModel;
    loadingElement: JSX.Element;
    containerElement: JSX.Element;
}

export const MapView: FC<IMapViewProps> = (props) => {
    const orderContext: IOrderContext = useOrders();
    const { couriersById } = useCouriers();
    const modal = useContext(ModalContext);
    const deliveryContext = useDelivery();
    const { form } = useMapFilter();
    const environment = useEnv();

    const mapContainer = useRef<any>(null);
    const map = useRef<Map | null>(null);
    let orderMarkers = useRef<Marker[]>([]);
    let deliveryMarkers = useRef<Marker[]>([]);

    const lastKnownCoords: ISocketCourierCoordList = JSON.parse(
        localStorage.getItem("last-coords") || "{}"
    );

    useDidMount(() => {
        setMap();

        return () => {
            if (map.current) map.current.remove();
        };
    });

    const setMap = (): void => {
        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: `https://maps.geo.eu-central-1.amazonaws.com/maps/v0/maps/LCT/style-descriptor?key=${environment.AWS_API_KEY}`,
            center: [props.restaurant.coord_lng, props.restaurant.coord_lat],
            zoom: 15
        });

        new maplibregl.Marker({
            element: MarkerWithLabel(props.restaurant.name, "restaurant-icon", "restaurant-label")
        })
            .setLngLat([props.restaurant.coord_lng, props.restaurant.coord_lat])
            .addTo(map.current!);

        map.current!.addControl(
            new maplibregl.NavigationControl({ showCompass: true, showZoom: true }),
            "top-left"
        );
    };

    const onOrderClick = (
        order: OrderModel,
        swappable: boolean,
        delivery?: CourierTurnModel
    ): void => {
        modal.setContent(<InfoboxController order={order} swappable={swappable} turn={delivery} />);
    };

    useMountWithTriggers(() => {
        if (!map.current) return;

        if (orderMarkers.current.length > 0) {
            orderMarkers.current.forEach((item) => item.remove());
            orderMarkers.current = [];
        }

        if (form.courierFilter.value) {
            let hasUnassigned = false;
            for (let i = 0; i < form.courierFilter.value.length; i++) {
                if (form.courierFilter.value[i].id === "UNASSIGNED") {
                    hasUnassigned = true;
                }
            }

            if (!hasUnassigned) return;
        }

        const elements: HTMLElement[] = MapOrderList(
            form,
            couriersById,
            onOrderClick,
            orderContext.list
        );

        elements.forEach((el, index) => {
            orderMarkers.current.push(
                new maplibregl.Marker({
                    element: el
                })
                    .setLngLat([
                        orderContext.list[index].coord_lng,
                        orderContext.list[index].coord_lat
                    ])
                    .addTo(map.current!)
            );
        });
    }, [orderContext.list, form.courierFilter.value]);

    useMountWithTriggers(() => {
        if (!map.current) return;

        if (deliveryMarkers.current.length > 0) {
            deliveryMarkers.current.forEach((item) => item.remove());
            deliveryMarkers.current = [];
        }

        const elements: IMapDelivery[] = MapDeliveryList(
            deliveryContext.list,
            form,
            lastKnownCoords,
            couriersById,
            (delivery) => {
                modal.setContent(<CourierInfobox delivery={delivery} />);
            },
            onOrderClick
        );

        elements.forEach((el) => {
            if (el.roundCoords) {
                deliveryMarkers.current.push(
                    new maplibregl.Marker({
                        element: el.element
                    })
                        .setLngLat([el.roundCoords!.lng, el.roundCoords!.lat])
                        .addTo(map.current!)
                );
            }

            if (el.orderElements) {
                for (let i = 0; i < el.orderElements.elements.length; i++) {
                    deliveryMarkers.current.push(
                        new maplibregl.Marker({
                            element: el.orderElements.elements[i]
                        })
                            .setLngLat([
                                el.orderElements.orders[i].coord_lng,
                                el.orderElements.orders[i].coord_lat
                            ])
                            .addTo(map.current!)
                    );
                }
            }
        });
    }, [deliveryContext.list, form]);

    return (
        <div className="map">
            <div ref={mapContainer} className="map-view" />
        </div>
    );
};
