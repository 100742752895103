import printJS from "print-js";

export const PrintBlock = (pdfBlob: Blob | void, orderId: string): void => {
    if (!pdfBlob) return;
    const url = window.URL.createObjectURL(pdfBlob);
    const resolve = (): void => {
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 300);
    };
    printJS({
        printable: url,
        type: "pdf",
        onPrintDialogClose: resolve,
        onError: resolve,
        onIncompatibleBrowser: resolve,
        frameId: `print-pdf-${orderId}`,
        documentTitle: "PDF print",
        onPdfOpen: resolve
    });
};

const performPrint = (blobUrl: string, id: string, resolver: () => void | PromiseLike<void>) => {
    const uniqueID = `${id}-print-turn-pdf`;

    const performResolve = (): void => {
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            return resolver();
        }, 300);
    };

    //@ts-ignore
    const isFirefox = () => typeof InstallTrigger !== "undefined";
    if (isFirefox()) {
        setTimeout(() => {
            const inputs = document.getElementsByTagName("input");
            if (inputs instanceof Array && inputs[0]) {
                inputs[0].focus();
            }
            performResolve();
        }, 1200);
    }
    try {
        printJS({
            printable: blobUrl,
            type: "pdf",
            onPrintDialogClose: performResolve,
            onError: performResolve,
            onIncompatibleBrowser: performResolve,
            frameId: uniqueID,
            documentTitle: uniqueID,
            onPdfOpen: performResolve
        });
    } catch (error) {
        console.error("Error in perform print:", error);
        performResolve();
    }
};

export const PrintBlockArray = async (
    blobs: { blob: Blob; id: string }[] | void
): Promise<void> => {
    if (!blobs) return;

    for (let blobData of blobs) {
        await new Promise<void>((resolve, reject) => {
            const url = window.URL.createObjectURL(blobData.blob);
            performPrint(url, blobData.id, resolve);
        });
    }
};
