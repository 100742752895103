import { useState } from "react";
import { cloneDeep } from "lodash";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { CourierDAO } from "../../../Api/DAO/Courier.dao";
import { useCouriers } from "../../../Contexts/Courier.context";
import { CourierModel } from "../../../Models/Courier.model";
import { IError, ILoading } from "xa-generics";
import CouriersView from "../View/Couriers.view";

export interface ICouriersProps {}

const Couriers: React.FC<ICouriersProps> = (props): React.ReactElement => {
    const { restaurant } = useAccess();
    const { couriers, setCouriers } = useCouriers();

    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const changeAvailability = (couriedId: string, value: boolean): void => {
        setLoading(
            CourierDAO.setAvailability(couriedId, value)
                .then(() => {
                    let units: CourierModel[] = cloneDeep(couriers);
                    const index: number = units.findIndex(
                        (unit: CourierModel) => unit.id === couriedId
                    );
                    units[index].is_available = value;
                    setCouriers(units);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    return (
        <CouriersView
            error={error}
            loading={loading}
            restaurant={restaurant}
            resetError={() => setError(null)}
            changeAvailability={changeAvailability}
        />
    );
};

export default Couriers;
