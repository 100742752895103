import { IDynamicObject, ISelectOption } from "xa-generics";

export class OrderStatusModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;

        let options: ISelectOption[] = [];

        for (let key in data) {
            const option: ISelectOption = { id: key, name: data[key] };
            options.push(option);
        }

        this.statusAsArr = options;
        this.statusAsObj = data;
    }

    statusAsArr: ISelectOption[] = [];
    statusAsObj: IDynamicObject = {};
}
