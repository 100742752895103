import { Context, createContext, useState, useContext } from "react";
import { connect, Socket } from "socket.io-client";
import { ISocketContext } from "../Interfaces/ISocketContext.interface";
import { useDidMount } from "xa-generics";
import { useAccess } from "../Modules/Access/Context/Access.context";
import { useEnv } from "./Environment.context";

/**
 * ## SocketContext
 */
const SocketContext: Context<ISocketContext> = createContext<ISocketContext>(null as any);

SocketContext.displayName = "SocketContext";

interface ISocketContextProviderProps {}

/**
 * ## Socket context provider component
 *
 */
export const SocketContextProvider: React.FC<ISocketContextProviderProps> = (props) => {
    const env = useEnv();
    const { accessModel, restaurant } = useAccess();
    const [socket, setSocket] = useState<Socket | null>(null);

    useDidMount(() => {
        const initedSocket = connect(env.SOCKET_URL, {
            reconnectionDelay: 3000,
            reconnectionAttempts: 10,
            path: "/lct-rabbit",
            query: {
                restaurant_ids: accessModel.restaurant_ids.toString(),
                restaurant_id: restaurant.id
            }
        });
        setSocket(initedSocket);
    });

    return (
        <SocketContext.Provider
            value={{
                socket: socket as Socket
            }}
        >
            {!socket ? null : props.children}
        </SocketContext.Provider>
    );
};

export const useSocket = (): ISocketContext => useContext(SocketContext);
