export interface IDropMenuItemProps {
    onClick: Function;
}

export const DropMenuItem: React.FC<IDropMenuItemProps> = ({ onClick, children }) => {
    return (
        <div
            className="menu-item"
            onClick={(e) => {
                if (onClick) onClick();
            }}
        >
            {children}
        </div>
    );
};
