import { Context, createContext, useContext, useState } from "react";
import { IDateFilterContext } from "../Interfaces/IDateFilterContext.interface";
import { IDateFilterForm } from "../Interfaces/IDateFilterForm.interface";
import { DATEFILTERFORM } from "../Static/DateFilter.form";
import { ChangeHandler } from "../Utils/ChangeHandler.util";
import { IInputChange } from "xa-inputs";
import { cloneDeep } from "lodash";

/**
 * ## DateFilterContext
 *
 * This context stores the global date state that triggers
 * an update in the orders and delivery contexts.
 */
const DateFilterContext: Context<IDateFilterContext> = createContext<IDateFilterContext>({
    form: null as any,
    onChange: null as any
});

DateFilterContext.displayName = "DateFilterContext";

interface IDateFilterContextWrapperProps {}

/**
 * ## DateFilter context wrapper component
 *
 */
export const DateFilterContextWrapper: React.FC<IDateFilterContextWrapperProps> = (props) => {
    const [form, setform] = useState<IDateFilterForm>(cloneDeep(DATEFILTERFORM));

    const onChange = (e: IInputChange): void => {
        setform(ChangeHandler(form, e.fieldName, e.value));
    };

    return (
        <DateFilterContext.Provider
            value={{
                onChange,
                form
            }}
        >
            {props.children}
        </DateFilterContext.Provider>
    );
};

export const useDateFilter = () => useContext(DateFilterContext);
