import { Context, createContext, useState, useContext } from "react";
import { ILoading, IError, useMountWithTriggers } from "xa-generics";
import { IDeliveryFilterForm } from "../Components/Delivery/Interfaces/IDeliveryFilterForm.interface";
import { DeliveryFilterForm } from "../Components/Delivery/Static/DeliveryFilter.form";
import { IDeliveryContext } from "../Interfaces/IDeliveryContext.interface";
import { CourierTurnModel } from "../Models/CourierTurn.model";
import { useDateFilter } from "./DateFilter.context";
import { ChangeHandler } from "../Utils/ChangeHandler.util";
import { IInputChange } from "xa-inputs";
import { useCouriers } from "./Courier.context";
import { cloneDeep } from "lodash";
import { useAccess } from "../Modules/Access/Context/Access.context";
import { RoundDAO } from "../Api/DAO/CourierTurn.dao";

/**
 * ## DeliveryContext
 */
const DeliveryContext: Context<IDeliveryContext> = createContext<IDeliveryContext>(null as never);

DeliveryContext.displayName = "DeliveryContext";

interface IDeliveryContextWrapperProps {}

/**
 * ## Delivery context wrapper component
 *
 */
export const DeliveryContextWrapper: React.FC<IDeliveryContextWrapperProps> = (props) => {
    const { restaurant } = useAccess();
    const { couriersById } = useCouriers();
    const {
        form: { date }
    } = useDateFilter();

    const [deliveryForm, setForm] = useState<IDeliveryFilterForm>(cloneDeep(DeliveryFilterForm));
    const [list, setList] = useState<CourierTurnModel[]>([]);
    const [loading, setLoading] = useState<ILoading>(true);
    const [error, setError] = useState<IError>(null);

    const loadRounds = (): void => {
        setLoading(
            RoundDAO.getDeliveryRounds(restaurant.delay_time, date.value as Date)
                .then((unsortedList) => {
                    return unsortedList.sort((aTurn, bTurn) => {
                        const aCourier = aTurn.getCourier(couriersById);
                        const bCourier = bTurn.getCourier(couriersById);
                        if (!aCourier || !bCourier) return -1;
                        if (aCourier.name < bCourier.name) return -1;
                        if (aCourier.name > bCourier.name) return 1;
                        return 0;
                    });
                })
                .then((sortedTurns) => setList(sortedTurns))
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useMountWithTriggers(loadRounds, [date]);

    return (
        <DeliveryContext.Provider
            value={{
                onChange: (e: IInputChange) =>
                    setForm(ChangeHandler(deliveryForm, e.fieldName, e.value)),
                reloadRounds: loadRounds,
                form: deliveryForm,
                setList,
                loading,
                error,
                list
            }}
        >
            {props.children}
        </DeliveryContext.Provider>
    );
};
export const useDelivery = () => useContext(DeliveryContext);
