import { PaymentTranslationModel } from "../../../Models/PaymentTranslation.model";
import { axios, IDynamicObject } from "xa-generics";
import { IDictionaryModels } from "../Interfaces/IDictionaryContext.interface";
import { OrderStatusModel } from "../Models/OrderStatus.model";
import { PaymentLangModel } from "../../../Models/PaymentLang.model";
import { PayMethodsModel } from "../Models/PayMethods.model";

export default abstract class DictionaryDAO {
    private static readonly pay: string = "/api/dictionary/payment-methods";
    private static readonly statuses: string = "/api/dictionary/order-statuses";

    public static async getPayMethods(): Promise<PayMethodsModel> {
        const request = await axios.getInstance().get<IDynamicObject>(this.pay);
        return new PayMethodsModel(request.data);
    }

    public static async getStatuses(): Promise<OrderStatusModel> {
        const request = await axios.getInstance().get<IDynamicObject>(this.statuses);
        return new OrderStatusModel(request.data);
    }

    public static async loadPaymentTranslations(): Promise<PaymentTranslationModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(`/api/payment-methods`);
        const payments = request.data.map((item) => new PaymentLangModel(item));
        return new PaymentTranslationModel(payments);
    }

    public static async loadDictionary(): Promise<IDictionaryModels> {
        const payments: PayMethodsModel = await this.getPayMethods();
        const statuses: OrderStatusModel = await this.getStatuses();
        const translations: PaymentTranslationModel = await this.loadPaymentTranslations();

        return {
            payment: payments,
            status: statuses,
            translations
        };
    }
}
