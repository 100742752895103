import { IDynamicObject } from "xa-generics";
import { PaymentLangModel } from "./PaymentLang.model";

export class PaymentTranslationModel {
    constructor(data?: PaymentLangModel[]) {
        if (!data) return;

        let methodsById: IDynamicObject<PaymentLangModel> = {};
        for (let index in data) {
            if (data[index].payment_method_id) {
                methodsById[data[index].payment_method_id] = data[index];
            }
        }
        this.methodsById = methodsById;
    }

    private readonly methodsById: IDynamicObject<PaymentLangModel> = {};

    public getById(id: string): string {
        return this.methodsById[id]?.name || "Unrecognized payment method id";
    }
}
