import { IRestaurantConfigForm } from "../Interfaces/IRestaurantConfigForm.interface";

export const RESTAURANT_CONFIG_FORM: IRestaurantConfigForm = {
    conf_sort_product_by: {
        id: "conf_sort_product_by",
        value: null,
        options: [],
        selectType: true
    },
    conf_km_price: {
        id: "conf_km_price",
        value: "0",
        required: true,
        isNumeric: true,
        textType: true
    },
    conf_include_finish_distance: {
        id: "conf_include_finish_distance",
        value: false,
        booleanType: true
    },
    conf_sort_product_method: {
        id: "conf_sort_product_method",
        value: { id: "placeholder", name: "placeholder" }, //The value and options will be initialised in the controller for this one as well
        options: [],
        selectType: true
    },
    conf_auto_optimize: {
        id: "conf_auto_optimize",
        value: false,
        booleanType: true
    }
};
