import { MapView } from "../View/Map.view";
import { useAccess } from "../../../Modules/Access/Context/Access.context";

export interface IMapProps {}

export const Map: React.FC<IMapProps> = (props) => {
    const { restaurant } = useAccess();

    return (
        <MapView
            {...props}
            loadingElement={<div className="map map__loading"></div>}
            containerElement={<div className="map map__container"></div>}
            mapElement={<div className="map map__element"></div>}
            restaurant={restaurant}
        />
    );
};
