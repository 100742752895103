import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { Statuses } from "../../../Static/Statuses.static";
import DeliveryTime from "../../UI/TimeSpan/DeliveryTime.view";
import ElapsedTime from "../../UI/TimeSpan/ElapsedTime.view";
import "./Less/CourierInfobox.css";
import { useCouriers } from "../../../Contexts/Courier.context";

export interface ICourierInfoboxProps {
    delivery: CourierTurnModel;
}

const CourierInfobox: React.FC<ICourierInfoboxProps> = (props) => {
    const { t } = useTranslation();
    const { couriersById } = useCouriers();
    const { restaurant } = useAccess();

    const courier = props.delivery.getCourier(couriersById);

    return (
        <div className="courier-infobox">
            <div className={`courier-infobox__name`}>
                <span className={`courier-infobox__name--box ${props.delivery.status}`}>
                    {courier?.name || "-"}
                </span>
            </div>
            <div className="courier-infobox__data">
                <div className="courier-infobox__data--block">
                    <DeliveryTime
                        round={props.delivery}
                        text={t("c_info_round")}
                        labelClass={"label"}
                        valueClass={"value"}
                    />
                </div>
                {props.delivery.status === Statuses.deliveryStatuses.created ? (
                    <div className="courier-infobox__data--block">
                        <div className="label">{t("c_info_created")}</div>
                        <ElapsedTime
                            className="value time-value"
                            warningClassName={"value time-value-warning"}
                            maxDelay={restaurant.delay_time}
                            date={props.delivery.created_at_as_date}
                        />
                    </div>
                ) : (
                    <div className="courier-infobox__data--block">
                        <div className="label">{t("c_info_started")}</div>
                        <ElapsedTime
                            className="value time-value"
                            warningClassName={"value time-value-warning"}
                            maxDelay={restaurant.delay_time}
                            date={props.delivery.started_at_as_date}
                        />
                    </div>
                )}
                <div className="courier-infobox__data--block">
                    <div className="label">{t("c_info_all_address")}</div>
                    <div className="value">{props.delivery.all_order_qty || 0}</div>
                </div>
                <div className="courier-infobox__data--block">
                    <div className="label">{t("c_info_unsuccessful")}</div>
                    <div className="value">{props.delivery.failed_order_qty || 0}</div>
                </div>
                <div className="courier-infobox__data--block">
                    <div className="label">{t("c_info_successful")}</div>
                    <div className="value">{props.delivery.delivered_order_qty || 0}</div>
                </div>
                <div className="courier-infobox__data--block">
                    <div className="label">{t("c_info_expected")}</div>
                    <div className="value">
                        <ElapsedTime
                            className="value time-value"
                            warningClassName={"value time-value"}
                            maxDelay={restaurant.delay_time}
                            date={props.delivery.full_travel_time}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourierInfobox;
