import AssignCourierView from "../View/AssignCourier.view";
import { useState, useContext } from "react";
import { ILoading, IError } from "xa-generics";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { CourierModel } from "../../../Models/Courier.model";
import { ModalContext } from "xa-modal";
import { useCouriers } from "../../../Contexts/Courier.context";
import { useStacker } from "../../../Contexts/RabbitStacker.context";
import { OrderModel } from "../../../Models/Order.model";
import { useOrders } from "../../../Contexts/Orders.context";
import { OrderDAO } from "../../../Api/DAO/Order.dao";

export interface IAssignCourierControllerProps {
    order: OrderModel | OrderModel[];
    courier?: CourierModel | null;
    returnSelectedCourier?: (courier: CourierModel) => void;
}

const AssignCourierController: React.FC<IAssignCourierControllerProps> = (
    props
): React.ReactElement => {
    const modal = useContext(ModalContext);
    const orders = useOrders();
    const { couriers } = useCouriers();
    const stack = useStacker();

    const [selectedCourier, setSelectedCourier] = useState<CourierModel | null>(
        props.courier || null
    );
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const assignOrderToCourier = (): void => {
        if (!selectedCourier) return;
        if (props.returnSelectedCourier) return props.returnSelectedCourier(selectedCourier);

        const request: Promise<CourierTurnModel | void> =
            props.order instanceof Array
                ? OrderDAO.ordersToCourier(selectedCourier.id)
                : OrderDAO.assignToCourier(props.order.id, selectedCourier.id);

        setLoading(
            request
                .then(() => {
                    localStorage.removeItem("check_list");
                    stack.reloadOrders();
                    stack.reloadRounds();
                    modal.setContent(null);
                })
                .catch((error: IError) => {
                    setError(error);
                    setLoading(null);
                })
        );
    };

    return (
        <AssignCourierView
            {...props}
            error={error}
            orders={orders.list}
            loading={loading}
            couriers={couriers}
            selected={selectedCourier}
            setSelectedCourier={setSelectedCourier}
            assignOrderToCourier={assignOrderToCourier}
        />
    );
};

export default AssignCourierController;
