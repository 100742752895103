import { useTranslation } from "react-i18next";

export interface ICourierHeaderElementViewProps {}

const CourierHeaderElementView: React.FC<ICourierHeaderElementViewProps> = (
    props
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="courier courier__header">
            <div className="name">{t("courier_name")}</div>
            <div className="email">{t("identifier")}</div>
            <div className="input">{t("courier_available")}?</div>
        </div>
    );
};

export default CourierHeaderElementView;
