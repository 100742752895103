import { cloneDeep } from "lodash";
import { useOrders } from "../../../Contexts/Orders.context";
import { OrderModel } from "../../../Models/Order.model";
import { useContext } from "react";
import { ModalContext } from "xa-modal";
import { CourierModel } from "../../../Models/Courier.model";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import AssignCourierController from "../../AssignCourier/Controller/AssignCourier.controller";
import OrderEditorController from "../../OrderEditor/Controller/OrderEditor.controller";
import InfoboxView from "../View/Infobox.view";

export interface IInfoboxControllerProps {
    turn?: CourierTurnModel;
    order?: OrderModel;
    swappable: boolean;
}

const InfoboxController: React.FC<IInfoboxControllerProps> = (props) => {
    const modal = useContext(ModalContext);
    const orderContext = useOrders();

    const assignToCourier = (order: OrderModel, courier?: CourierModel | null): void => {
        modal.setContent(<AssignCourierController order={order} courier={courier} />);
    };

    const editOrder = (order: OrderModel): void => {
        modal.setContent(<OrderEditorController order={order} />);
    };

    const markForAssign = (order: OrderModel, value: boolean): void => {
        const index: number = orderContext.list.findIndex((o: OrderModel) => o.id === order.id);
        if (index > -1) {
            const list: OrderModel[] = cloneDeep(orderContext.list);
            list[index].isCheckedToAssign = value;
            orderContext.updateList(list);
            modal.setContent(null);
        }
    };

    if (props.order) {
        return (
            <InfoboxView
                {...props}
                order={props.order as OrderModel}
                assignToCourier={assignToCourier}
                markForAssign={markForAssign}
                editOrder={editOrder}
            />
        );
    }

    return null;
};

export default InfoboxController;
