interface ILockerSVGProps {
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
    className?: string;
}

export const LockerSVG: React.FC<ILockerSVGProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x={props.width || "0"}
            y={props.height || "0"}
            className={props.className}
            enableBackground="new 0 0 478 596"
            version="1.1"
            viewBox="0 0 478 596"
            xmlSpace="preserve"
        >
            <path
                d="M178.9 99.9c-23.1 14.7-36.8 40.3-36.8 67.6v74.4h193.2v-75c-7.9-66.8-83.9-113.1-156.4-67z"
                style={{ fill: "none" }}
                className="st0"
            ></path>
            <path
                d="M261 464v-41.4c12-7.4 20.1-20.6 20.1-35.8 0-32-35.7-55.6-69.4-33.4-2.5 1.6-4.6 3.8-6.3 6.3-16.5 25.4-7.4 51.3 11.5 62.9V464H261z"
                style={{ fill: "#5793CE" }}
                className="st1"
            ></path>
            <path
                d="M52.2 596.4h373.6c24.5 0 45.1-16.8 50.8-39.6H1.4c5.7 22.7 26.3 39.6 50.8 39.6z"
                style={{ fill: "#C0DCF3" }}
                className="st2"
            ></path>
            <path
                d="M-.3 543.8c0 4.5.6 8.8 1.7 13h475.3c1.1-4.2 1.7-8.5 1.7-13v-8.4H-.3v8.4z"
                className="st1"
                style={{ fill: "#5793CE" }}
            ></path>
            <path
                d="M411.6 178.3C411.6 54 279.7-40 148.6 29c-51 26.9-82.2 80.6-82.2 138.3v74.6H32.5c-18.1 0-32.8 14.7-32.8 32.8v260.7h478.7V274.8c0-18.1-14.7-32.8-32.8-32.8h-35v-63.7h1zM261 422.6V464h-44v-41.4c-18.9-11.6-28.1-37.5-11.5-62.9 1.6-2.5 3.8-4.7 6.3-6.3 33.7-22.2 69.4 1.4 69.4 33.4-.1 15.1-8.2 28.3-20.2 35.8zm74.2-180.7H142.1v-74.4c0-27.3 13.8-53 36.8-67.6 72.5-46 148.5.2 156.3 67.1v74.9z"
                className="st2"
                style={{ fill: "#C0DCF3" }}
            ></path>
        </svg>
    );
};
