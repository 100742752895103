import { map } from "lodash";
import { useState } from "react";
import { OrderModel } from "../../../Models/Order.model";
import { textFilter } from "../../../Utils/TextFilter.util";
import { FloatingError } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IError, ILoading } from "xa-generics";
import { Button, TextInput } from "xa-inputs";
import ArchivedOrderElementView from "./ArchivedOrder.element.view";
import DrawerHeader from "../../Drawer/View/DrawerHeader";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/ArchivedOrders.css";

export interface IArchivedOrderElementFunctions {
    markOrder: (order: OrderModel, value: boolean) => void;
    revokeOrder: (order: OrderModel) => void;
    editOrder: (order: OrderModel) => void;
}

export interface IArchivedOrdersViewProps extends IArchivedOrderElementFunctions {
    reloadArchivedOrders: () => void;
    revokerMarkedOrders: () => void;
    archivedOrders: OrderModel[];
    markAllOrder: () => void;
    resetError: () => void;
    loading: ILoading;
    error: IError;
}

const ArchivedOrdersView: React.FC<IArchivedOrdersViewProps> = (props): React.ReactElement => {
    const [counterFilter, setCounterFilter] = useState<string>("");
    const [textFilterInput, setTextFilter] = useState<string>("");
    const { t } = useTranslation();

    const textRegexp: RegExp = new RegExp(textFilterInput, "i");

    return (
        <div className="orders-view archived-orders-view">
            <DrawerHeader title={t("archive_orders_tab_title")} />
            {props.loading && <Spinner isAbsolute isLarge />}
            <FloatingError error={props.error} resetError={props.resetError} />

            <div className="archived-orders-view__button-container">
                <Button textButton onClick={props.markAllOrder}>
                    {t("mark_all_order")}
                </Button>
                <Button textButton onClick={props.revokerMarkedOrders}>
                    {t("revoke_marked_archives")} <span className="flaticon-switch icon"></span>
                </Button>
            </div>

            <div className="delivery-view__manual-dispatch">
                <div className="delivery-view__filter">
                    <TextInput
                        isNumeric
                        value={counterFilter}
                        id={"delivery-counter-filter"}
                        placeholder={t("counter_filter")}
                        className="delivery-view__filter--counter"
                        onChange={(e) => setCounterFilter(e.value)}
                    />
                    <TextInput
                        value={textFilterInput}
                        id={"delivery-text-filter"}
                        placeholder={t("order_text_filter")}
                        className="delivery-view__filter--text"
                        onChange={(e) => setTextFilter(e.value)}
                    />
                </div>
            </div>

            <div className="orders-view__list-container">
                {props.archivedOrders.length > 0 ? (
                    map(props.archivedOrders, (archivedOrder: OrderModel, index: number) => {
                        if (textFilterInput && !textFilter(textRegexp, archivedOrder)) return null;
                        if (counterFilter && counterFilter !== `${archivedOrder.counter}`)
                            return null;
                        return (
                            <ArchivedOrderElementView
                                key={`${index}-archived-order-element`}
                                revokeOrder={props.revokeOrder}
                                archivedOrder={archivedOrder}
                                markOrder={props.markOrder}
                                editOrder={props.editOrder}
                            />
                        );
                    })
                ) : (
                    <div className="orders-view__list-container--no-data no-data">
                        <span>{t("no_orders_in_list")}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArchivedOrdersView;
