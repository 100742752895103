import { IOrderEditorForm } from "../Interface/IOrderEditorForm.interface";

export const OrderEditorForm: IOrderEditorForm = {
    city: {
        id: "city",
        value: "",
        required: true
    },
    street: {
        id: "street",
        value: "",
        required: true
    },
    house_number: {
        id: "house_number",
        value: "",
        className: "half-sized-input",
        required: true
    },
    floor: {
        id: "floor",
        value: "",
        className: "half-sized-input"
    },
    door: {
        id: "door",
        value: "",
        className: "half-sized-input"
    },
    bell: {
        id: "bell",
        value: "",
        className: "half-sized-input"
    }
};
