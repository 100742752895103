import { PaymentSummaryModel } from "../Model/PaymentSummary.model";
import { ICourierSummaries } from "../Interfaces/ICourierSummaries.type";
import { ILoading, IError } from "xa-generics";
import { useTranslation } from "react-i18next";
import { FloatingError } from "xa-error-with-lang";
import { CourierModel } from "../../../Models/Courier.model";
import { ISummaryForm } from "../Interfaces/ISummaryForm.interface";
import { IOnChange } from "xa-inputs";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import SummarySidebarView from "./SummarySidebar.view";
import SummaryFormView from "./SummaryForm.view";
import SummaryDataView from "./SummaryData.view";
import CenterSummary from "./CenterSummary.view";
import RequirePin from "../../UI/RequirePin/RequirePin.view";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/Summary.css";

export interface ISummaryViewProps {
    error: IError;
    loading: ILoading;
    closeSummary: () => void;
    summaryForm: ISummaryForm;
    onChangeSummary: IOnChange;
    setError: (error: IError) => void;
    centerSummary: PaymentSummaryModel;
    courierSummaries: ICourierSummaries;
    selectedCourier: CourierModel | null;
    loadCourierSummary: (forceReload?: boolean) => void;
    setSelectedCourier: (courier: CourierModel) => void;
    validatePin: (value: string) => void;
    exportSummary: () => void;
    isValidPin: boolean;
    pin: string;
}

const SummaryView: React.FC<ISummaryViewProps> = (props) => {
    const { t } = useTranslation();

    const { accessModel } = useAccess();

    if (accessModel.has_pin && (!props.pin || !props.isValidPin)) {
        return (
            <div className="summary-view">
                <div className={"page-wrapper"}>
                    <RequirePin
                        isInvalid={!props.isValidPin}
                        validatePin={props.validatePin}
                        loading={props.loading}
                        close={props.closeSummary}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="summary-view">
            {props.loading && (
                <Spinner isAbsolute isLarge>
                    {t("summary_loading")}
                </Spinner>
            )}
            <FloatingError error={props.error} resetError={() => props.setError(null)} />
            <div className={"page-wrapper"}>
                <div className="summary-view__header">
                    <h1 className="summary-view__header--title">{t("summary_title")}</h1>
                    <div
                        className="exit summary-view__header--close"
                        onClick={props.closeSummary}
                    ></div>
                </div>
                <div className="summary-view__body">
                    <SummarySidebarView {...props} />
                    <div className="summary-view__body-data">
                        <SummaryFormView {...props} />
                        <CenterSummary {...props} />
                        <SummaryDataView {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryView;
