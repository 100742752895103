import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { IDynamicObject } from "xa-generics";
import { CourierModel } from "../../../Models/Courier.model";
import { OrderModel } from "../../../Models/Order.model";
import { Statuses } from "../../../Static/Statuses.static";

export const OrderMarkerView = (
    order: OrderModel,
    couriersById: IDynamicObject<CourierModel>,
    onClick: (order: OrderModel, swappable: boolean, delivery?: CourierTurnModel) => void,
    delivery?: CourierTurnModel
): HTMLElement => {
    let swappable: boolean = false;
    let classes: string[] = ["map__label"];
    let iconClass: string = order.status;

    switch (order.status) {
        case Statuses.done:
        case Statuses.assigned_to_courier:
            swappable = true;
            classes.push(`${Statuses.done}`);
            break;
        case Statuses.courier_started:
            classes.push(`${Statuses.courier_started}`);
            break;
        case Statuses.order_delivered:
            classes.push(`${Statuses.order_delivered}`);
            break;
        case Statuses.in_progress:
            swappable = true;
            classes.push("not-occupied");
            break;
        case Statuses.order_created:
        case Statuses.order_accepted:
        default:
            if (delivery) {
                swappable = true;
                iconClass = Statuses.done;
                classes.push(`${Statuses.done}`);
            } else {
                iconClass = Statuses.order_created;
                classes.push("order-initial-status");
            }
            break;
    }

    if (order.isCheckedToAssign) classes.push("checked-order-marker");

    const el = document.createElement("div");
    el.className = "marker-container";

    el.addEventListener("click", () => onClick(order, swappable, delivery));

    const icon = document.createElement("div");
    icon.className = `${iconClass}-icon`;
    icon.style.width = `38px`;
    icon.style.height = `60px`;
    el.appendChild(icon);

    if (order.isLate) {
        const lateDiv = document.createElement("div");
        lateDiv.className = "late-sign";
        el.appendChild(lateDiv);
    }

    const subDiv = document.createElement("div");
    subDiv.className = classes.join(" ");

    if (order.counter) {
        var counterLabel = document.createElement("label");
        counterLabel.innerHTML = `${order.counter} | `;
        subDiv.appendChild(counterLabel);
    }

    var addressLabel = document.createElement("label");
    addressLabel.innerHTML = `${order.street} ${order.house_number}`;
    subDiv.appendChild(addressLabel);

    el.appendChild(subDiv);

    if (delivery) {
        const deliveryLabel = document.createElement("label");
        deliveryLabel.className = `courier-data ${iconClass}`;
        deliveryLabel.innerHTML = `${delivery.getCourier(couriersById)?.name || "-"}`;
        el.appendChild(deliveryLabel);
    }
    return el;
};
