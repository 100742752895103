export const formatCurrency = (value: number | string | null): string => {
    const num: number = Math.round(Number(value));
    return new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        useGrouping: true
    })
        .format(Number(num))
        .replace(/,/g, " ");
};
