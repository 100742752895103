import { IOrderElementFunctions } from "./Orders.view";
import { useTranslation } from "react-i18next";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { DropMenuItem } from "../../UI/DropMenu/DropMenuItem.view";
import { StoreCheck } from "../Util/OrderChecks.util";
import { OrderModel } from "../../../Models/Order.model";
import { Checkbox } from "xa-inputs";
import TimeSpan from "../../UI/TimeSpan/TimeSpan.view";
import DropMenu from "../../UI/DropMenu/DropMenu.view";

export interface IOrderElementViewProps extends IOrderElementFunctions {
    order: OrderModel;
    maxDelay: number;
}

const OrderElementView: React.FC<IOrderElementViewProps> = (props): React.ReactElement => {
    const { t } = useTranslation();

    const dictionary = useDictionary();
    const order: OrderModel = props.order;

    const handleCheck = (): void => {
        StoreCheck(order.id, !order.isCheckedToAssign);
        props.updateOrderElement(
            "UPDATE",
            order,
            "id",
            "isCheckedToAssign",
            !order.isCheckedToAssign
        );
    };

    return (
        <div className="order" id={`${order.id}`} onClick={handleCheck}>
            <div className="order__checkbox">
                <Checkbox
                    id={`order-${order.id}`}
                    value={order.isCheckedToAssign}
                    onChange={() => {}}
                />
                <div className="order__checkbox--overlay">
                    {/* This div is to prevent the onChange from bubbling up to the 'handleCheck'
                        and do a double state refresh, ultimately leaving the state the same. */}
                </div>
            </div>
            <div className="order__status">
                <span className="order__status--counter">{order.counter}</span>
                <span className={`circle ${order.status}`} />
            </div>
            <div className="order__data">
                <span className="order__data--address">{order.getFormattedAddress(t)}</span>
                {order.address_notes && (
                    <span className="order__data--address-notes">
                        {`${t("address_notes")}: ${order.address_notes}`}
                    </span>
                )}
                <span className="order__data--name">
                    {order.first_name} {order.last_name}: {order.comment || "-"}
                </span>
            </div>
            <div className="order__payment">
                <span className="order__payment--method">
                    {dictionary.parsePayment(order.payment_method)}
                </span>
                <TimeSpan
                    id={order.id}
                    maxDelay={props.maxDelay}
                    className="order__payment--timer"
                    warningClassName="order__payment--timer--warning"
                    onChangeLate={() =>
                        props.updateOrderElement("UPDATE", order, "id", "isLate", true)
                    }
                    elementIsLate={order.isLate}
                    date={order.showAtAsDate}
                />
            </div>
            <div className="order__dots">
                <DropMenu>
                    <DropMenuItem
                        onClick={() => {
                            props.assignToCourier(order);
                        }}
                    >
                        {t("order_assign_courier")}
                    </DropMenuItem>
                    <DropMenuItem
                        onClick={() => {
                            props.printBigBlock(order.id);
                        }}
                    >
                        {t("print_big_block")}
                    </DropMenuItem>
                    <DropMenuItem
                        onClick={() => {
                            props.editOrder(order);
                        }}
                    >
                        {t("order_edit")}
                    </DropMenuItem>
                </DropMenu>
            </div>
        </div>
    );
};

export default OrderElementView;
