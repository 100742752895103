import { map } from "lodash";
import { RootDAO } from "../../../Api/DAO/Root.dao";
import { ProductCountModel } from "../Model/ProductCount.model";
import { axios, IDynamicObject } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export abstract class ProductCountDAO extends RootDAO {
    public static async loadProductCount(date: string): Promise<ProductCountModel[]> {
        const request: AxiosPromise<IDynamicObject[]> = axios
            .getInstance()
            .get(`${this.rest}/products-summary?date=${date}`);
        const response: AxiosResponse<IDynamicObject[]> = await request;
        return map(response.data, (item) => new ProductCountModel(item));
    }
}
