import { FC } from "react";
import { Redirect } from "../../UI/Redirect/Redirect.view";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { Route, Routes } from "react-router";
import { DictCtxWrapper } from "../../Dictionary/Context/Dictionary.context";
import PageContainerController from "../../PageContainer/Controller/PageContainer.controller";
import AuthController from "../../Auth/Controller/Auth.controller";
import LabelPrinting from "../../Print/Controller/LabelPrinting.controller";
import PrintRound from "../../Print/Controller/Print.controller";
import PrintMenu from "../../Print/Controller/PrintMenu.controller";
import PosAuth from "../../Auth/Controller/PosAuth.controller";

interface IRouterProps {}

export const Router: FC<IRouterProps> = (props) => {
    const { accessModel, restaurant } = useAccess<false>();

    if (!accessModel || !restaurant) {
        return (
            <Routes>
                <Route path="/pos" element={<PosAuth />} />
                <Route path="*" element={<AuthController />} />
            </Routes>
        );
    }

    return (
        <DictCtxWrapper>
            <Routes>
                <Route path="/pos" element={<PosAuth />} />
                <Route path="/login" element={<AuthController />} />
                <Route path="/print/:rount_id" element={<PrintRound />} />
                <Route path="/print/menu/:rount_id" element={<PrintMenu />} />
                <Route path="/print/labels/:rount_id" element={<LabelPrinting />} />
                <Route path="/" element={<PageContainerController />} />
                <Route path="*" element={<Redirect to={"/"} />} />
            </Routes>
        </DictCtxWrapper>
    );
};
