import "./Less/DrawerHeader.css";

interface IDrawerHeaderProps {
    title?: string;
    children?: any;
}

const DrawerHeader = (props: IDrawerHeaderProps) => {
    return (
        <div className="drawer__header">
            <h1 className="drawer__header--title">{props.title}</h1>
            {props.children}
        </div>
    );
};

export default DrawerHeader;
