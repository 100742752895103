import { ModelConstructor, IDynamicObject } from "xa-generics";
import { BaseOrderModel } from "../../../Models/Order.model";
import { OrderItemModel } from "./OrderItem.model";
import { PayableTransactionModel } from "../../../Models/PayableTransaction.model";

export class PrintOrderModel extends BaseOrderModel {
    constructor(rawData?: IDynamicObject<any>) {
        super();
        if (!rawData) return;
        const data = rawData as PrintOrderMData;
        ModelConstructor(data, this);

        this.init();

        if (data.order_items instanceof Array) {
            this.order_items = data.order_items.map((item) => new OrderItemModel(item));
        }

        if (data.payable_transactions instanceof Array) {
            this.payable_transactions = data.payable_transactions.map(
                (item) => new PayableTransactionModel(item)
            );
        }
    }

    balance: number = 0;
    order_items: OrderItemModel[] = [];
    payable_transactions: PayableTransactionModel[] = [];
}

//If you add public methods, you may want to replace the empty string with them!
type PrintOrderMData = Partial<InstanceType<typeof PrintOrderModel>>;
