import { DateFilterContextWrapper } from "../../../Contexts/DateFilter.context";
import { MapFilterContextWrapper } from "../../../Contexts/MapFilter.context";
import { DeliveryContextWrapper } from "../../../Contexts/DeliveryRounds.context";
import { CourierContextProvider } from "../../../Contexts/Courier.context";
import { RabbitStackerProvider } from "../../../Contexts/RabbitStacker.context";
import { SocketContextProvider } from "../../../Contexts/Socket.context";
import { OrderContextWrapper } from "../../../Contexts/Orders.context";
import { NowContextWrapper } from "../../../Contexts/Now.context";
import { NotifyController } from "../../Notify/Context/Notify.context";
import { GlobalModal } from "xa-modal";
import PageContainerView from "../View/PageContainer.view";

export interface IPageContainerControllerProps {}

const PageContainerController: React.FC<IPageContainerControllerProps> = (
    props
): React.ReactElement => {
    return (
        <NowContextWrapper>
            <SocketContextProvider>
                <NotifyController>
                    <CourierContextProvider>
                        <DateFilterContextWrapper>
                            <MapFilterContextWrapper>
                                <DeliveryContextWrapper>
                                    <OrderContextWrapper>
                                        <RabbitStackerProvider>
                                            <GlobalModal>
                                                <PageContainerView {...props} />
                                            </GlobalModal>
                                        </RabbitStackerProvider>
                                    </OrderContextWrapper>
                                </DeliveryContextWrapper>
                            </MapFilterContextWrapper>
                        </DateFilterContextWrapper>
                    </CourierContextProvider>
                </NotifyController>
            </SocketContextProvider>
        </NowContextWrapper>
    );
};

export default PageContainerController;
