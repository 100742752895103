export const MarkerWithLabel = (
    text: string,
    iconClass?: string,
    labelClass?: string
): HTMLElement => {
    const el = document.createElement("div");
    el.className = "marker-container";

    if (iconClass) {
        const icon = document.createElement("div");
        icon.className = iconClass;
        icon.style.width = `51px`;
        icon.style.height = `51px`;

        el.appendChild(icon);
    }

    const labelClasses: string[] = ["label"];
    if (labelClass) labelClasses.push(labelClass);
    var label = document.createElement("label");
    label.setAttribute("class", labelClasses.join(" "));
    label.innerHTML = text;

    el.appendChild(label);

    return el;
};
