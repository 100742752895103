import { useNow } from "../../../Contexts/Now.context";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getElapsedSeconds } from "../../../Utils/GetElapsedSeconds.util";
import { useMountWithTriggers } from "xa-generics";

export interface IElapsedTimeProps {
    date: Date | number;
    maxDelay: number;
    className: string;
    warningClassName: string;
}

const ElapsedTime: React.FC<IElapsedTimeProps> = (props) => {
    const now = useNow();
    const [node, setnode] = useState<JSX.Element | null>(null);
    const { t } = useTranslation();

    const JSXGetTimeSpan = (): JSX.Element => {
        if (!props.date) return <span className={props.className}>{t("unknown")}</span>;

        if (typeof props.date === "number") {
            return (
                <span className={props.className}>
                    {Math.round(props.date / 60)} {t("time_min")}
                </span>
            );
        }

        const seconds: number = getElapsedSeconds(props.date, now);

        if (seconds < 60)
            return (
                <span className={props.className}>
                    {seconds} {t("time_seconds")}
                </span>
            );

        const minutes: number = Math.round(seconds / 60);
        if (minutes >= props.maxDelay) {
            return (
                <span className={props.warningClassName}>
                    {minutes} {t("time_minutes")}
                </span>
            );
        }
        return (
            <span className={props.className}>
                {minutes} {t("time_minutes")}
            </span>
        );
    };

    const initTimeSpan = (): (() => void) => {
        let tmout: NodeJS.Timeout | undefined;
        if (!node) {
            tmout = setTimeout(() => {
                setnode(JSXGetTimeSpan());
            }, 500);
        } else {
            setnode(JSXGetTimeSpan());
        }

        return () => {
            if (tmout) clearTimeout(tmout);
        };
    };

    useMountWithTriggers(initTimeSpan, [now]);

    return <>{node || t("calculating")}</>;
};

export default ElapsedTime;
