import { map } from "lodash";
import { useState } from "react";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { TextInput } from "xa-inputs";
import { OrderModel } from "../../../Models/Order.model";
import { useCouriers } from "../../../Contexts/Courier.context";
import { CourierModel } from "../../../Models/Courier.model";
import { FloatingError } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IError, ILoading } from "xa-generics";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { courierNameFilter, swapString, textFilter } from "../../../Utils/TextFilter.util";
import ArchivedDeliveryElementView from "./ArchivedDelivery.element.view";
import DrawerHeader from "../../Drawer/View/DrawerHeader";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/ArchivedDeliveries.css";

export interface IArchivedDeliveryElementFunctions {
    revoke: (roundID: string) => void;
    reloadArchivedDeliveries: () => void;
    backToAddresses: (order: OrderModel) => void;
    printTurnOrders: (orderIds: string[]) => void;
    assignToOtherCourier: (order: OrderModel, courier: CourierModel) => void;
}

export interface IArchivedDeliveriesViewProps extends IArchivedDeliveryElementFunctions {
    archivedDeliveries: CourierTurnModel[];
    setTextFilter: (value: string) => void;
    resetError: () => void;
    textFilter: string;
    loading: ILoading;
    error: IError;
}

const ArchivedDeliveriesView: React.FC<IArchivedDeliveriesViewProps> = (
    props
): React.ReactElement => {
    const [counterFilter, setCounterFilter] = useState<string>("");
    const { restaurant } = useAccess();
    const { couriersById } = useCouriers();
    const { t } = useTranslation();

    const regex: RegExp = new RegExp(swapString(props.textFilter), "i");
    const filteredDeliveries = props.archivedDeliveries.filter((delivery) => {
        if (!props.textFilter && !counterFilter) return true;
        const courier = delivery.getCourier(couriersById);
        if (!courier) return false;
        if (props.textFilter && courierNameFilter(regex, courier)) return true;

        for (let order of delivery.orders) {
            if (counterFilter && `${order.counter}` === counterFilter) return true;
            if (props.textFilter && textFilter(regex, order)) return true;
        }
        return false;
    });

    return (
        <div className="delivery-view archived-deliveries-view">
            <DrawerHeader title={t("archive_delivery_tab_title")} />

            {(props.loading || restaurant.conf_conf_manual_turn_parse) && (
                <Spinner isAbsolute isLarge />
            )}
            <FloatingError error={props.error} resetError={props.resetError} />

            <div className="delivery-view__manual-dispatch">
                <div className="delivery-view__filter">
                    <TextInput
                        isNumeric
                        value={counterFilter}
                        id={"delivery-counter-filter"}
                        placeholder={t("counter_filter")}
                        className="delivery-view__filter--counter"
                        onChange={(e) => setCounterFilter(e.value)}
                    />
                    <TextInput
                        value={props.textFilter}
                        id={"delivery-text-filter"}
                        placeholder={t("order_text_filter")}
                        className="delivery-view__filter--text"
                        onChange={(e) => props.setTextFilter(e.value)}
                    />
                </div>
            </div>

            <div className="delivery-view__list-container">
                {filteredDeliveries.length ? (
                    map(filteredDeliveries, (archivedDelivery, index: number) => (
                        <ArchivedDeliveryElementView
                            reloadArchivedDeliveries={props.reloadArchivedDeliveries}
                            assignToOtherCourier={props.assignToOtherCourier}
                            key={`${index}-archived-delivery-element`}
                            printTurnOrders={props.printTurnOrders}
                            backToAddresses={props.backToAddresses}
                            counterFilter={Number(counterFilter)}
                            archivedDelivery={archivedDelivery}
                            revoke={props.revoke}
                            textFilter={regex}
                        />
                    ))
                ) : (
                    <div className="delivery-view__list-container--no-data no-data">
                        <span>{t("no_rounds_in_list")}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArchivedDeliveriesView;
