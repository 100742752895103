import OrdersView from "../View/Orders.view";
import OrderEditorController from "../../OrderEditor/Controller/OrderEditor.controller";
import AssignCourierController from "../../AssignCourier/Controller/AssignCourier.controller";
import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { useState, useContext } from "react";
import { IOrderStatusCount } from "../Interface/IOrderStatusCount.interface";
import { OrderStatusCount } from "../Static/StatusCount.static";
import { ModalContext } from "xa-modal";
import { PrintBlock } from "../../../Utils/PrintBlock.util";
import { OrderModel } from "../../../Models/Order.model";
import { useOrders } from "../../../Contexts/Orders.context";
import { cloneDeep } from "lodash";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { OrderDAO } from "../../../Api/DAO/Order.dao";
import { Statuses } from "../../../Static/Statuses.static";

export interface IOrdersProps {}

const Orders: React.FC<IOrdersProps> = (props): React.ReactElement => {
    const { restaurant, host } = useAccess();
    const modal = useContext(ModalContext);
    const orderContext = useOrders();
    const [statusCount, setStatusCount] = useState<IOrderStatusCount>(cloneDeep(OrderStatusCount));
    const [textFilter, setTextFilter] = useState<string>("");
    const [loading, setLoading] = useState<ILoading>(false);
    const [showCount, setShowCount] = useState<number>(15);
    const [error, setError] = useState<IError>(null);

    const countStatus = (): void => {
        let counter = cloneDeep(OrderStatusCount);

        for (const order of orderContext.list) {
            counter.all += 1;

            if (order.status === Statuses.in_progress) {
                counter.kitchen += 1;
                continue;
            }
            if (order.status === Statuses.done) {
                counter.done += 1;
                continue;
            }
            if (order.isLate) {
                counter.late += 1;
                continue;
            }
        }

        setStatusCount(counter);
    };

    useMountWithTriggers(countStatus, [orderContext.list]);

    const updateOrderElement = (
        updateMode: "UPDATE" | "REMOVE",
        order: OrderModel,
        identifier: keyof OrderModel,
        propertyName: keyof OrderModel,
        newValue: any = ""
    ): void => {
        const orderList: OrderModel[] = cloneDeep(orderContext.list);
        const orderIndex: number = orderList.findIndex(
            (o: OrderModel) => o[identifier] === order[identifier]
        );
        if (updateMode === "UPDATE") {
            orderList[orderIndex][propertyName] = newValue as never;
        } else {
            orderList.splice(orderIndex, 1);
        }
        orderContext.updateList(orderList);
    };

    const printBigBlock = (orderId: string): void => {
        const request = host?.is_cloud
            ? OrderDAO.getBlockPDF(restaurant, orderId)
            : OrderDAO.printBigBlock(restaurant, orderId);
        setLoading(
            request
                .then((pdfBlob) => PrintBlock(pdfBlob, orderId))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const editOrder = (order: OrderModel): void => {
        modal.setContent(<OrderEditorController order={order} />);
    };

    const assignCheckedOrders = (): void => {
        const list: OrderModel[] = [];
        for (const order of orderContext.list) {
            if (order.isCheckedToAssign) {
                list.push(order);
            }
        }
        modal.setContent(<AssignCourierController order={list} />);
    };

    const assignToCourier = (order: OrderModel): void => {
        modal.setContent(<AssignCourierController order={order} />);
    };

    return (
        <OrdersView
            increaseShowCount={() => setShowCount(showCount + 15)}
            assignCheckedOrders={assignCheckedOrders}
            loading={loading || orderContext.loading}
            updateOrderElement={updateOrderElement}
            error={error || orderContext.error}
            assignToCourier={assignToCourier}
            onChange={orderContext.onChange}
            printBigBlock={printBigBlock}
            setTextFilter={setTextFilter}
            statusCount={statusCount}
            form={orderContext.form}
            textFilter={textFilter}
            editOrder={editOrder}
            showCount={showCount}
        />
    );
};

export default Orders;
