import { IInfoboxControllerProps } from "../Controller/Infobox.controller";
import { FromatPhoneNumber } from "../../../Utils/FormatPhoneNumber.util";
import { Button, Checkbox } from "xa-inputs";
import { getAddressLayer } from "../../../Utils/GetAddress.util";
import { useTranslation } from "react-i18next";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { CourierModel } from "../../../Models/Courier.model";
import { useCouriers } from "../../../Contexts/Courier.context";
import { OrderModel } from "../../../Models/Order.model";
import { StoreCheck } from "../../Orders/Util/OrderChecks.util";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import TimeSpan from "../../UI/TimeSpan/TimeSpan.view";
import "./Less/Infobox.css";

export interface IInfoboxViewProps extends Omit<IInfoboxControllerProps, "order"> {
    assignToCourier: (order: OrderModel, courier?: CourierModel | null) => void;
    markForAssign: (order: OrderModel, value: boolean) => void;
    editOrder: (order: OrderModel) => void;
    order: OrderModel;
}

const InfoboxView: React.FC<IInfoboxViewProps> = ({ order, ...props }): React.ReactElement => {
    const { restaurant } = useAccess();
    const { parsePayment } = useDictionary();
    const { couriersById } = useCouriers();

    const courier = props.turn?.getCourier(couriersById);

    const { t } = useTranslation();
    return (
        <div className="infobox-view">
            <div className="infobox__title">{t("infobox_details")}</div>
            <div className="infobox__header">
                <div className="infobox__header--counter">
                    <span className={`circle icon ${order.status}`}></span> {order.counter}
                </div>
                <TimeSpan
                    id={order.id}
                    date={order.showAtAsDate}
                    maxDelay={restaurant.delay_time}
                    className="infobox__header--timer"
                    warningClassName="infobox__header--timer--warning"
                    elementIsLate={order.isLate}
                    onChangeLate={() => null} //This is handled in global state, no need for duplicate calls
                />
            </div>
            <div className="infobox__body">
                <div className="infobox__body--address">
                    <span>{t("city")}:</span> {getAddressLayer(order.city) || t("not_set")}
                    <br />
                    <span>{t("street")}:</span> {getAddressLayer(order.street) || t("not_set")}
                    <br />
                    <span>{t("house_number")}:</span>{" "}
                    {getAddressLayer(order.house_number, ".") || t("not_set")}
                    <br />
                    <span>{t("floor")}:</span> {getAddressLayer(order.floor, ".") || t("not_set")}
                    <br />
                    <span>{t("door")}:</span> {getAddressLayer(order.door, ".") || t("not_set")}
                    <br />
                    <span>{t("bell")}:</span> {getAddressLayer(order.bell) || t("not_set")}
                    <br />
                </div>
                <div className="infobox__body--details">
                    <div className="infobox__body--details--name">{order.name}</div>
                    <div className="infobox__body--details--phone">
                        {FromatPhoneNumber(order.tel)}
                    </div>
                    <div className="infobox__body--details--payment">
                        {parsePayment(order.payment_method)}
                    </div>
                    <div
                        className="infobox__body--details--edit-item"
                        onClick={() => props.editOrder(order)}
                    >
                        {t("order_editor_title")}
                    </div>
                </div>
            </div>
            <div className="infobox__footer">
                {props.turn ? (
                    <>
                        <div className="infobox__footer--name">{courier?.name || "-"}</div>
                        <div
                            className="infobox__footer--swap"
                            onClick={() => props.assignToCourier(order, courier)}
                        >
                            <span className="flaticon-switch"></span> {t("swap_courier")}
                        </div>
                        <div className="infobox__footer--quantities">
                            {order.index + 1}. {t("address")} ({t("qty_sum")}{" "}
                            {props.turn.all_order_qty} {t("qty_unit")})
                        </div>
                    </>
                ) : (
                    <>
                        <div className="infobox__footer--assign">
                            <Button onClick={() => props.assignToCourier(order)}>
                                {t("assign_to_courier")}
                            </Button>
                        </div>
                        <div className="infobox__footer--assign-mark">
                            <Checkbox
                                id={`order-${order.id}`}
                                value={order.isCheckedToAssign}
                                labelText={t("mark_for_assign")}
                                onChange={(e) => {
                                    props.markForAssign(order, e.value);
                                    StoreCheck(order.id, e.value);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default InfoboxView;
