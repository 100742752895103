import { IActiveTab, ITabTypes } from "../Interface/ITabs.type";
import { useTranslation } from "react-i18next";
import { IDrawerControllerProps } from "../Controller/Drawer.controller";
import DeliveryController from "../../Delivery/Controller/Delivery.controller";
import ArchivedDeliveries from "../../ArchivedDeliveries/Controller/ArchivedDeliveries.controller";
import ArchivedOrders from "../../ArchivedOrders/Controller/ArchivedOrders.controller";
import Orders from "../../Orders/Controller/Orders.controller";
import "./Less/Drawer.css";

export interface IDrawerViewProps extends IDrawerControllerProps {
    setActiveTab: (newValue: IActiveTab) => void;
    activeTab: IActiveTab;
}

const DrawerView: React.FC<IDrawerViewProps> = (props): React.ReactElement => {
    const getTab = (): JSX.Element | null => {
        switch (props.activeTab.type) {
            case "ADDRESSES":
                return <Orders />;
            case "DELIVERY-ROUNDS":
                return <DeliveryController />;
            case "ARCHIVED-ADDRESS":
                return <ArchivedOrders />;
            case "ARCHIVED-ROUND":
                return <ArchivedDeliveries />;
            default:
                return null;
        }
    };

    const getResult = (tab: ITabTypes): IActiveTab => {
        if (tab === props.activeTab.type) {
            return {
                type: tab,
                isOpened: !props.activeTab.isOpened
            };
        } else {
            //(tab !== props.activeTab.type)
            return {
                type: tab,
                isOpened: true
            };
        }
    };

    const { t } = useTranslation();

    let classNames: string = "drawer-view";
    if (!props.activeTab.isOpened) classNames += " drawer-view-closed";

    return (
        <div className={classNames}>
            <div className="container">
                <div className="drawer-view__controls">
                    <div className="control-container">
                        <div className="block">
                            <div
                                className="drawer-view__controls--navigator archive-tab"
                                onClick={() => props.setActiveTab(getResult("ARCHIVED-ADDRESS"))}
                            >
                                {t("archive_orders_tab_title")}
                            </div>
                            <div
                                className="drawer-view__controls--navigator archive-tab"
                                onClick={() => props.setActiveTab(getResult("ARCHIVED-ROUND"))}
                            >
                                {t("archive_delivery_tab_title")}
                            </div>
                        </div>
                        <div className="block">
                            <div
                                className="drawer-view__controls--navigator"
                                onClick={() => props.setActiveTab(getResult("ADDRESSES"))}
                            >
                                {t("addresses_tab_title")}
                            </div>
                            <div
                                className="drawer-view__controls--navigator"
                                onClick={() => props.setActiveTab(getResult("DELIVERY-ROUNDS"))}
                            >
                                {t("rounds_tab_title")}
                            </div>
                        </div>
                        <div className="block">
                            <div
                                className="drawer-view__controls--navigator dark-nav arrow-navigator"
                                onClick={() =>
                                    props.setActiveTab({
                                        type: props.activeTab.type,
                                        isOpened: !props.activeTab.isOpened
                                    })
                                }
                            >
                                <span className="flaticon-down-arrow flaticon" />
                            </div>
                        </div>
                    </div>
                </div>
                {props.activeTab.isOpened && getTab()}
            </div>
        </div>
    );
};

export default DrawerView;
