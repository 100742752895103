import { RootDAO } from "./Root.dao";
import { APIDATE } from "../../Static/DateFormats.static";
import { AXIOSNAMES } from "../../Static/AxiosInstanceNames.static";
import { IRoundData } from "../../Interfaces/IRoundData.interface";
import { CourierDAO } from "./Courier.dao";
import { ProductModel } from "../../Components/Print/Model/Product.model";
import { ITurnResponse } from "../../Components/Print/Interface/ITurnResponse.interface";
import { RestaurantModel } from "../../Models/Restaurant.model";
import { CourierTurnModel } from "../../Models/CourierTurn.model";
import { axios, IDynamicObject } from "xa-generics";
import { DetailedCourierTurnModel } from "../../Models/DetailedCourierTurn.model";
import moment from "moment";

export abstract class RoundDAO extends RootDAO {
    /**
     * ## Get delivery elements
     *
     * The list is sorted by courier name before it's returned!
     */
    public static async getDeliveryRounds(
        maxDelay: number,
        date: Date = new Date()
    ): Promise<CourierTurnModel[]> {
        const query: string = `date=${moment(date).format(
            APIDATE
        )}&status[]=created&status[]=on_road&status[]=go_back`;
        const url: string = `${this.restTurns}?${query}`;
        const request = await axios.getInstance().get<IRoundData[]>(url);
        return request.data.map((data: IRoundData) => new CourierTurnModel(data, maxDelay));
    }

    public static async startRound(roundID: string): Promise<void> {
        await axios.getInstance().patch<void>(`${this.restTurns}/${roundID}/start`);
        return;
    }

    public static async finishRound(roundID: string): Promise<void> {
        await axios.getInstance().patch<void>(`${this.restTurns}/${roundID}/finish`);
        return;
    }

    public static async changeAutoFill(roundID: string, autoFill: boolean = false): Promise<void> {
        await axios
            .getInstance()
            .patch<void>(`${this.restTurns}/${roundID}/auto-fill`, { auto_fill: autoFill ? 1 : 0 });
        return;
    }

    public static async closeRoundAsSuccessful(roundID: string): Promise<void> {
        await axios.getInstance().patch<void>(`${this.restTurns}/${roundID}/force-finish`);
        return;
    }

    public static async reorganiseSequence(roundID: string, data: number[]): Promise<void> {
        await axios
            .getInstance()
            .patch<void>(`${this.restTurns}/${roundID}/reordering`, { order_ids: data });
        return;
    }

    public static async optimizeSequence(roundID: string): Promise<void> {
        await axios
            .getInstance()
            .patch<void>(`${this.restTurns}/${roundID}/calculate`, { optimize: true });
        return;
    }

    public static async calculateSequence(roundID: string): Promise<void> {
        await axios.getInstance().patch<void>(`${this.restTurns}/${roundID}/calculate`);
        return;
    }

    public static async loadTurn(turnId: string): Promise<ITurnResponse> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.restTurns}/${turnId}`);

        const productsReq = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(`${this.restTurns}/${turnId}/products`);

        const products = productsReq.data.map((prod) => new ProductModel(prod));
        const turn = new DetailedCourierTurnModel(request.data);
        const courier = await CourierDAO.loadCourier(turn.courier_id);

        return {
            products,
            courier,
            turn
        };
    }

    public static async printTurnOrders(rest: RestaurantModel, orderIds: string[]): Promise<void> {
        for (let orderId of orderIds) {
            await axios
                .getInstance(AXIOSNAMES.PRINT)
                .put<void>(
                    `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/orders/${orderId}/print`
                );
        }
        return;
    }

    public static async getTurnPDFs(
        rest: RestaurantModel,
        orderIds: string[]
    ): Promise<{ blob: Blob; id: string }[]> {
        let blobs: { blob: Blob; id: string }[] = [];
        for (let orderId of orderIds) {
            const request = await axios
                .getInstance(AXIOSNAMES.PRINT)
                .get<Iterable<number>>(
                    `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/orders/${orderId}/pdf`,
                    {
                        responseType: "arraybuffer",
                        headers: { "content-type": "application/pdf" }
                    }
                );
            const u8Array = new Uint8Array(request.data);
            blobs.push({ blob: new Blob([u8Array], { type: "application/pdf" }), id: orderId });
        }
        return blobs;
    }

    public static async printTaxes(rest: RestaurantModel, orderIds: string[]): Promise<void> {
        for (let orderId of orderIds) {
            try {
                await axios
                    .getInstance(AXIOSNAMES.PRINT)
                    .put<void>(
                        `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/stats/tax-printer/orders/${orderId}/print`
                    );
            } catch (error) {
                console.error("Error printing taxes", error);
                continue;
            }
        }
        return;
    }

    public static async getArchivedDeliveries(
        date: Date = new Date()
    ): Promise<CourierTurnModel[]> {
        const url: string = `${this.restTurns}?status=finished&date=${moment(date).format(
            APIDATE
        )}`;
        const request = await axios.getInstance().get<IRoundData[]>(url);
        return request.data.map((data: IRoundData) => new CourierTurnModel(data));
    }

    public static async revoke(roundID: string): Promise<void> {
        await axios.getInstance().patch<void>(`${this.restTurns}/${roundID}/revoke`);
        return;
    }
}
