import { ILoading, IError, useDidMount } from "xa-generics";
import { DetailedCourierTurnModel } from "../../../Models/DetailedCourierTurn.model";
import { DetailedCourierModel } from "../../../Models/DetailedCourier.model";
import { ITurnResponse } from "../Interface/ITurnResponse.interface";
import { ProductModel } from "../Model/Product.model";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useState } from "react";
import { RoundDAO } from "../../../Api/DAO/CourierTurn.dao";
import { useMatch } from "react-router";
import PrintRoundView from "../View/PrintRound.view";

export type IPrintRoundProps = {};

const PrintRound: React.FC<IPrintRoundProps> = (props) => {
    const path = useMatch("/print/:round_id");
    const { restaurant } = useAccess();

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [courier, setCourier] = useState<DetailedCourierModel>(new DetailedCourierModel());
    const [turn, setTurn] = useState<DetailedCourierTurnModel>(new DetailedCourierTurnModel());

    const loadDelivery = (): void => {
        setLoading(
            RoundDAO.loadTurn(path!.params.round_id!)
                .then((data: ITurnResponse) => {
                    setProducts(data.products);
                    setCourier(data.courier);
                    setTurn(data.turn);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useDidMount(loadDelivery);

    return (
        <PrintRoundView
            {...props}
            conf_print_category_column_count={restaurant.conf_print_category_column_count}
            conf_print_product={restaurant.conf_print_product}
            products={products}
            courier={courier}
            loading={loading}
            error={error}
            turn={turn}
        />
    );
};

export default PrintRound;
