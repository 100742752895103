import { ModelConstructor, IDynamicObject } from "xa-generics";

export class HostModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as HostMData;
        ModelConstructor(data, this);

        //Ensures that urls don't end with "/"
        if (this.host.endsWith("/")) {
            this.host = this.host.substring(0, this.host.length - 1);
        }
        if (this.socket_url.endsWith("/")) {
            this.socket_url = this.socket_url.substring(0, this.socket_url.length - 1);
        }
    }
    host: string = "";
    new_pos: boolean = false;
    is_cloud: boolean = true;
    socket_url: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type HostMData = Partial<InstanceType<typeof HostModel>>;
