import NotifyView from "../View/Notify.view";
import { useContext, useState } from "react";
import { cloneDeep } from "lodash";
import { INotificationContext } from "../Interface/INotificationContext.interface";
import { Context, createContext } from "react";
import { INotification, INotificationSystem } from "../Interface/INotification.interface";

/**
 * ## NotifyContext
 */
const NotifyContext: Context<INotificationContext> = createContext<INotificationContext>({
    notifications: {},
    addNotification: null as any,
    removeNotification: null as any
});

NotifyContext.displayName = "NotifyContext";

export interface INotifyControllerProps {}

export const NotifyController: React.FC<INotifyControllerProps> = (props): React.ReactElement => {
    const [notifications, setNotifications] = useState<INotificationSystem>({});

    const addNotification = (notification: INotification): void => {
        let new_notify: INotificationSystem = cloneDeep(notifications);

        new_notify[notification.action] = notification;

        setNotifications(new_notify);
    };

    const removeNotification = (notification: INotification): void => {
        let new_notify: INotificationSystem = cloneDeep(notifications);

        if (new_notify[notification.action]) delete new_notify[notification.action];

        setNotifications(new_notify);
    };

    return (
        <NotifyContext.Provider
            value={{
                notifications,
                addNotification,
                removeNotification
            }}
        >
            <>
                <NotifyView {...props} />
                {props.children}
            </>
        </NotifyContext.Provider>
    );
};
export const useNotify = () => useContext(NotifyContext);
