import { Button } from "xa-inputs";
import { useContext } from "react";
import { ModalContext } from "xa-modal";
import { useTranslation } from "react-i18next";
import "./Less/ConfirmModal.css";

export interface IConfirmModalViewProps {
    textKey: string;
    onYes: () => void;
}

const ConfirmModalView: React.FC<IConfirmModalViewProps> = (props) => {
    const { t } = useTranslation();
    const modal = useContext(ModalContext);

    return (
        <div className="confirm-modal-view">
            <div className="confirm-modal-view__text">{t(props.textKey)}</div>
            <div className="confirm-modal-view__button-wrapper">
                <Button
                    onClick={() => {
                        props.onYes();
                        modal.setContent(null);
                    }}
                >
                    {t("modal_yes")}
                </Button>
                <Button onClick={() => modal.setContent(null)}>{t("modal_cancel")}</Button>
            </div>
        </div>
    );
};

export default ConfirmModalView;
