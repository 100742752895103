import { ICalculatedSummary, ISummary } from "../Interfaces/ICalculatedSummary.interface";
import { IPaymentMethodBlock } from "../Interfaces/IPaymentBlock.interface";
import { IDynamicObject } from "xa-generics";

/**
 * ## Summary calculator utility function
 *
 * You must pass the PaymentSummaryModel.payment_methods to this function.
 * The method will loop through the object which is consisting of payment methods,
 * each with a 'delivered/failed/in_progress' object, each of which has a
 * 'delivery_price/quantity/sum' property.
 *
 * These values are summed in the respectively related objects declared at the top of
 * the function and then returned in the form of 'ICalculatedSummary' interface.
 */
export const calculateSummary = (
    paymentMethods: IDynamicObject<IPaymentMethodBlock>
): ICalculatedSummary => {
    let all: ISummary = {
        quantity: 0,
        sum: 0,
        deliveryPrice: 0
    };
    let delivered: ISummary = {
        quantity: 0,
        sum: 0,
        deliveryPrice: 0
    };
    let failed: ISummary = {
        quantity: 0,
        sum: 0,
        deliveryPrice: 0
    };

    for (let method in paymentMethods) {
        if (paymentMethods[method].delivered) {
            // delivered quantities and moniz
            delivered.quantity += paymentMethods[method].delivered.quantity;
            delivered.sum += paymentMethods[method].delivered.sum;
            delivered.deliveryPrice += paymentMethods[method].delivered.delivery_price;

            // failed quantities and moniz
            failed.quantity += paymentMethods[method].failed.quantity;
            failed.sum += paymentMethods[method].failed.sum;
            failed.deliveryPrice += paymentMethods[method].failed.delivery_price;

            // summed all quantities and moniz
            all.quantity += paymentMethods[method].failed.quantity;
            all.quantity += paymentMethods[method].delivered.quantity;

            all.sum += paymentMethods[method].failed.sum;
            all.sum += paymentMethods[method].delivered.sum;

            all.deliveryPrice += paymentMethods[method].failed.delivery_price;
            all.deliveryPrice += paymentMethods[method].delivered.delivery_price;
        }
    }

    return {
        delivered,
        failed,
        all
    };
};
