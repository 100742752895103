import { FC } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { useDidMount } from "xa-generics";

interface IRedirectProps {
    to: string;
}

export const Redirect: FC<IRedirectProps> = (props) => {
    const navigateTo = useNavigate();
    const { pathname, hash, search } = useLocation();

    useDidMount(() => {
        navigateTo(props.to, {
            state: {
                from: {
                    pathname,
                    search,
                    hash
                }
            }
        });
    });

    return <div></div>;
};
