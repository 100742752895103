import { IDeliveryViewProps } from "./Delivery.view";
import { Button, TextInput } from "xa-inputs";
import { useTranslation } from "react-i18next";
import { ModalContext } from "xa-modal";
import { useContext } from "react";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import ConfirmModalView from "./ConfirmModal.view";
import "./Less/ManualDispatch.css";

export interface IDeliveryManualDispatchProps extends IDeliveryViewProps {
    setCounterFilter: (value: string) => void;
    counterFilter: string;
}

const DeliveryManualDispatch: React.FC<IDeliveryManualDispatchProps> = (props) => {
    const modal = useContext(ModalContext);
    const { restaurant } = useAccess();
    const { t } = useTranslation();

    if (!restaurant.conf_allow_manual_dispatch) {
        return (
            <div className="delivery-view__manual-dispatch">
                <div className="delivery-view__filter">
                    <TextInput
                        onChange={(e) => props.setCounterFilter(e.value)}
                        className="delivery-view__filter--counter"
                        placeholder={t("counter_filter")}
                        id={"delivery-counter-filter"}
                        value={props.counterFilter}
                        isNumeric
                    />
                    <TextInput
                        value={props.textFilter}
                        id={"delivery-text-filter"}
                        placeholder={t("order_text_filter")}
                        className="delivery-view__filter--text"
                        onChange={(e) => props.setTextFilter(e.value)}
                    />
                </div>
            </div>
        );
    }

    const schemaDate: string = restaurant.last_scheme_generated_at;
    let isManualParsing: boolean =
        props.center.conf_conf_manual_order_dispatch || props.center.conf_conf_manual_turn_parse
            ? true
            : false;

    return (
        <div className="delivery-view__manual-dispatch">
            <div className="button-wrapper">
                <div className="button-container">
                    <Button
                        disabled={isManualParsing || !props.turns.length ? true : false}
                        className="manual-parsing-button"
                        textButton
                        onClick={() =>
                            modal.setContent(
                                <ConfirmModalView
                                    textKey={"manual_parsing"}
                                    onYes={() => props.manualAutoDispatch("conf_manual_turn_parse")}
                                />,
                                t("modal_attention")
                            )
                        }
                    >
                        {t("btn_manual_parsing")}{" "}
                        <span className="flaticon-floppy-disk icon"></span>
                    </Button>
                    <Button
                        disabled={isManualParsing ? true : false}
                        className={"manual-dispatch-button"}
                        textButton
                        onClick={() =>
                            modal.setContent(
                                <ConfirmModalView
                                    textKey={"manual_dispatch"}
                                    onYes={() =>
                                        props.manualAutoDispatch("conf_manual_order_dispatch")
                                    }
                                />,
                                t("modal_attention")
                            )
                        }
                    >
                        {t("btn_manual_dispatch")}{" "}
                        <span className="flaticon-connection icon"></span>
                    </Button>
                </div>

                <div className="schema-date-container">
                    {schemaDate ? (
                        <span className="text">
                            {t("last_updated")}: {schemaDate}
                        </span>
                    ) : null}
                </div>
            </div>
            <div className="delivery-view__filter">
                <TextInput
                    onChange={(e) => props.setCounterFilter(e.value)}
                    className="delivery-view__filter--counter"
                    placeholder={t("counter_filter")}
                    id={"delivery-counter-filter"}
                    value={props.counterFilter}
                    isNumeric
                />
                <TextInput
                    value={props.textFilter}
                    id={"delivery-text-filter"}
                    placeholder={t("order_text_filter")}
                    className="delivery-view__filter--text"
                    onChange={(e) => props.setTextFilter(e.value)}
                />
            </div>
        </div>
    );
};

export default DeliveryManualDispatch;
