import { map } from "lodash";
import { INotification } from "../Interface/INotification.interface";
import { INotifyControllerProps, useNotify } from "../Context/Notify.context";
import "./Less/Notify.css";

export interface INotifyViewProps extends INotifyControllerProps {}

const NotifyView: React.FC<INotifyViewProps> = (props): React.ReactElement => {
    const notify = useNotify();

    return (
        <div className="notify-view">
            <div className="notify-view__list">
                {map(notify.notifications, (notification: INotification, index: number) => (
                    <div className="notify-view__element" key={`${index}-notification-element`}>
                        <span className="circle done" />
                        <div className="notify-view__element--text">{notification.displayText}</div>
                        <div
                            className="exit"
                            onClick={() => notify.removeNotification(notification)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotifyView;
