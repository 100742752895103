import { IError, ILoading, useMountWithTriggers } from "xa-generics";
import { useDateFilter } from "../../../Contexts/DateFilter.context";
import { ModalContext } from "xa-modal";
import { useStacker } from "../../../Contexts/RabbitStacker.context";
import { useContext } from "react";
import { OrderModel } from "../../../Models/Order.model";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { OrderDAO } from "../../../Api/DAO/Order.dao";
import ArchivedOrdersView from "../View/ArchivedOrders.view";
import OrderEditorController from "../../OrderEditor/Controller/OrderEditor.controller";

export interface IArchivedOrdersProps {}

const ArchivedOrders: React.FC<IArchivedOrdersProps> = (props): React.ReactElement => {
    const stack = useStacker();
    const modal = useContext(ModalContext);
    const { form } = useDateFilter();

    const [archivedOrders, setArchivedOrders] = useState<OrderModel[]>([]);
    const [loading, setLoading] = useState<ILoading>(null);
    const [error, setError] = useState<IError>(null);

    const loadArchivedOrders = (): void => {
        setLoading(
            OrderDAO.getArchivedOrders(form.date.value as Date)
                .then((archivedOrdersList: OrderModel[]) => {
                    setArchivedOrders(archivedOrdersList);
                    if (error) setError(null);
                })
                .catch((error: IError) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(null);
                })
        );
    };

    useMountWithTriggers(loadArchivedOrders, [form]);

    const revokeOrder = (order: OrderModel): void => {
        setLoading(
            OrderDAO.revoke(order.id)
                .then(() => {
                    loadArchivedOrders();
                    stack.reloadOrders();
                })
                .catch((error: IError) => setError(error))
        );
    };

    const markOrder = (order: OrderModel, value: boolean): void => {
        let orderList = cloneDeep(archivedOrders);
        for (let orderItem of orderList) {
            if (order.id === orderItem.id) {
                orderItem.isCheckedToAssign = value;
            }
        }
        setArchivedOrders(orderList);
    };

    const markAllOrder = (): void => {
        let orderList = cloneDeep(archivedOrders);
        for (let orderItem of orderList) {
            orderItem.isCheckedToAssign = true;
        }
        setArchivedOrders(orderList);
    };

    const revokerMarkedOrders = (): void => {
        setLoading(
            OrderDAO.revokeList(archivedOrders)
                .then(() => {
                    loadArchivedOrders();
                    stack.reloadOrders();
                })
                .catch((error: IError) => setError(error))
        );
    };

    const editOrder = (order: OrderModel) => {
        modal.setContent(
            <OrderEditorController
                order={order}
                returnNewOrder={() => {
                    modal.setContent(null);
                    loadArchivedOrders();
                }}
            />
        );
    };

    return (
        <ArchivedOrdersView
            reloadArchivedOrders={loadArchivedOrders}
            revokerMarkedOrders={revokerMarkedOrders}
            resetError={() => setError(null)}
            archivedOrders={archivedOrders}
            markAllOrder={markAllOrder}
            revokeOrder={revokeOrder}
            editOrder={editOrder}
            markOrder={markOrder}
            loading={loading}
            error={error}
        />
    );
};

export default ArchivedOrders;
