import { Img } from "xa-image";
import { inputLang } from "../../../Lang/Inputs.lang";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useCouriers } from "../../../Contexts/Courier.context";
import { ModalContext } from "xa-modal";
import { useMapFilter } from "../../../Contexts/MapFilter.context";
import { useDictionary } from "../../Dictionary/Context/Dictionary.context";
import { useDateFilter } from "../../../Contexts/DateFilter.context";
import { useTranslation } from "react-i18next";
import { setDefaultLocale } from "react-datepicker";
import { ISelectOption, axios } from "xa-generics";
import { useContext, useState } from "react";
import { Button, Select, Datepicker, XAInputDefaults } from "xa-inputs";
import SummaryController from "../../Summary/Controller/Summary.controller";
import ConfigController from "../../Config/Controller/Config.controller";
import ProductCount from "../../ProductCount/Controller/ProductCount.controller";
import Couriers from "../../Couriers/Controller/Couriers.controller";
import Spinner from "../../UI/Spinner/Spinner";
import Cookie from "js-cookie";
import Signs from "../../Signs/View/SignsView";
import Logo from "../../../Assets/onemin_logo.png";
import huHU from "date-fns/locale/hu";
import "./Less/Navbar.css";

export interface IToolbarProps {}

const Navbar: React.FC<IToolbarProps> = (props): React.ReactElement | null => {
    const [isOpened, setisOpened] = useState<boolean>(false);
    const [showSummary, setshowSummary] = useState<boolean>(false);
    const [showProductCount, setshowProductCount] = useState<boolean>(false);

    const { restaurant } = useAccess();
    const dictionary = useDictionary();
    const courierContext = useCouriers();
    const mapFilter = useMapFilter();
    const dateContext = useDateFilter();
    const modal = useContext(ModalContext);
    const access = useAccess();

    const { t, i18n } = useTranslation();

    const changeLanguage = (lang: ISelectOption): void => {
        const cleanValue = lang.id.slice(0, 2);
        i18n.changeLanguage(cleanValue);
        Cookie.set("lang", cleanValue, {
            expires: (function () {
                let date = new Date();
                date.setFullYear(date.getFullYear() + 10);
                return date;
            })()
        });
        setDefaultLocale(lang.id);
        XAInputDefaults.Select.noOption = inputLang[cleanValue].noOption;
        XAInputDefaults.Select.loadingMessage = inputLang[cleanValue].loadingMessage;
        XAInputDefaults.Select.placeholder = inputLang[cleanValue].placeholder;
        XAInputDefaults.Select.searching = inputLang[cleanValue].searching;
        axios.setLanguage(cleanValue);
        dictionary.loadDictionary();
    };

    if (!access.accessModel) return null;

    const styles: string[] = ["navbar"];

    if (isOpened) {
        styles.push("navbar-opened");
    }

    return (
        <>
            <div className={styles.join(" ")}>
                <div className="navbar-container">
                    <div className="navbar__logo">
                        <span
                            onClick={() => modal.setContent(<Signs />)}
                            className="flaticon-information navbar__logo--icon"
                            title={`${t("help")}`}
                        ></span>
                        <a
                            href="https://www.oneminorder.com"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <Img
                                src={Logo}
                                alt="Logo"
                                className="navbar__logo--image"
                                loader={<Spinner isTiny />}
                                onClick={() => setisOpened(false)}
                            />
                        </a>
                    </div>
                    <div className="navbar__filters">
                        <div className="navbar__text">{t("filter_map")}:</div>
                        <Select
                            {...mapFilter.form.courierFilter}
                            options={mapFilter.form.courierFilter.options.concat(
                                courierContext.couriers
                            )}
                            placeholder={t("courier_select_placeholder")}
                            onChange={mapFilter.onChange}
                            isClearable
                            isMulti
                        />
                        <Select
                            {...mapFilter.form.orderStatus}
                            options={dictionary.status.statusAsArr}
                            placeholder={t("order_state_select_placeholder")}
                            onChange={mapFilter.onChange}
                            isClearable
                            isMulti
                        />
                        <Datepicker
                            {...dateContext.form.date}
                            onChange={dateContext.onChange}
                            className="icon-datepicker"
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent"
                                }
                            }}
                            locale={huHU}
                        />
                    </div>
                    <div
                        className="navbar__buttons"
                        onClick={(e) => {
                            e.stopPropagation();
                            setisOpened(false);
                        }}
                    >
                        <Button
                            className="slide-in-nav"
                            onClick={(e) => {
                                e.stopPropagation();
                                setshowSummary(true);
                            }}
                        >
                            {t("summary_page")}
                        </Button>
                        {restaurant.allow_product_sync && (
                            <Button
                                className="slide-in-nav"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setshowProductCount(true);
                                }}
                            >
                                {t("show_product_count")}
                            </Button>
                        )}
                        <Button
                            className="slide-in-nav"
                            onClick={(e) => {
                                e.stopPropagation();
                                modal.setContent(<Couriers />);
                                setisOpened(false);
                            }}
                        >
                            {t("courier_handler_button")}
                        </Button>

                        <Button
                            className="slide-in-nav"
                            onClick={(e) => {
                                e.stopPropagation();
                                modal.setContent(<ConfigController />, t("config"));
                                setisOpened(false);
                            }}
                        >
                            {t("config")}
                        </Button>

                        <div
                            className="select-container slide-in-nav"
                            style={{ animationDelay: "0.3s" }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Select
                                className="language-select"
                                id="language-select"
                                value={{
                                    id: i18n.language,
                                    name: i18n.language.substring(0, 2).toUpperCase()
                                }}
                                options={[
                                    { id: "hu-HU", name: "HU" },
                                    { id: "en-GB", name: "EN" }
                                ]}
                                onChange={(e) => changeLanguage(e.value)}
                            />
                        </div>

                        {access.accessModel && (
                            <Button
                                altColor
                                className="slide-in-nav"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    localStorage.removeItem("check_list");
                                    setisOpened(false);
                                    access.logout();
                                }}
                            >
                                {t("logout")}
                            </Button>
                        )}
                    </div>
                    <div
                        className="threads"
                        onClick={(e) => {
                            e.stopPropagation();
                            setisOpened(!isOpened);
                            setshowSummary(false);
                        }}
                    >
                        <div className="thread"></div>
                        <div className="thread"></div>
                        <div className="thread"></div>
                    </div>
                </div>
            </div>
            {showSummary && (
                <SummaryController
                    closeSummary={() => {
                        setshowSummary(false);
                        setisOpened(false);
                    }}
                />
            )}
            {showProductCount && (
                <ProductCount
                    close={() => {
                        setshowProductCount(false);
                        setisOpened(false);
                    }}
                />
            )}
        </>
    );
};

export default Navbar;
