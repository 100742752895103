import { cloneDeep } from "lodash";
import { IDynamicObject } from "xa-generics";

export const ChangeHandler = <T extends IDynamicObject<any>>(
    form: T,
    key: keyof T | string,
    value: any
): T => {
    const clonedForm = cloneDeep(form);
    clonedForm[key].value = value;
    return clonedForm;
};
