import { axios } from "xa-generics";
import { RootDAO } from "./Root.dao";
import { APIDATE } from "../../Static/DateFormats.static";
import { isEmpty } from "lodash";
import { OrderModel } from "../../Models/Order.model";
import { AXIOSNAMES } from "./../../Static/AxiosInstanceNames.static";
import { IDynamicObject } from "xa-generics";
import { IAttachRequest } from "../../Components/Orders/Interface/IAttachRequest.type";
import { RestaurantModel } from "../../Models/Restaurant.model";
import { CourierTurnModel } from "../../Models/CourierTurn.model";
import moment from "moment";

export abstract class OrderDAO extends RootDAO {
    public static async getOrders(
        maxDelay: number,
        date: Date = new Date()
    ): Promise<OrderModel[]> {
        const url: string = `${this.restOrders}?date=${moment(date).format(APIDATE)}&storno=false`;
        const request = await axios.getInstance().get<IDynamicObject[]>(url);
        return request.data.map((order, index) => new OrderModel(order, maxDelay, index));
    }

    public static async assignToCourier(
        order_id: string,
        courierId: string
    ): Promise<CourierTurnModel> {
        const request = await axios
            .getInstance()
            .patch<IDynamicObject>(`${this.restOrders}/${order_id}/to-courier/${courierId}`);
        return new CourierTurnModel(request.data);
    }

    public static async ordersToCourier(courierId: string): Promise<void> {
        const ordersByKey: null | { [key: string]: number } = JSON.parse(
            localStorage.getItem("check_list") || "{}"
        );
        if (isEmpty(ordersByKey)) return;
        let attachRequests: IAttachRequest[] = [];
        for (const key in ordersByKey) {
            attachRequests.push({ id: `${key}`, index: Number(ordersByKey[key]) });
        }
        attachRequests = attachRequests.sort((e1, e2) => e1.index - e2.index);

        for (const order of attachRequests) {
            await axios
                .getInstance()
                .patch(`${this.restOrders}/${order.id}/to-courier/${courierId}`);
        }

        return;
    }

    public static async updateOrder(data: string, order_id: string): Promise<void> {
        const request = await axios
            .getInstance()
            .patch<void>(`${this.restOrders}/${order_id}`, data);
        return request.data;
    }

    public static async cancelOrder(order_id: string): Promise<void> {
        const request = await axios
            .getInstance()
            .patch<void>(`${this.restOrders}/${order_id}/cancel`);
        return request.data;
    }

    public static async revoke(order_id: string): Promise<void> {
        const request = await axios
            .getInstance()
            .patch<void>(`${this.restOrders}/${order_id}/revoke`);
        return request.data;
    }

    public static async revokeList(orders: OrderModel[]): Promise<void> {
        const requests: Promise<any>[] = [];

        for (const order of orders) {
            if (order.isCheckedToAssign) {
                requests.push(
                    axios.getInstance().patch<void>(`${this.restOrders}/${order.id}/revoke`)
                );
            }
        }

        await Promise.all(requests);

        return;
    }

    public static async getArchivedOrders(date: Date = new Date()): Promise<OrderModel[]> {
        const url: string = `${this.restOrders}?date=${moment(date).format(APIDATE)}&storno=true`;
        const request = await axios.getInstance().get<IDynamicObject[]>(url);
        return request.data.map((order: IDynamicObject) => new OrderModel(order));
    }

    public static async printBigBlock(rest: RestaurantModel, orderId: string): Promise<void> {
        await axios
            .getInstance(AXIOSNAMES.PRINT)
            .put<void>(
                `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/orders/${orderId}/print`
            );
        return;
    }

    public static async getBlockPDF(rest: RestaurantModel, orderId: string): Promise<Blob> {
        const request = await axios
            .getInstance(AXIOSNAMES.PRINT)
            .get<Iterable<number>>(
                `/api/v3/admin/partners/${rest.partner_id}/restaurants/${rest.id}/orders/${orderId}/pdf`,
                {
                    responseType: "arraybuffer",
                    headers: { "content-type": "application/pdf" }
                }
            );
        const u8array = new Uint8Array(request.data);
        return new Blob([u8array], { type: "application/pdf" });
    }
}
