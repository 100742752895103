import { Img } from "xa-image";
import { Button } from "xa-inputs";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { IAuthProps } from "../Controller/Auth.controller";
import { IInputChange } from "xa-inputs";
import { useTranslation } from "react-i18next";
import { IDynamicObject } from "xa-generics";
import { ErrorHandler } from "xa-error-with-lang";
import { useLocation } from "react-router-dom";
import { ILoginForm } from "../Interface/ILoginForm.interface";
import { TextInput } from "xa-inputs";
import { Redirect } from "../../UI/Redirect/Redirect.view";
import Background from "../../../Assets/bg_ok.png";
import Spinner from "../../UI/Spinner/Spinner";
import Logo from "../../../Assets/onemin_logo.png";
import "./Less/Auth.css";

export type IAuthViewProps = IAuthProps & {
    onSubmit: (e: React.FormEvent) => void;
    onChange: (e: IInputChange) => void;
    form: ILoginForm;
};

const AuthView: React.FC<IAuthViewProps> = (props): React.ReactElement => {
    const { accessModel, restaurant, authError, authLoading } = useAccess();
    const { state } = useLocation();

    const { t } = useTranslation();

    const redirectedFrom = (state as IDynamicObject<any>)?.from || "";

    if (accessModel || restaurant) {
        return <Redirect to={redirectedFrom?.pathname || "/"} />;
    }

    return (
        <div className="login-view">
            <div className={"page-wrapper"}>
                <div className="login-view__container">
                    <Img
                        src={Logo}
                        alt={"ONEMINORDER"}
                        isVerticalResponsive
                        className="login-view__container--logo"
                    />
                    <div className="login-page">
                        <form className="login-page__form" onSubmit={props.onSubmit}>
                            <TextInput
                                value={props.form.email}
                                id={"email"}
                                type="email"
                                onChange={props.onChange}
                                labelText={t("email")}
                            />
                            <TextInput
                                value={props.form.password}
                                id={"password"}
                                type="password"
                                labelText={t("password")}
                                onChange={props.onChange}
                            />
                            <Button isWide hasContainer type="submit">
                                {t("login")}
                            </Button>
                        </form>
                        {authLoading ? <Spinner isTiny /> : null}
                        <ErrorHandler error={authError} />
                    </div>
                </div>
            </div>
            <Img src={Background} alt="Backgound image" className="login-view__background" />
        </div>
    );
};

export default AuthView;
