import { useTranslation } from "react-i18next";
import { Button } from "xa-inputs";
import "./Less/DeleteModal.css";

export interface IDeleteModalProps {
    yesFunction: () => void;
    noFunction: () => void;
}

const DeleteModal: React.FC<IDeleteModalProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="delete-modal">
            <div className="delete-modal__title">{t("modal_attention")}</div>
            <div className="delete-modal__text">{t("modal_delete_text")}</div>
            <div className="delete-modal__button-container">
                <Button onClick={props.yesFunction}>{t("modal_yes")}</Button>
                <Button onClick={props.noFunction}>{t("modal_cancel")}</Button>
            </div>
        </div>
    );
};

export default DeleteModal;
