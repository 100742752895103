import { IDynamicObject } from "xa-generics";
import { IMapFilterForm } from "../../../Interfaces/IMapFilterForm.interface";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { OrderModel } from "../../../Models/Order.model";
import { OrderMarkerView } from "./OrderMarkerView.util";
import { CourierModel } from "../../../Models/Courier.model";

export const MapOrderList = (
    form: IMapFilterForm,
    couriersById: IDynamicObject<CourierModel>,
    onClick: (order: OrderModel, swappable: boolean, delivery?: CourierTurnModel) => void,
    orders?: OrderModel[],
    delivery?: CourierTurnModel
): HTMLElement[] => {
    let nodeArray: HTMLElement[] = [];

    const list = delivery?.orders || orders;
    //This is looped backwards to reverse the marker z-index order.
    const listLength = list!.length;
    for (let i = 0; i < listLength; i++) {
        const order = list![i];
        if (form.orderStatus.value instanceof Array && form.orderStatus.value.length > 0) {
            for (let filter of form.orderStatus.value) {
                if (filter.id === order.status) {
                    nodeArray.push(OrderMarkerView(order, couriersById, onClick, delivery));
                    break;
                }
            }
        } else {
            nodeArray.push(OrderMarkerView(order, couriersById, onClick, delivery));
        }
    }

    return nodeArray;
};
