import { ISummaryViewProps } from "./Summary.view";
import { useTranslation } from "react-i18next";
import { CourierModel } from "../../../Models/Courier.model";
import { useCouriers } from "../../../Contexts/Courier.context";
import { map } from "lodash";
import "./Less/SummarySidebar.css";

export interface ISummarySidebarViewProps extends ISummaryViewProps {}

const SummarySidebarView: React.FC<ISummarySidebarViewProps> = (props) => {
    const { couriers } = useCouriers();

    const { t } = useTranslation();

    return (
        <div className="summary-view__body-sidebar summary-sidebar">
            {map(couriers, (courier: CourierModel, index: number) => {
                let classNames = "summary-sidebar__tab";
                if (!courier.is_available) classNames += " unavailable-courier";
                if (props.selectedCourier?.id === courier.id) classNames += " active-tab";
                return (
                    <div
                        key={`${index}-courier-summary-tab`}
                        className={classNames}
                        title={courier.is_available ? undefined : t("inactive_courier")}
                        onClick={() => props.setSelectedCourier(courier)}
                    >
                        {courier.name}
                    </div>
                );
            })}
        </div>
    );
};

export default SummarySidebarView;
