import { axios } from "xa-generics";
import { RootDAO } from "./Root.dao";
import { CourierModel } from "../../Models/Courier.model";
import { IDynamicObject } from "xa-generics";
import { DetailedCourierModel } from "../../Models/DetailedCourier.model";
export abstract class CourierDAO extends RootDAO {
    private static getUrl(couriedId: string | number): string {
        return `${this.restCouriers}/${couriedId}`;
    }

    public static async loadCouriers(): Promise<CourierModel[]> {
        const request = await axios.getInstance().get<IDynamicObject<any>[]>(this.restCouriers);
        return request.data.map((item: IDynamicObject) => new CourierModel(item));
    }

    public static async setAvailability(courierId: string, value: boolean): Promise<void> {
        await axios.getInstance().patch<void>(this.getUrl(courierId), { is_available: value });
        return;
    }

    public static async loadCourier(courierId: string): Promise<DetailedCourierModel> {
        const request = await axios.getInstance().get<IDynamicObject<any>>(this.getUrl(courierId));
        return new DetailedCourierModel(request.data);
    }
}
