import { cloneDeep } from "lodash";

export const StoreCheck = (id: string, value: boolean): void => {
    const prev: string | null = localStorage.getItem("check_list");
    const data = prev ? cloneDeep(JSON.parse(prev)) : {};
    const datAsNumArr: number[] = Object.values(data);
    if (datAsNumArr.length === 0) datAsNumArr.push(0);
    const max: number = Math.max(...datAsNumArr) + 1;
    if (value) data[id] = max;
    else delete data[id];
    localStorage.setItem("check_list", JSON.stringify(data));
};
