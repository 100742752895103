import { ISocketCourierCoordList } from "../../../Interfaces/ISocketTask.interface";
import { ICoords, IMapCoords } from "../../../Interfaces/ICoords.interface";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { IDynamicObject } from "xa-generics";
import { IMapFilterForm } from "../../../Interfaces/IMapFilterForm.interface";
import { CourierModel } from "../../../Models/Courier.model";
import { MapOrderList } from "./MapOrderList.util";
import { OrderModel } from "../../../Models/Order.model";
import { Statuses } from "../../../Static/Statuses.static";
import { IMapDelivery } from "../Interface/IMapDelivery.interface";

export const DeliveryMarkerView = (
    showOrders: boolean,
    delivery: CourierTurnModel,
    lastKnownCoords: ISocketCourierCoordList,
    couriersById: IDynamicObject<CourierModel>,
    form: IMapFilterForm,
    onClick: (delivery: CourierTurnModel) => void,
    onOrderClick: (order: OrderModel, swappable: boolean, delivery?: CourierTurnModel) => void
): IMapDelivery => {
    const getCoordinates = (
        courierId: string,
        courierCoords: ICoords | null,
        coordList: ISocketCourierCoordList
    ): IMapCoords | null => {
        let lat: number, lng: number;

        if (courierCoords) {
            lat = courierCoords.coord_lat;
            lng = courierCoords.coord_lng;
        } else if (coordList[courierId]) {
            lat = coordList[courierId].coord_lat;
            lng = coordList[courierId].coord_lng;
        } else return null;

        return {
            lat: lat,
            lng: lng
        };
    };

    const roundCoords: IMapCoords | null = getCoordinates(
        delivery.courier_id,
        delivery.couierCoords,
        lastKnownCoords
    );

    let classes: string[] = ["map__label"];

    switch (delivery.status) {
        case Statuses.deliveryStatuses.go_back:
            classes.push(`${Statuses.deliveryStatuses.go_back}`);
            break;
        case Statuses.deliveryStatuses.on_road:
            classes.push(`${Statuses.deliveryStatuses.on_road}`);
            break;
        case Statuses.deliveryStatuses.created:
        default:
            classes.push("not-occupied");
            break;
    }

    const element = document.createElement("div");
    element.className = "marker-container";

    element.addEventListener("click", () => onClick(delivery));

    const icon = document.createElement("div");
    icon.className = `${delivery.status}-icon`;
    icon.style.width = `51px`;
    icon.style.height = `51px`;
    element.appendChild(icon);

    const label = document.createElement("label");
    label.className = classes.join(" ");
    label.innerHTML = delivery.getCourier(couriersById)?.name || "-";
    element.appendChild(label);

    const orderElements: HTMLElement[] = MapOrderList(
        form,
        couriersById,
        onOrderClick,
        undefined,
        delivery
    );

    return {
        element,
        orderElements: { elements: showOrders ? orderElements : [], orders: delivery.orders },
        roundCoords
    };
};
