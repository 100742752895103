import { ModelConstructor, IDynamicObject } from "xa-generics";
import { IFiscatStatusType } from "../Interfaces/IFiscatStatus.type";

export class TaxPrinterModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TaxPrinterMData;
        ModelConstructor(data, this);
    }

    has_tax_printer: boolean = false;
    fiscat_status: IFiscatStatusType = "";
}

//If you add public methods, you may want to replace the empty string with them!
type TaxPrinterMData = Partial<InstanceType<typeof TaxPrinterModel>>;
