import { useState } from "react";
import { IActiveTab } from "../Interface/ITabs.type";
import DrawerView from "../View/Drawer.view";

export interface IDrawerControllerProps {}

const DrawerController: React.FC<IDrawerControllerProps> = (props): React.ReactElement => {
    const [activeTab, setActiveTab] = useState<IActiveTab>({
        type: "ADDRESSES",
        isOpened: true
    });
    return <DrawerView {...props} activeTab={activeTab} setActiveTab={setActiveTab} />;
};

export default DrawerController;
