import { CourierSummaryModel } from "../Model/CourierSummary.model";
import { ICalculatedSummary } from "../Interfaces/ICalculatedSummary.interface";
import { ISummaryViewProps } from "./Summary.view";
import { calculateSummary } from "../Util/CalculateSummary.util";
import { useTranslation } from "react-i18next";
import { DATETIMEFORMAT } from "../../../Static/DateFormats.static";
import { formatCurrency } from "../../../Utils/Currency.util";
import { Button } from "xa-inputs";
import moment from "moment";
import "./Less/SumaryData.css";

export interface ISummaryDataViewProps extends ISummaryViewProps {}

const SummaryDataView: React.FC<ISummaryDataViewProps> = (props) => {
    const { t } = useTranslation();

    const courierData: CourierSummaryModel | undefined = props.selectedCourier
        ? props.courierSummaries[props.selectedCourier.id]
        : undefined;

    const summary: ICalculatedSummary = calculateSummary(
        courierData ? courierData.payment_methods : props.centerSummary.payment_methods
    );

    const paymentNodes: JSX.Element[] = [];

    const paymentTypes = courierData
        ? courierData.payment_types
        : props.centerSummary.payment_types;

    for (let method in paymentTypes) {
        if (method === "cash") continue;
        paymentNodes.push(
            <div className="summary-data__list--element" key={`${method}-summary-payment-method`}>
                <div className="sum-label">{t(method)}</div>
                <div className="sum-value">
                    {formatCurrency(paymentTypes[method].delivered.sum)}
                </div>
            </div>
        );
    }

    return (
        <div className="summary-data" id={"summaryprint"}>
            <h1 className="summary-data__title">
                {props.selectedCourier?.name || "-"}
                {props.selectedCourier && (
                    <Button textButton onClick={() => window.print()}>
                        {t("print")}
                        <span className="flaticon-printer icon"></span>
                    </Button>
                )}

                {props.selectedCourier && (
                    <span className="date">
                        {moment(props.summaryForm.from.value).format(DATETIMEFORMAT)}
                        {" - "}
                        {moment(props.summaryForm.till.value).format(DATETIMEFORMAT)}
                    </span>
                )}
            </h1>
            <div className="summary-data__list">
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_all_address")}</div>
                    <div className="sum-value">{summary.all.quantity}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_succ_addr")}</div>
                    <div className="sum-value">{summary.delivered.quantity}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_unsucc_addr")}</div>
                    <div className="sum-value">{summary.failed.quantity}</div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_delivery_price")}</div>
                    <div className="sum-value">
                        {formatCurrency(summary.delivered.deliveryPrice)}
                    </div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_distance")}</div>
                    <div className="sum-value">
                        {courierData?.distance_km || 0} {t("distance_unit")}
                    </div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_calc_km")}</div>
                    <div className="sum-value">
                        {formatCurrency(courierData?.distance_price || 0)}
                    </div>
                </div>
                <div className="summary-data__list--element">
                    <div className="sum-label">{t("summary_income")}</div>
                    <div className="sum-value">{formatCurrency(summary.delivered.sum)}</div>
                </div>
                <div className="summary-data__list--element summary-strong add-space">
                    <div className="sum-label">{t("summary_cash")}</div>

                    <div className="sum-value">
                        {formatCurrency(paymentTypes.cash?.delivered.sum || "0")}
                    </div>
                </div>

                {paymentNodes}
            </div>
        </div>
    );
};

export default SummaryDataView;
