import { AxiosPromise, AxiosResponse } from "axios";
import { axios, IDynamicObject, xlsxToDownload } from "xa-generics";
import { IPaymentMethodBlock } from "../Interfaces/IPaymentBlock.interface";
import { PaymentSummaryModel } from "../Model/PaymentSummary.model";
import { CourierSummaryModel } from "../Model/CourierSummary.model";
import { ICenterSummary } from "../Interfaces/ICenterSummary.interface";
import { RootDAO } from "../../../Api/DAO/Root.dao";
import moment from "moment";
import { DATEFORMAT } from "../../../Static/DateFormats.static";

export default abstract class SummaryDAO extends RootDAO {
    public static async getRestSummary(
        from: string,
        till: string,
        pin: string
    ): Promise<PaymentSummaryModel> {
        let url = `${this.rest}/summary?from=${from}&till=${till}`;
        if (pin) url += `&pin=${pin}`;

        const request: AxiosPromise<IDynamicObject<IPaymentMethodBlock>> = axios
            .getInstance()
            .get(url);
        const response: AxiosResponse<IDynamicObject<IPaymentMethodBlock>> = await request;
        return new PaymentSummaryModel(response.data);
    }

    public static async getCourierSummary(
        courierID: string,
        from: string,
        till: string,
        pin: string
    ): Promise<CourierSummaryModel> {
        let url = `${this.restCouriers}/${courierID}/summary?from=${from}&till=${till}`;
        if (pin) url += `&pin=${pin}`;

        const request: AxiosPromise<ICenterSummary> = axios.getInstance().get(url);
        const response: AxiosResponse<ICenterSummary> = await request;
        return new CourierSummaryModel(response.data);
    }

    public static async exportSummary(
        courierID: string,
        from: string,
        till: string,
        pin: string
    ): Promise<void> {
        let url = `${this.restCouriers}/${courierID}/export?from=${from}&till=${till}`;
        if (pin) url += `&pin=${pin}`;

        const dateString: string = moment(from, moment.ISO_8601).format(DATEFORMAT);

        const request: AxiosPromise<Blob> = axios.getInstance().get(url, {
            responseType: "blob"
        });
        const response: AxiosResponse<Blob> = await request;
        const downloadName: string = `${dateString.replace(/\//g, "-")}_courier_${courierID}.xlsx`;
        xlsxToDownload(response.data, downloadName);
        return;
    }
}
