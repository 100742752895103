import { IOrdersViewProps } from "./Orders.view";
import { useTranslation } from "react-i18next";
import { Radio } from "xa-inputs";

export interface IOrderFilterViewProps extends IOrdersViewProps {}

const OrderFilterView: React.FC<IOrderFilterViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="orders-view__filter-view">
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.all}
                onChange={props.onChange}
                className="radio__all--other"
            >
                <>
                    {t("order_filter_r1")} ({props.statusCount.all})
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.done}
                onChange={props.onChange}
                className="radio__done"
            >
                <>
                    {t("order_filter_r2")} ({props.statusCount.done})
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.kitchen}
                onChange={props.onChange}
                className="radio__not-occupied"
            >
                <>
                    {t("order_filter_r3")} ({props.statusCount.kitchen})
                </>
            </Radio>
            <Radio
                {...props.form.filters}
                radioID={props.form.filters.objRadioIDS.late}
                onChange={props.onChange}
                className="radio__running-late"
            >
                <>
                    {t("order_filter_r4")} ({props.statusCount.late})
                </>
            </Radio>
        </div>
    );
};

export default OrderFilterView;
