import { IDeliveryControllerProps } from "../Controller/Delivery.controller";
import { textFilter, swapString } from "../../../Utils/TextFilter.util";
import { IDeliveryStatusCount } from "../Interfaces/IDeliveryStatusCount.interface";
import { IDeliveryFilterForm } from "../Interfaces/IDeliveryFilterForm.interface";
import { IAutoDispatchTypes } from "../Interfaces/IParsingTypes.type";
import { CourierTurnModel } from "../../../Models/CourierTurn.model";
import { ILoading, IError } from "xa-generics";
import { RestaurantModel } from "../../../Models/Restaurant.model";
import { useTranslation } from "react-i18next";
import { IOnChange } from "xa-inputs";
import { Statuses } from "../../../Static/Statuses.static";
import { useState } from "react";
import DeliveryManualDispatch from "./DeliveryManualDispatch.view";
import DeliveryFilterView from "./DeliveryFilter.view";
import DeliveryUnit from "../Controller/DeliveryUnit.controller";
import DrawerHeader from "../../Drawer/View/DrawerHeader";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/Delivery.css";

export interface IDeliveryViewProps extends IDeliveryControllerProps {
    manualAutoDispatch: (type: IAutoDispatchTypes) => void;
    setTextFilter: (newValue: string) => void;
    statusCount: IDeliveryStatusCount;
    turns: CourierTurnModel[];
    form: IDeliveryFilterForm;
    center: RestaurantModel;
    onChange: IOnChange;
    textFilter: string;
    loading: ILoading;
    error: IError;
}

const DeliveryView: React.FC<IDeliveryViewProps> = (props): React.ReactElement => {
    const [counterFilter, setCounterFilter] = useState<string>("");
    const { t } = useTranslation();

    const regex: RegExp = RegExp(swapString(props.textFilter), "i");
    const roundFilter = (round: CourierTurnModel): boolean => {
        const currentValue = props.form.filters.value;
        const radioTypes = props.form.filters.objRadioIDS;

        if (currentValue === radioTypes.all) return true;

        if (currentValue === radioTypes.on_road) {
            return round.status === Statuses.deliveryStatuses.on_road;
        }

        if (currentValue === radioTypes.go_back) {
            return round.status === Statuses.deliveryStatuses.go_back;
        }

        //This is the last case, there are no others, so there is no need to check it
        // if (currentValue === radioTypes.created)
        return round.status === Statuses.deliveryStatuses.created;
    };

    const getDeliveryRoundElements = (): JSX.Element[] => {
        const deliveryNodeArray: JSX.Element[] = [];

        for (const delivery of props.turns) {
            if (!roundFilter(delivery)) continue;

            if (props.textFilter || counterFilter) {
                let hasElement: boolean = false;
                for (const order of delivery.orders) {
                    if (
                        (counterFilter && `${order.counter}` === counterFilter) ||
                        (props.textFilter && textFilter(regex, order))
                    ) {
                        hasElement = true;
                        break;
                    }
                }
                if (!hasElement) continue;
            }

            deliveryNodeArray.push(
                <DeliveryUnit
                    key={`${delivery.id}-delivery-round`}
                    counterFilter={Number(counterFilter)}
                    turn={delivery}
                    textFilter={regex}
                />
            );
        }

        return deliveryNodeArray;
    };

    const nodeArray: JSX.Element[] = getDeliveryRoundElements();

    let listClasses: string = "delivery-view__list-container";

    return (
        <div className="delivery-view">
            <DrawerHeader title={t("deliveries_title")}>
                <DeliveryFilterView {...props} />
            </DrawerHeader>

            {props.loading && <Spinner className="delivery-view-loader" isLarge isAbsolute />}
            {(props.center.conf_conf_manual_order_dispatch ||
                props.center.conf_conf_manual_turn_parse) && (
                <Spinner className="delivery-view-loader" isLarge isAbsolute>
                    {t("auto_fill_loading")}
                </Spinner>
            )}

            <DeliveryManualDispatch
                {...props}
                setCounterFilter={setCounterFilter}
                counterFilter={counterFilter}
            />

            <div className={listClasses} id="scrollable-list-container">
                {nodeArray.length ? (
                    nodeArray
                ) : (
                    <div className="delivery-view__list-container--no-data no-data">
                        <span>{t("no_rounds_in_list")}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DeliveryView;
