import { RootDAO } from "../../../Api/DAO/Root.dao";
import { cloneDeep } from "lodash";
import { useAccess } from "../../../Modules/Access/Context/Access.context";
import { useSocket } from "../../../Contexts/Socket.context";
import { ModalContext } from "xa-modal";
import { ChangeHandler } from "../../../Utils/ChangeHandler.util";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import { IRestaurantConfigForm } from "../Interfaces/IRestaurantConfigForm.interface";
import { RESTAURANT_CONFIG_FORM } from "../Static/RestConfig.form";
import { IError, ILoading, ISelectOption, IDynamicObject } from "xa-generics";
import { IInputChange, IInputSelect, IInputBoolean, IInputText } from "xa-inputs";
import ConfigView from "../View/Config.view";

export interface IConfigControllerProps {}

const ConfigController: React.FC<IConfigControllerProps> = (props) => {
    const { t } = useTranslation();

    const { restaurant, setRestaurant } = useAccess();
    const { socket } = useSocket();
    const modal = useContext(ModalContext);

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [form, setForm] = useState<IRestaurantConfigForm>(
        (function () {
            let initial = cloneDeep(RESTAURANT_CONFIG_FORM);
            initial.conf_sort_product_by.options = [
                { id: "category_name", name: t("category_name") },
                { id: "product_name", name: t("product_name") },
                { id: "quantity", name: t("quantity") },
                { id: "ranking", name: t("ranking") }
            ];
            if (restaurant.conf_sort_product_by) {
                initial.conf_sort_product_by.value = {
                    id: restaurant.conf_sort_product_by,
                    name: t(restaurant.conf_sort_product_by)
                };
            }
            let sortMethods: ISelectOption[] = [
                { id: "asc", name: t("asc_method") },
                { id: "desc", name: t("desc_method") }
            ];
            initial.conf_sort_product_method.value = sortMethods.find(
                (item) => item.id === restaurant.conf_sort_product_method
            )!;
            initial.conf_auto_optimize.value = restaurant.conf_auto_optimize;
            initial.conf_sort_product_method.options = sortMethods;
            initial.conf_include_finish_distance.value = restaurant.conf_include_finish_distance;
            initial.conf_km_price.value = restaurant.conf_km_price.toString();
            return initial;
        })()
    );

    const parseForm = (): IDynamicObject<any> => {
        let data: IDynamicObject<any> = {};
        for (let input of Object.values(form)) {
            const field: IInputSelect | IInputBoolean | IInputText = input;

            if ("selectType" in field) {
                data[field.id] = field.value ? field.value.id : "";
                continue;
            }
            if ("booleanType" in field) {
                data[field.id] = field.value ? true : false;
                continue;
            }

            data[field.id] = field.value;
        }

        return data;
    };

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const data = parseForm();

        setLoading(
            RootDAO.updateRestaurant(data)
                .then((model) => {
                    setRestaurant(model);
                    setError(null);
                    modal.setContent(null);
                    socket.emit("RESTAURANT_UPDATE", model.id);
                })
                .catch((error: IError) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    return (
        <ConfigView
            {...props}
            form={form}
            error={error}
            loading={loading}
            onSubmit={onSubmit}
            resetError={() => setError(null)}
            onChange={(e: IInputChange) => setForm(ChangeHandler(form, e.fieldName, e.value))}
        />
    );
};

export default ConfigController;
