import { ModelConstructor, IDynamicObject } from "xa-generics";

export class PaymentLangModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as PaymentLangMData;
        ModelConstructor(data, this);
    }

    name: string = "";
    lang: string = "";
    short_name: string = "";
    payment_method_id: string = "";
    payment_type: string = "";

    id: string = "";
    type_web: boolean = false;
    type_app: boolean = false;
    type_phone: boolean = false;
    type_in_place: boolean = false;
    type_delivery: boolean = false;
    has_callback: boolean = false;
}

//If you add public methods, you may want to replace the empty string with them!
type PaymentLangMData = Partial<InstanceType<typeof PaymentLangModel>>;
