import { map } from "lodash";
import { FloatingError } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IError, ILoading } from "xa-generics";
import { ProductCountModel } from "../Model/ProductCount.model";
import { IProductCountProps } from "../Controller/ProductCount.controller";
import Spinner from "../../UI/Spinner/Spinner";
import "./Less/ProductCount.css";

export interface IProductCountViewProps extends IProductCountProps {
    setError: (error: IError) => void;
    productCounts: ProductCountModel[];
    loading: ILoading;
    error: IError;
}

const ProductCountView: React.FC<IProductCountViewProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="product-count">
            {props.loading && <Spinner isAbsolute isLarge />}
            <FloatingError error={props.error} resetError={() => props.setError(null)} />
            <div className={"page-wrapper"}>
                <div className="product-count__header">
                    <h1 className="product-count__header--title">{t("show_product_count")}</h1>
                    <div className="exit product-count__header--close" onClick={props.close}></div>
                </div>
                <div className="product-count__body">
                    {map(props.productCounts, (product, index) => (
                        <div className="product" key={`${index}-product-count-item`}>
                            <span className="product__name">{product.product_name}</span>
                            <span className="product__count">
                                {product.count} {t("qty_unit")}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductCountView;
