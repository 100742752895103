import {
    useDidMount,
    IDynamicObject,
    AuthCookieName,
    useMountWithTriggers,
    useAfterTriggerChanged
} from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import { IError, ILoading } from "xa-generics";
import { RestaurantModel } from "../../../Models/Restaurant.model";
import { IAccessContext } from "../Interfaces/IAccessContext.interface";
import { FloatingError } from "xa-error-with-lang";
import { AccessModel } from "../Models/Access.model";
import { AccessDAO } from "../DAO/Access.dao";
import { RootDAO } from "../../../Api/DAO/Root.dao";
import Spinner from "../../../Components/UI/Spinner/Spinner";
import Cookies from "js-cookie";
import { HostModel } from "../Models/Host.model";
import { TaxPrinterModel } from "../../../Models/TaxPrinter.model";

/**
 * ## AccessContext
 */
const AccessContext: Context<IAccessContext> = createContext<IAccessContext>(null as any);

AccessContext.displayName = "AccessContext";

export const AccessConsumer = AccessContext.Consumer;

interface IAccessContextProviderProps {}

/**
 * ## Access context provider component
 *
 */
export const AccessContextProvider: React.FC<IAccessContextProviderProps> = (props) => {
    const [taxPrinter, setTaxPrinter] = useState<null | TaxPrinterModel>(null);
    const [restaurant, setRestaurant] = useState<RestaurantModel | null>(null);
    const [accessModel, setAccessModel] = useState<AccessModel | null>(null);
    const [isInitializing, setIsInitializing] = useState<ILoading>(true);
    const [authLoading, setAuthLoading] = useState<ILoading>(false);
    const [authError, setAuthError] = useState<IError>(null);
    const [host, setHost] = useState<HostModel | null>(null);
    const [error, setError] = useState<IError>(null);

    useDidMount(() => {
        const cookieContent = Cookies.get(AuthCookieName);
        if (!cookieContent) return setIsInitializing(false);
        const authData = JSON.parse(cookieContent);
        if (!("token" in authData)) {
            Cookies.remove(AuthCookieName);
            return setIsInitializing(false);
        }

        AccessDAO.loadMe(authData.token)
            .then((model) => setAccessModel(model))
            .catch((error) => {
                console.log(error);
                Cookies.remove(AuthCookieName);
                setIsInitializing(false);
            });
    });

    useAfterTriggerChanged(() => {
        if (accessModel && restaurant) {
            setIsInitializing(false);
        }
    }, [accessModel, restaurant]);

    const saveCookie = (data: AccessModel, token: string): void => {
        Cookies.set(AuthCookieName, JSON.stringify({ ...data, token }), {
            sameSite: "Strict",
            expires: 3650, //10 * 1 year (365 days) = 3650 days
            secure: process.env.NODE_ENV === "production"
        });
    };

    const loadMeWithToken = (token: string): void => {
        setAuthLoading(
            AccessDAO.loadMe(token)
                .then((model) => {
                    saveCookie(model, token);
                    setAccessModel(model);
                })
                .catch((error) => console.error(error))
                .finally(() => setAuthLoading(false))
        );
    };

    const login = (data: IDynamicObject<any>): void => {
        setAuthLoading(
            AccessDAO.login(data)
                .then((data) => {
                    saveCookie(data.model, data.token);
                    setAccessModel(data.model);
                    if (error) setError(null);
                    if (authError) setAuthError(null);
                })
                .catch((error) => setAuthError(error))
                .finally(() => setAuthLoading(false))
        );
    };

    const logout = (): void => {
        setAuthLoading(
            AccessDAO.logout()
                .then(() => {
                    Cookies.remove(AuthCookieName);
                    setAccessModel(null);
                    setRestaurant(null);
                })
                .catch((error) => setAuthError(error))
                .finally(() => setAuthLoading(false))
        );
    };

    const loadRestaurant = (): void => {
        if (!accessModel) return;

        RootDAO.getCenterData(accessModel.restaurant_id)
            .then((model) => setRestaurant(model))
            .catch((error) => setError(error));
    };

    const loadServer = (): void => {
        if (!accessModel || !restaurant) return;

        AccessDAO.loadServer(restaurant.id)
            .then((hostModel) => setHost(hostModel))
            .catch((error) => console.error(error));
    };

    const loadTaxPrinter = (): void => {
        if (restaurant && host) {
            RootDAO.loadTaxPrinter(restaurant)
                .then((tax) => setTaxPrinter(tax))
                .catch((error) => console.error(error));
        }
    };

    useMountWithTriggers(loadTaxPrinter, [host, restaurant]);
    useMountWithTriggers(loadServer, [restaurant, accessModel]);
    useMountWithTriggers(loadRestaurant, [accessModel]);

    return (
        <AccessContext.Provider
            value={{
                accessModel: accessModel as never,
                restaurant: restaurant as never,
                loadMeWithToken,
                loadRestaurant,
                setRestaurant,
                authLoading,
                taxPrinter,
                authError,
                logout,
                login,
                host
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {isInitializing ? <Spinner /> : props.children}
        </AccessContext.Provider>
    );
};

export const useAccess = <IsLoggedInRoute extends boolean = true>() => {
    return useContext(AccessContext) as IAccessContext<IsLoggedInRoute>;
};
